.shop-whiskey-section {
  padding: 2.5rem 0;
  &:first-child {
    padding-top: rem;
  }
  &:last-child {
    padding-bottom: rem(50);
  }
  &.nickfaldo {
    background: radial-gradient(97.29% 235.81% at -10.58% -16.69%, #7F3715 0%, #350A08 54.65%, #100101 100%)
  }
}
.shop-whiskey-section-wrapper {
  background: radial-gradient(50% 154.13% at 50% 50%, #183047 0%, #100101 100%);
}
.shop-whiskey-card-wrapper {
  max-width: 43.6rem;
  margin: 0 auto;
  &__heading {
    margin-top: rem(25);
    padding: rem(10) 0;
    @include flex-center();
    background-image: url("/images/line-bg-3.png");
    @include bgCover();
    min-height: rem(60);
  }
  &__logo {
    @include flex-x-center();

    img {
      margin: 0 rem(10);
      &:first-child {
        width: rem(110);
      }
      &:nth-child(2) {
        width: rem(86);
      }
      &:last-child {
        width: rem(99);
      }
    }
  }
}
.shop-whiskey-card-main {
  margin-top: 1rem;
  .row {
    justify-content: center;
    [class*="col-"] {
      &:nth-child(odd) {
        .shop-whiskey-card-main__col {
          @include respond(desktopIpad) {
            margin-right: auto;
          }
        }
      }
      &:nth-child(even) {
        .shop-whiskey-card-main__col {
          @include respond(desktopIpad) {
            margin-left: auto;
          }
        }
      }
    }
  }
}

.shop-whiskey-card-main__col {
  margin-top: rem(35);
  background: rgba(106, 42, 17, 0.2);
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 31%);
  border-radius: 14px;
  border: 1px solid #d67d1b;
  padding: rem(20) 2rem;
  text-align: center;
  position: relative;
  @include respond(desktopIpad) {
    max-width: 95%;
  }
  &--image {
    width: rem(96);
  }
  .addToCartBtn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    @include flex-center();
    a,
    span {
      max-width: 35%;
      flex-basis: 35%;
      padding: 0.8rem;
    }
  }
  &--btn {
    .new-btn-primary {
      width: 100%;
      font-size: rem(12);
    }
  }
  &--heading {
    font-family: "Alfa Slab One";
    font-style: normal;
    font-weight: 400;
    color: $white;
    font-size: rem(14);
    margin: rem(15) 0;
  }
}
.new-btn-primary {
  &.sm {
    padding: 0.8rem;
    font-size: 0.6rem;
  }
}

.new-market-new-challenges-section,
.how-it-started-section,
.new-markets-original-flavour-section {
  .new-about-description-card .nikfaldo-desc {
    margin-left: 0;
  }
}

.new-market-new-challenges-section {
  .new-market-new-challenges-image {
    @include respond(mobile) {
      margin-top: rem(20);
    }
  }
  .new-about-description-card__desc {
    @include respond(desktopIpad) {
      max-height: rem(250);
      overflow-y: auto;
    }
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #7d6233;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #d49255;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #ffd59f;
    }
  }
}

.new-market-new-challenges-2-section {
  display: none;
}
.shop-whiskey-banner {
  background: radial-gradient(
    35.97% 50% at 50% 50%,
    rgba(62, 75, 96, 0.58) 0%,
    rgba(24, 48, 71, 0.58) 48.44%,
    rgba(2, 0, 0, 0.58) 100%
  );
  @include flex-y-center();

  &__desc {
    text-align: center;
  }
  &__row {
    @include bgCover();
    @include respond(desktop) {
      min-height: 31.25rem;
    }
    @include respond(desktopIpad) {
      align-items: center;
      background-image: url("/images/shop-lg-bg.jpg");
      margin-top: rem(70);
    }
    @include respond(ipad) {
      min-height: 20.25rem;
      background-position: right;
    }
    @include respond(mobile) {
      min-height: 19.25rem;
    }
  }
}

.shop-whiskey-banner__desc {
  @include respond(mobile) {
    margin: rem(156) auto 0 auto;
    max-width: 13.875rem;
  }
  .heading-bright-1 {
    @include respond(mobile) {
      font-size: 2.5rem !important;
    }
  }
}

.kyloe-edition-section-bottle-wrapper {
  .nikfaldo-desc-2 {
    @include respond(mobile) {
      margin-top: 0;
    }
  }
}
