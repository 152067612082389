$max-lg: 991px;

#scene-container {
  width: 75%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.readmore-modal {
  .modal-content {
    background: transparent;
  }

  .readmore-modal-body {
    background: radial-gradient(50% 154.13% at 50% 50%, #183047 0%, #100101 100%);
    border-radius: rem(16);
    min-height: 24.125rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .shop-whiskey-card-wrapper__heading{
      width: 120%;
    }
    .title {
      font-size: rem(30);
      margin-bottom: rem(15);
    }

    .content {
      line-height: 1.7;
      margin-bottom: rem(17);
    }
  }

  .modal-close-btn {
    font-size: rem(20);
    background-color: #f9c467;
    color: #1d1d20;
    padding: rem(15) rem(30);
    border-radius: rem(6);
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.product-details-new {
  .flavor-section {
    .text-description {
      font-size: 21px !important;
    }
  }

  #gsap-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #gsap-box-img {
    margin-top: rem(30);
    width: rem(210);
  }

  #gsap-box-img-glass {
    position: absolute;
    bottom: rem(140);
    width: rem(210);
    left: 5%;
    opacity: 0;

    @media (max-width: 1365px) {
      bottom: rem(180);
    }
  }

  .slick-dots li {
    border-radius: 99px;
  }

  #banner {
    padding-top: rem(20);
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 991px) {
      padding-top: rem(80);
    }

    .title {
      font-size: rem(40);
      line-height: 1.5;
      margin-bottom: rem(18);
    }

    .subtitle {
      font-size: rem(20);
    }

    .content {
      font-size: rem(18);
      line-height: 1.67;
      margin-bottom: rem(50);
    }

    .locate-store-btn {
      font-size: rem(16);
      line-height: 1.67;
      padding: rem(12) rem(20);
      border-radius: rem(5);
      border: rem(1) solid #f9c467;
      text-transform: capitalize;
    }

    .locate-store-btn:hover {
      text-decoration: none;
    }

    .locate-store-icon {
      width: rem(17);
      position: relative;
      top: rem(-2);
      margin-right: rem(10);
    }
  }

  .product-details-section {
    .mobile-product-details-points {
      height: 500px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 5px;
      }
    }

    @media (max-width: $max-lg) {
      .product-details-main-img {
        height: 600px;
        object-fit: contain;
        margin-left: -15px !important;
        margin-right: -15px !important;
      }
    }

    .title {
      text-align: center;
      font-size: rem(40);
    }

    .color-dot {
      margin-left: rem(8);
      width: rem(14);
      height: rem(14);
      border-radius: rem(99);
      display: inline-block;
      border: rem(1) solid #f9c467;
      position: relative;
      top: rem(2);
    }

    .content {
      font-size: rem(16);
      line-height: 1.67;
      font-weight: 700;
      margin-bottom: rem(40);
    }

    .product-images {
      width: rem(100);
      height: rem(100);
      border-radius: rem(5);
      padding: rem(9);
      background-color: #1d1d20;
      border: rem(1) solid #1d1d20;
    }

    .product-images.active {
      border-color: #f9c467;
    }

    @media (max-width: $max-lg) {
      .product-gallery-active-img {
        height: 450px;
        object-fit: contain;
      }

      .product-image-padding {
        padding-bottom: 30px;
      }
    }

    .product-images img {
      object-fit: contain;
    }
  }

  .flavor-section {
    @media (max-width: $max-lg) {
      padding-bottom: rem(20);

      .text-description {
        font-size: rem(18) !important;
      }
    }

    .radar-card-container {
      background-color: #242425;
      border-radius: rem(20);
      padding: rem(35) rem(20);
      width: 90%;
      max-width: 90%;
      margin-bottom: rem(16);
      transform: scale(1.1);
      box-shadow: 14.9804px 14.9804px 14.9804px rgba(0, 0, 0, 0.2),
        -14.9804px -14.9804px 14.9804px rgba(0, 0, 0, 0.2);

      @media (max-width: $max-lg) {
        transform: scale(1);
        width: 100%;
        max-width: 100%;
        padding: rem(19) 0;

        div {
          transform: scale(1.1);
        }
      }
    }

    .product-desc-section-card,
    .rotate-card-one {
      clip-path: none;
      border-radius: rem(15);
    }

    .title {
      font-size: rem(40);
      line-height: rem(66);
    }

    .content {
      font-size: rem(18);
      line-height: 1.67;

      @media (max-width: $max-lg) {
        margin-right: rem(70);
      }
    }

    .flovor-item-container {
      padding-bottom: rem(70);

      .flovor-glass {
        bottom: rem(-78);
        right: rem(-190);
        width: rem(300);

        @media (max-width: $max-lg) {
          right: rem(-140);
          bottom: rem(-68);
        }
      }

      .flovor-item {
        bottom: rem(-65);
        right: rem(-100);
        width: rem(250);
      }
    }
  }

  #article-section {
    .overline {
      font-size: rem(18);
      line-height: 1.67;
    }

    .title {
      font-size: rem(38);
      line-height: 1.67;
      margin-bottom: rem(20);
    }

    .article-container {
      margin: rem(15);
    }

    .article-img-container::before {
      content: "";
      display: block;
      position: absolute;
      left: rem(12);
      right: 0;
      top: rem(12);
      bottom: 0;
      width: 100%;
      height: 100%;
      border: rem(1) solid #f9c467;
    }

    .article-img-container {
      margin-bottom: rem(25);
    }

    .article-img-container img {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }

    .article-title {
      font-size: rem(28);
      line-height: 1.5;
    }

    .article-content {
      font-size: rem(16);
      font-weight: 500;
      line-height: 1.67;
    }
  }

  #bottles-grid-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    opacity: 0.3;
    background-image: url("/images/about/slider-bg.jpg");
    background-size: cover;
    background-position: center;
  }

  #bottles-grid-section {
    position: relative;

    .bottle-card {
      overflow: hidden;
    }

    .overline {
      font-size: rem(19);
      line-height: 1.67;
    }

    .title {
      font-size: rem(38);
      line-height: 1.3;
      max-width: rem(450);
      margin-bottom: rem(50);
    }

    .bottle-img {
      width: 90%;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: $max-lg) {
        height: 450px;
        object-fit: contain;
      }
    }

    .bottle-glass-img {
      bottom: 0;
      opacity: 0;
      transform: translateX(-100%);
      transition: transform 500ms ease-in-out, opacity 500ms ease-in;

      @media (max-width: $max-lg) {
        height: 250px;
        object-fit: contain;
      }
    }

    .bottle-card:hover .bottle-glass-img {
      opacity: 1;
      transform: translateX(-23%);
    }

    .bottle-title {
      font-size: rem(18);
      line-height: rem(30);

      @media (max-width: $max-lg) {
        text-align: center;
      }
    }
  }
}
