.wrapper-box-shadow {
    position: relative;
    filter: drop-shadow(0px 0px 14px #00000040);
}
.timeline-wrapper {
    background-color: #303033;
    padding: 8rem 5rem 3rem;
    max-height: 670px;
    overflow: auto;
    scrollbar-width: none;  /* Firefox */ 
    .slick-slider .slick-list {
        overflow: auto !important;
    }

    @include respond(desktop) {
        clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    }
    @include respond(ipadMobile) {
        clip-path: polygon(0 8%, 100% 0, 100% 100%, 0% 100%) !important;
    }
    &::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }
    /* Optional: show position indicator in red */
    &::-webkit-scrollbar-thumb {
        background: #ff0000;
    }
    @media all and (max-width: 768px) {
        padding: 8rem 3rem 3rem;
    }
    @media all and (max-width: 575px) {
        padding: 6rem 1.5rem 4rem;
        margin-left: 1rem;
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
        .timeline-area {
            padding: 0;
        }
    }
    @include respond(smallmobile) {
        padding: 4rem 1rem 4rem;
        clip-path: polygon(0 7%, 100% 0, 100% 100%, 0% 100%);
    }
}
.border-box-shadow {
    z-index: 1;
    position: relative;
    filter: drop-shadow(0px 0px 14px #00000040);
}
.timeline-area {
    padding: 20px 0;
}
.all-timelines {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        height: 100%;
        width: 1px;
        background: $color-primary;
        top: 16px;
    }
    &::after {
        content: "";
        height: 6px;
        width: 6px;
        background: $color-primary;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.single-timeline {
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    // &:last-child {
    //     margin-bottom: 0;
    // }
    &:nth-child(even),
    &.left-timeline {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        .t-square {
            right: 0;
            left: unset;
            clip-path: polygon(0 0, 100% 40%, 100% 100%, 0% 100%);
        }
        .timeline-text {
            padding-left: 0;
            padding-right: 55px;
            text-align: right;
            @media all and (max-width: 575px) {
                padding-right: 36px;
            }
            span {
                text-align: right;
            }
        }
    }
}
.timeline-blank {
    width: 50%;
}
.timeline-text {
    width: 50%;
    text-align: left;
    padding-left: 55px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    .title {
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: normal;
        font-size: rem(22);
        line-height: normal;
        color: $color-primary;
        margin-bottom: 1rem;
    }
    span,
    .timeline-description p {
        font-family: "Lato";
        font-style: normal;
        font-weight: 300;
        color: $color-light;
        display: block;
        max-width: 380px;
        margin-bottom: 0;

        @include respond(desktop) {
            font-size: rem(18);
            line-height: rem(24);
        }
        @include respond(ipadMobile) {
            font-size: rem(14);
            line-height: rem(20);
        }
        a {
            margin-top: 10px;
        }
    }
    @media all and (max-width: 575px) {
        padding-left: 36px;
        h6 {
            font-size: rem(16);
            line-height: rem(30);
            margin-bottom: 0.5rem;
        }
        span {
            font-size: rem(14);
            line-height: rem(20);
        }
    }
    @include respond(smallmobile) {
        h6 {
            line-height: normal;
        }
        span {
            font-size: 1rem;
            line-height: normal;
        }
    }
}
.t-square {
    content: "";
    position: absolute;
    width: 35px;
    height: 14px;
    left: 0;
    top: 10px;
    background: $color-primary;
    clip-path: polygon(0 40%, 100% 0, 100% 100%, 0% 100%);
    @media all and (max-width: 575px) {
        width: 28px;
        height: 11px;
    }
    @include respond(smallmobile) {
        top: 5px;
    }
}

@media all and (max-width: 991px) {
}
@media all and (max-width: 768px) {
}
// @media all and (max-width: 575px) {
//     .all-timelines::before {
// 		right: unset;
// 	}
// 	.single-timeline:nth-child(2n) .timeline-text {
// 		padding-left: 55px;
// 		padding-right: 0;
// 		text-align: left;
// 	}
// 	.single-timeline:nth-child(2n) .t-square {
// 		left: 0px;
//         right: unset;
//         clip-path: polygon(0 40%, 100% 0, 100% 100%, 0% 100%);
// 	}
// 	.timeline-blank {
// 		display: none;
// 	}
// 	.timeline-text {
// 		width: 100%;
// 	}
// 	.single-timeline:nth-child(even) .timeline-text span {
// 		text-align: left !important;
// 	}
//     .all-timelines::after {
//         left: 0;
//         transform: translateX(-40%);
//     }
// }

// timeline buttons
.timeline-action-btns {
    display: flex;
    align-items: center;
    position: absolute;
    transform: rotate(90deg);
    right: 0;
    top: 50%;
    @media all and (max-width: 575px) {
        right: -12px;
    }
    @include respond(smallmobile) {
        right: -18px;
    }
    .timeline-button {
        background: $color-primary;
        cursor: pointer;
        height: 10px;
        width: 10px;
        margin-right: 10px;
        opacity: 0.7;
        transition: all 0.3s ease;
        &.active {
            opacity: 1;
            width: 14px;
            height: 14px;
        }
    }
}
