.site-loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  @include flex-center();
  z-index: 20;
  transition: 0.7s $transition-timing visibility, 0.7s $transition-timing opacity, 0.7s $transition-timing transform;
  will-change: opacity, visibility, transform;
  background: radial-gradient(50% 154.13% at 50% 50%, #183047 0%, #100101 100%);
  // box-shadow: 0 2px 4px 0 $color-danger;

  img {
    @include respond(desktop) {
      width: rem(250);
    }

    @include respond(ipadMobile) {
      width: remMobile(100);
    }
  }
}

.loaded {
  opacity: 0.1;
  transform: translateY(-100%);
}

.load-image {
  animation: logo-pop-up-top 0.5s alternate infinite cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes logo-pop-up-top {
  0% {
    transform: translateY(0);
    transform-origin: 50% 50%;
  }
  100% {
    transform: translateY(-30px);
    transform-origin: 50% 50%;
  }
}

.custom-fixed-loader {
  position: fixed;
  left: 45%;
  top: 50%;
  z-index: 9;
}

// component loader
.component-loader {
  @include flex-center();
  transform: scale(0.8);
  margin-bottom: rem(150);
  p {
    margin: 0;
    margin-left: 10px;
    color: $color-light;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 2px;
  }
}
.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: $color-primary;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.no-data {
  color: $color-primary;
  font-size: rem(14);
  margin-bottom: 0;
  text-transform: uppercase;
}
