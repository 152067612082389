.site-jumbotron {
  z-index: -1;
  // position: relative;
  @include respond(desktop) {
    height: rem(605);
    transform: translateY(-6.2142857143rem);
  }
  @include respond(ipadMobile) {
    transform: translateY(rem(-55));
    height: calc(100vh - 5.72rem);
  }

  background-image: url("/images/home-jumbotron.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  @include respond(desktop) {
    margin-top: rem(100);
  }
  @include respond(ipadMobile) {
    margin-top: rem(55);
  }

  &__arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 10px);
    z-index: 1;
  }

  &.about-us {
    .section-heading-2 {
      position: absolute;
      text-align: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.blog-banner {
    .filter {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #0a0a0a;
      opacity: 0.65;
    }
    .blog-banner-text {
      position: absolute;
      text-align: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @include respond(mediumMobile) {
        width: 100%;
      }
      .section-heading-2 {
        margin-bottom: rem(30) !important;
      }
    }
  }
}

.error-section {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(50% 154.13% at 50% 50%, #183047 0%, #100101 100%);
}

.error-card {
  .section-heading-2 {
    line-height: 1 !important;
    @include respond(desktopIpad) {
      font-size: rem(102);
      margin-bottom: rem(45) !important;
    }
    @include respond(mobile) {
      font-size: rem(48);
      margin-bottom: rem(25) !important;
    }
  }
  .rotate-card-one {
    @include respond(desktopIpad) {
      padding-bottom: 10.5rem;
    }
    @include respond(mobile) {
      padding-bottom: rem(70);
      min-height: rem(341);
    }
  }
  .rotate-card-one__image {
    @include respond(desktop) {
      transform: translate(-4.642857rem, -11.857143rem) !important;
    }
    @include respond(ipad) {
      transform: translate(-4.642857rem, -3.857143rem) !important;
    }
    @include respond(mobile) {
      transform: translate(-7%, -4.142857rem) !important;
    }
  }
  .card-para {
    margin-bottom: 0 !important;
    text-align: right;

    @include respond(desktopIpad) {
      max-width: rem(486);
      font-size: rem(18);
      line-height: 28px !important;
    }
    @include respond(mobile) {
      margin-bottom: rem(16);
      font-size: rem(14);
      max-width: rem(244);
    }
  }
}

.blog-videoplayer {
  position: relative;
  max-height: rem(700);
  video {
    max-height: rem(700);
  }
  .playButton {
    opacity: 1;
    img {
      transform: scale(0.7);
      @include respond(mobile) {
        transform: scale(0.4);
      }
    }
  }
  .speaker {
    @include respond(ipad) {
      transform: scale(0.3) translateY(0);
    }
  }
  .blog-videoplayer-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-130%, 200%);
    @include respond(ipad) {
      transform: translate(-120%, 150%);
    }
    @include respond(mobile) {
      transform: translate(-110%, 110%);
    }
    h4.title {
      font-family: "Alfa Slab One";
      font-size: rem(32);
      font-weight: 400;
      line-height: rem(42);
      color: $color-grey;
      @include respond(mobile) {
        font-size: rem(18);
        line-height: normal;
        margin-bottom: 0;
      }
    }
    p.date {
      font-family: "Lato";
      font-size: rem(18);
      font-weight: 300;
      line-height: normal;
      color: $color-grey;
      @include respond(mobile) {
        font-size: rem(12);
      }
    }
  }
}

.error-page-card{
    max-width: rem(481);
    img{
        width: rem(200);
    }
    .nikfaldo-desc{
        font-size: rem(18);
        line-height: 1.5;
        margin: rem(40) 0;
    }
}