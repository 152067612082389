.store-locator-page {
  .main-section {
    @include respond(mediumMobile) {
      overflow: visible;
      max-width: 100%;
    }
  }

  .section.product-filter-section {
    @include respond(ipadMobile) {
      padding-top: 6.7rem !important;
    }
  }
}

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: rem(18);
  height: rem(18);
  background-color: #000;
  border: rem(2) solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);

  &:hover {
    z-index: 1;
  }
}
.gm-style .gm-style-iw-tc::after {
  background-color: #382003;
}
.gm-style-iw.gm-style-iw-c {
  padding: 0 !important;
  background: $color-secondary !important;
  border-radius: 10px !important;
}

.gm-style .gm-style-iw-t::after {
  display: none;

  padding: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  width: rem(30) !important;
  height: rem(30) !important;
  left: rem(-5) !important;
  background: linear-gradient(
    136deg,
    $color-secondary 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(255, 255, 255, 0) 100%
  );
}

.gm-ui-hover-effect > span {
  background-color: #fff;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;

  @include respond(mediumMobile) {
    background: $color-secondary;
    overflow: hidden !important;
  }
}

.gm-style-iw-t {
  button {
    top: 0.3rem !important;
    right: 0.3rem !important;
  }
}

.gm-style-iw.gm-style-iw-c {
  @include respond(mediumMobile) {
    max-width: rem(370) !important;
  }
}

.location-data-container {
  // position: relative;
  //  max-height: rem(550);

  @include respond(mediumMobile) {
    max-height: 100%;
    // padding: 0 rem(20);
  }

  .no-location-card {
    margin-top: 1.6875rem;
  }
  .maps-listing-container {
    text-align: left;
    position: absolute;
    height: rem(455);
    max-width: rem(375);
    min-width: rem(350);
    overflow: hidden;
    // padding: rem(25) rem(25) rem(25) rem(25);
    z-index: 2;
    top: 50%;
    transform: translate(-136%, -50%);
    left: 50%;
    border-radius: rem(16);
    height: 100%;
    background-image: url("/images/nikfaldo-details-bg.png");
    @include bgCover();
    // box-shadow: 0px 0px rem(25) rgba(0, 0, 0, 0.25);

    @include respond(ipad) {
      transform: translateY(-0.9375rem);
    }

    @include respond(mediumMobile) {
      position: relative;
      top: unset;
      transform: none;
      left: unset;
      max-width: 100%;
      margin: rem(30) 0;
      min-width: 100%;
      padding: rem(20) rem(24) rem(20) rem(20);
    }

    h3 {
      font-family: "Alfa Slab One";
      font-size: rem(22);
      line-height: rem(30);
      color: $color-grey;
      margin-bottom: rem(15);
    }

    .location-listing {
      height: rem(410);
      overflow: auto;
      padding-right: rem(15);

      @include respond(mediumMobile) {
        height: rem(364);
      }
      &::-webkit-scrollbar {
        width: 7px;
      }
  
      /* Track */
      &::-webkit-scrollbar-track {
        background: #7d6233;
      }
  
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #d49255;
      }
  
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #ffd59f;
      }

      .address-card {
        padding: rem(20);

        @include respond(mediumMobile) {
          margin-bottom: 0;
          padding: 18px 0;
          box-shadow: none;
        }

        .shop-name {
          margin-bottom: rem(5);
          font-size: rem(16);
          line-height: rem(19);

          @include respond(mediumMobile) {
            font-size: rem(14);
            line-height: rem(18);
          }
        }

        .address-text {
          font-size: rem(14);
          line-height: rem(20);

          @include respond(mediumMobile) {
            font-size: rem(13);
            line-height: rem(15);
          }
        }

        // .shop-actions {
        //   display: none;
        // }
      }
    }
  }

  .google-maps-container {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    
    @include respond(ipadMobile) {
      height: rem(500);
    }

    @include respond(mediumMobile) {
      height: rem(400);
    }

    #map {
      min-height: rem(500);
      max-height: rem(500);
    }
  }
}

.google-maps-container .address-card .shop-actions {
  // display: none;
}

.store-locator-filters {
  @include respond(mediumMobile) {
    margin-bottom: 0px !important;

    &:last-child {
      margin-bottom: 3rem !important;
    }
  }

  .select-filter {
    .react-select__placeholder,
    .react-select__single-value {
      font-size: 1rem !important;
    }

    @include respond(mediumMobile) {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .store-locator-input {
    text-align: left;

    @include respond(ipadMobile) {
      margin-bottom: 30px;

      .maps-autocomplete-input {
        max-width: 370px;
      }

      input,
      .site-input-group {
        margin-bottom: 0;
      }
    }

    input {
      padding: 1.2142857143rem rem(20);
      padding-right: rem(40);

      @include respond(ipadMobile) {
        font-size: 1rem;
      }
    }
  }
}

.maps-autocomplete-input {
  position: relative;

  a.maps-locator {
    position: absolute;
    right: rem(11);
    top: 50%;
    transform: translateY(-50%);
  }
}
.p-xy-15 {
  padding: 1.5rem;
}
.new-address-card {
  @include flex-y-center();
  padding: rem(27) 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  &__image {
    @include bgCover();
    border-radius: rem(5);
    width: 100%;
    @include respond(desktopIpad) {
      max-width: rem(150);
      height: rem(150);
    }
    @include respond(mobile) {
      max-width: rem(113);
      height: rem(113);
      margin: 0 rem(15);
    }
  }
  &__desc {
    font-family: "Bellota Text";
    font-weight: 400;
    font-size: rem(14);
    color: #c4c4c4;
    margin-bottom: 0.7rem;
    &.open {
      color: #28b71f;
    }
    &.closed {
      color: #ff8181;
    }
  }
  &__openclose {
    span {
      display: inline-block;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include respond(desktopIpad) {
      padding-left: rem(15);
      max-width: calc(100% - 9.375rem);
    }
    @include respond(mobile) {
      max-width: calc(100% - 7.0625rem);
      text-align: left;
    }
    h5 {
      font-family: "Alfa Slab One";
      font-weight: 400;
      color: $white;
      @include respond(desktopIpad) {
        font-size: rem(16);
      }
      @include respond(mobile) {
        font-size: rem(14);
      }
    }
  }
  &__direction {
    display: flex;
    a {
      display: inline-block;
      margin-bottom: 0;
      &:last-child {
        margin-left: rem(22);
      }
    }
  }
}
.store-locator-page .mobile-store-locator-top-bar .site-input-group {
  @include respond(mobile) {
    height: rem(56);
    background: rgba(15, 15, 15, 0.9);
    border-radius: rem(10) !important;
  }
}
.site-input-group {
  input {
    @include respond(mobile) {
      height: rem(56);
      background: rgba(15, 15, 15, 0.9);
      border-radius: rem(10) !important;
    }
  }
}

.store-locator-page{
  .product-filter-section{
    @include respond(desktop){
      padding-top: 7.375rem;
    }
  }
}