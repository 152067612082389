.App__view-cart-wrapper {
  float: right;
}

.App__view-cart {
  font-size: 15px;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
}

/* CART
 * ============================== */
.Cart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background: radial-gradient(50% 154.13% at 50% 50%, #183047 0%, #100101 100%);
  box-shadow: -10px 0px 20px rgba(0, 0, 0, 25%);
  // backdrop-filter: blur(10px);
  border: none;
  z-index: 100;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.15s ease-in-out;
  @include respond(desktopIpad) {
    width: 26rem;
  }
  @include respond(mobile) {
    width: 100%;
  }
}

.Cart--open {
  transform: translateX(0);
}

.Cart__close {
  // position: absolute;
  // right: 9px;
  // top: 8px;
  // font-size: 35px;
  // color: #999;
  // border: none;
  // background: transparent;
  // transition: transform 100ms ease;
  // cursor: pointer;
}

.Cart__header {
  font-family: "Alfa Slab One";
  padding: 20px;
  border-bottom: 1px solid #3a3a3a;
  flex: 0 0 auto;
  display: inline-block;
  @include flex-y-center();
  justify-content: space-between;
  @include respond(desktop) {
    padding: 2rem 1.7857142857rem;
  }
  @include respond(ipadMobile) {
    padding: 1.5rem 1.7857142857rem;
  }
  h2 {
    color: $white;
    margin-bottom: 0;
    @include respond(desktop) {
      font-size: 1.4rem;
      text-transform: uppercase;
    }
  }
}

.Cart__line-items {
  margin: rem(398);
  overflow-y: auto;
  padding: 0;
  @include respond(desktop) {
    min-height: calc(100vh - 15.428571rem);
  }
  @include respond(ipadMobile) {
    min-height: calc(100vh - 19.128571rem);
  }
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.5);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.Cart__footer {
  padding: rem(20);
  border-top: 1px solid #3a3a3a;
  flex: 0 0 auto;
  .Cart-info {
    display: flex;
    &:not(:first-child) {
      margin-top: rem(20);
    }
  }
  .Cart-info__total,
  .Cart-info__pricing {
    max-width: 50%;
    flex-basis: 50%;
  }
  .Cart-info__total {
    color: $color-grey;
    float: left;
    text-transform: uppercase;
    font-size: rem(13);
  }

  .Cart-info__small {
    font-size: rem(13);
    font-family: "Lato";
  }

  .Cart-info__pricing {
    font-size: rem(14);
    font-family: "Alfa Slab One";
    text-align: right;
    color: #f9f8f7;
  }
}

.Cart__checkout {
  font-weight: 600;
  min-height: 3.285714rem;
  font-size: rem(20);
  text-transform: uppercase;
  margin-top: rem(40);
  display: block;
  width: 100%;
  font-family: "Alfa Slab One" !important;
  background-color: $color-primary;
  letter-spacing: 0.1em;
  color: $color-secondary;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    z-index: 1;
    bottom: 0;
    border: 0.25px solid $color-primary;
    width: 100%;
    height: 100%;
    transform: translate(-5px, 5px);
  }
}

.empty-cart-container {
  display: grid;
  place-items: center;
}

.empty-cart-text {
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  padding-top: 20vh;
  color: #f2ede9;
}
/* LINE ITEMS
   * ============================== */
.Line-item {
  overflow: hidden;
  backface-visibility: visible;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  display: flex;
  align-items: center;
  @include respond(desktop) {
    padding: 0 rem(25);
    margin-bottom: rem(25);
    padding-bottom: rem(25) !important;
  }
  @include respond(ipadMobile) {
    padding: 0 rem(15);
    margin-bottom: rem(15);
    padding-bottom: rem(15) !important;
  }
  border-bottom: 1px solid #3a3a3a;
  &:not(:last-child) {
  }
  &:first-child {
    @include respond(desktop) {
      padding-top: rem(25) !important;
    }
    @include respond(ipadMobile) {
      padding-top: rem(15) !important;
    }
  }
}
.line-item-img {
  width: 100%;
}
.Line-item__img {
  max-width: rem(83);
  flex-basis: rem(83);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background: rgba(106, 42, 17, 0.16);
  border: 1px solid #d67d1b;
  border-radius: 9.80749px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    border-radius: rem(10);
  }
  div {
    width: 100%;
    height: rem(80);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.Line-item__content {
  max-width: calc(100% - 5.9rem);
  flex-basis: calc(100% - 5.9rem);
  padding-left: rem(12);
  // padding-left: 75px;
  position: relative;
}

.Line-item__content-row {
  @include flex-y-center();
  width: 100%;
  position: relative;
  &:nth-child(2) {
    margin-top: rem(10);
  }
}

.Line-item__variant-title {
  float: right;
  font-weight: bold;
  font-size: 11px;
  line-height: 17px;
  color: #767676;
}

.Line-item__title {
  font-family: "Bellota Text";
  width: calc(100% - 2rem);
  color: $color-grey;
  font-size: rem(14);
  font-weight: 400;
  line-height: 1.5;
}

.Line-item__price {
  float: right;
  font-weight: bold;
  font-size: rem(20);
  font-family: "Bellota Text";
  margin-left: rem(25);
  color: $white;
}

.Line-item__quantity-container {
  background: rgba(106, 42, 17, 0.35);
  border: 1px solid #f9c467;
  border-radius: rem(15);
  min-height: 2rem;
  display: flex;
  align-items: center;
  min-width: 45%;
  justify-content: space-between;
  float: left;
  font-weight: 700;
  .Line-item__quantity-update {
    color: #ffca74;
    display: block;
    float: left;
    font-family: monospace;
    padding: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    cursor: pointer;
    font-size: rem(18);
    text-align: center;
    font-weight: 700;
    width: 30%;
  }
  .Line-item__quantity {
    font-family: "Baskerville";
    color: $white;
    width: 33%;
    font-size: rem(15);
    border: none;
    text-align: center;
    -moz-appearance: textfield;
    background: transparent;
    border-left: 1px solid $color-secondary;
    border-right: 1px solid $color-secondary;
    display: block;
    float: left;
    padding: 0;
    border-radius: 0;
  }
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
}

/* PRODUCTS
   * ============================== */
.Product {
  flex: 0 1 31%;
  margin-left: 1%;
  margin-right: 1%;
  margin-bottom: 3%;
}

.Product__title {
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
  opacity: 0.7;
}

.Product__price {
  display: block;
  font-size: 1.1rem;
  opacity: 0.5;
  margin-bottom: 0.4rem;
}

.Product__option {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.Product__quantity {
  display: block;
  width: 15%;
  margin-bottom: 10px;
}

.details-page-heading {
  .text-primary {
    display: inline !important;
  }
}
.sold-out {
  color: #f97272;
  text-transform: uppercase;
  font-family: "Alfa Slab One", sans-serif;
  font-size: rem(18);
  margin-bottom: rem(20);
}
.details-page-btn-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include respond(mobile) {
    flex-direction: column;
  }
  .locate-store-btn {
    font-size: rem(12) !important;
    padding: 0.8571428571rem 1rem !important;
  }
  .locateStoreBtn {
    @include respond(desktopIpad) {
      width: 48%;
    }
    @include respond(mobile) {
      width: 100%;
      text-align: center;
    }
  }
  .add-to-cart-detail {
    @include respond(desktopIpad) {
      width: 48%;
    }
    @include respond(mobile) {
      width: 100%;
    }
    .locate-store-btn {
      width: 100%;
      background: #f9c467;
      color: #000;
    }
  }
}

.addtoCartBtn {
  position: fixed !important;
  z-index: 5;
  transform: translateX(0) !important;
  right: 1rem !important;
  bottom: 1rem !important;
}
