@mixin markdowncss_case() {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: rem(15);
  }
  h1 {
    font-size: rem(45);
  }
  h2 {
    font-size: rem(35);
  }
  h3 {
    font-size: rem(30);
  }
  h4 {
    font-size: rem(25);
  }
  h5 {
    font-size: rem(22);
  }
  h6 {
    font-size: rem(18);
  }
  p,
  span {
    font-size: rem(18);
    margin-top: rem(16);
    margin-bottom: rem(24);
    line-height: rem(28);
    position: relative;
  }
  blockquote {
    border-left: 6px solid $color-light;
    padding-left: 0.875rem;
    margin-top: 2.5rem;
    p {
      line-height: rem(48);
      margin-bottom: 0;
      font-size: rem(30);
    }
  }
  strong {
    font-family: 'Alfa Slab One';
    display: block;
    color: $white;
    font-weight: 400;
    margin-bottom: rem(18);
    font-family: "Alfa Slab One";
    text-transform: uppercase;
    @include respond(desktop) {
      font-size: rem(16) !important;
      max-width: rem(758);
    }

    @include respond(ipadMobile) {
      font-size: rem(14) !important;
    }
  }
  em {
    font-style: italic;
  }
  a {
    color: $color-light;
    text-decoration: none;
    // margin-right: ;
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: rem(20) 0 0;
    + strong {
      display: block;
      text-align: center;
      margin-bottom: 6rem;
    }
  }
  pre {
    color: $color-light;
  }
  hr {
    margin: rem(100) 0;
    border-top: 2px solid $color-secondary;
  }
  ul,
  ol {
    padding-left: 0;
    margin-left: rem(13);
    margin-bottom: 0;

    @include respond(ipadMobile){
      margin-left: rem(25) !important;
    }

    li {
      margin-bottom: 1rem;
      line-height: 1.5rem;
      font-size: 1rem;
    }
  }
  footer {
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.14px;
    font-size: rem(22);
    line-height: rem(46);
  }
  small {
    font-size: rem(16);
    line-height: rem(24);
    margin-bottom: rem(30);
  }
  article {
    line-height: rem(52);
    margin-bottom: rem(30);
    font-size: rem(30);
  }
  table{
    width: 100%;
    margin-bottom: 2.1428571429rem;
    color: $white;
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    th{
      text-transform: uppercase;
      // font-family: 'Alfa Slab One';
      vertical-align: bottom;
      text-align: left !important;
      font-size: 1.3rem;
      color: $color-primary;
    }
    td{
      font-weight: 300;
      font-family: 'Bellota Text';
      color: #EDDBB5;
      
    }
    td, th{
      padding: .75rem;
      border: 1px solid $color-primary;
      font-size: rem(14);
      vertical-align: top;
      text-align: left !important;
      line-height: 1.2;
     
    }
  }
}

.markdown {
  color: $color-light;
  font-family: "Lato";
  @include markdowncss_case();
}

.about-text-info-conatiner {
  .markdown {
    p {
      color: $color-DE9;
      font-weight: 400;

      @include respond(desktop) {
        font-size: rem(18);
        line-height: rem(30);
        margin-bottom: rem(60);
      }
      @include respond(ipadMobile) {
        font-size: rem(14);
        line-height: rem(26);
      }
      &:last-child {
        margin-bottom: rem(45);
      }
    }
  }
  &.article-desc-container {
    .markdown {
      p {
        margin-bottom: 2.1428571429rem;
      }
    }
  }
}

.markdown.card-para {
  color: $color-grey;
}

@mixin markdown() {
  @include respond(desktop) {
    max-width: rem(840);
    text-align: left;
  }

  ul {
    // list-style-type: decimal;
    counter-reset: my-sec-counter;
    margin-bottom: 2.8571428571rem !important;

    @include respond(desktop) {
      padding-left: rem(113);
    }
    @include respond(ipadMobile) {
      padding-left: 3rem;
    }
    li {
      @include description();
      margin-bottom: 0 !important;
      position: relative;

      @include respond(ipadMobile) {
        font-size: rem(16) !important;
        line-height: 26px !important;
      }
      &::after {
        counter-increment: my-sec-counter;
        content: counter(my-sec-counter) ". ";
        position: absolute;
        left: 0;
        top: 0;

        @include respond(desktop) {
          transform: translateX(-43px);
        }
        @include respond(ipadMobile) {
          transform: translateX(-23px);
        }
      }
    }
  }
  h3 {
    color: $color-primary;
    font-weight: 400;
    margin-bottom: rem(18);

    @include respond(desktop) {
      font-size: rem(18) !important;
    }
    @include respond(ipadMobile) {
      font-size: rem(14) !important;
    }
  }
  p {
    @include description();

    @include respond(desktop) {
      line-height: 30px;
    }
    @include respond(ipadMobile) {
      margin-bottom: rem(40) !important;
      font-size: rem(16) !important;
      line-height: 26px !important;
    }
    b {
      color: $color-primary;
    }
  }
}
