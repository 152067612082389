.radio-group {
  display: block;
  margin-bottom: rem(26);
}

.radio-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.radio-group label {
  position: relative;
  cursor: pointer;
  display: flex;
  text-transform: uppercase;
  font-family: $font-lato;
  font-weight: 300;
  color: $color-light;

  @include respond(desktopIpad) {
    font-size: rem(16);
  }
}

.radio-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid $color-primary;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: rem(6.5);
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: rem(15);
}

.radio-group input:checked {
  ~ label {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 4px;
      left: 3px;
      width: rem(9);
      height: rem(9);
      background-color: $color-primary;
    }
  }
}

.profile-wrapper {
  position: relative;
  z-index: 10;

  &:hover {
    .profile__body {
      visibility: visible;
      opacity: 1;
    }
  }
}
.cart-icon-container {
  position: fixed;
  top: 50%;
  right: -9px;
  background: rgba(106, 42, 17, 0.2);
  border: 1px solid #d67d1b;
  padding: 4px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: rem(80);
  width: rem(80);
  z-index: 2;
}
.cart-icon-container img {
  width: rem(16);
  filter: invert(1);
}
.cart-icon-container-text {
  text-align: center;
  font-size: rem(14);
  color: $white;
  font-weight: 600;
  margin-top: 5px;
}
.cart-batch {
  background-color: #f97272;
  color: #303030;
  width: rem(20);
  height: rem(20);
  text-align: center;
  font-size: rem(10);
  line-height: rem(30);
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-out {
  color: $color-light;
  font-size: rem(14);
  font-weight: 300;
  text-transform: uppercase;
  position: relative;
  margin-top: 2.8571428571rem;
  display: block;

  @include respond(mobile) {
    font-size: rem(12);
  }

  &:hover {
    text-decoration: none;
    color: $color-light;
  }

  &::after {
    @include borderLine();
    bottom: inherit;
    top: 0;
    transform: translateY(-0.5rem);
  }
}

.profile__body {
  @include flex-column();
  align-items: flex-start;

  .social-media-card__profileimage {
    width: rem(18);
    height: rem(18);
    margin-right: rem(10);
  }

  .social-media-card__profile {
    @include respond(mobile) {
      margin-bottom: rem(30);
    }

    h3 {
      color: $color-light;
      font-size: rem(14);
      font-weight: 400;

      @include respond(mobile) {
        font-size: rem(12);
      }
    }
  }

  ul {
    margin-bottom: rem(40) !important;

    li {
      position: relative;

      &:not(:last-child) {
        @include respond(desktopIpad) {
          padding-bottom: rem(10);
          margin-bottom: rem(10);
        }

        @include respond(mobile) {
          padding-bottom: rem(10);
          margin-bottom: rem(10);
        }

        a {
          &::after {
            @include borderLine();
          }
        }
      }

      a {
        color: $color-light;
        font-size: rem(14);
        font-weight: 300;

        @include respond(mobile) {
          font-size: rem(12);
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.profile {
  ~ .profile__body {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out visibility, 0.3s ease-in-out opacity;
    will-change: visibility, opacity;
    background-color: $color-secondary;
    width: rem(282);
    height: calc(100vh - 50px);
    position: absolute;

    @include respond(largedesktop) {
      top: 2.0428571429rem !important;
    }

    @include respond(extralargedesktop) {
      top: 2.0428571429rem !important;
    }

    @include respond(desktop) {
      left: 48%;
      top: rem(37);
      transform: translateX(-50%);
      padding: rem(42);
    }

    @include respond(ipad) {
      right: 0;
      transform: translateX(44px) !important;
    }

    @include respond(ipadMobile) {
      padding: rem(22);
      right: 0;
      top: rem(35);
      transform: translateX(20px);
    }

    @include respond(mobile) {
      width: rem(169);
    }
  }

  .profile-arrow {
    transition: all 0.4s ease;
  }

  &:hover {
    .profile-arrow {
      transform: rotate(180deg);
    }
  }
}

.sm-icon {
  @include respond(mobile) {
    width: rem(20);
  }
}

.top-nav {
  position: relative;
  z-index: 2;
  @include flex-y-center();
  justify-content: space-between;
  padding: rem(14) 0;

  &__search {
    &:last-child {
      @include flex-end();
    }

    @include respond(desktopIpad) {
      max-width: rem(230);
      flex-basis: rem(419);
    }

    @include respond(mobile) {
      max-width: rem(179);
      flex-basis: rem(179);
      align-items: center;
    }

    &--link {
      @include respond(desktopIpad) {
        padding: 0 rem(15);
      }

      @include respond(mobile) {
        padding: 0 rem(7);
      }

      &.profile {
        @include respond(desktop) {
          padding-right: rem(35);
        }

        @include respond(ipad) {
          padding-right: 0;
        }
      }
    }
  }
}

.age-verifiation-card {
  .react-select {
    &__menu {
      z-index: 2 !important;

      &-list {
        max-height: 250px !important;
        height: 100%;
      }
    }

    &__value-container {
      padding: 2px 12px;
    }
  }
}

.react-select {
  width: 100%;

  .css-b8ldur-Input {
    padding: 0 !important;
    margin: 0 !important;
  }

  &__input {
    color: $color-light !important;
    font-family: "libre_baskervilleregular" !important;
    font-weight: 300;
  }

  &__control {
    background-color: #382003 !important;
    color: #f4f2ea !important;
    border-color: #382003 !important;
    // height: 1.6875rem;
    // min-height: 1.5rem!important;
    font-size: 0.75rem !important;
    -webkit-box-shadow: 0 0 0 1px #382003 !important;
    box-shadow: 0 0 0 1px #382003 !important;
    border-radius: 0.3125rem !important;
    @include respond(mobile){
      min-height: rem(30) !important;
    }
    // @media (min-width: 993px) {
    //   margin-top: rem(5);
    // }
    // @include respond(desktop){
    //   padding: rem(4) rem(16);
    // }
    // @include respond(ipadMobile){
    // padding: rem(0) rem(10);
    // }
  }

  &__single-value {
    color: $color-light !important;
    font-family: "libre_baskervilleregular" !important;
    font-weight: 300;
    line-height: 1.3;
  }

  &__placeholder {
    color: $color-light !important;
    font-family: "libre_baskervilleregular" !important;
    font-weight: 300;
    max-width: 84%;

    @include respond(desktopIpad) {
      font-size: rem(12) !important;
    }
  }

  &__value-container {
    align-self: end !important;
    min-height: rem(27);
    max-width: rem(228);
    transform: translateY(-1px);
  }

  &__indicator {
    color: #d49255 !important;
    position: absolute;
    right: rem(11);
    top: 50%;
    padding: 0 !important;
    transform: translateY(-50%);

    svg {
      width: rem(18);
      height: rem(18);
    }
  }

  &__indicator-separator {
    display: none;
  }

  &__menu {
    z-index: 3 !important;

    &--items {
      background-color: #38383a;
    }
  }
}

.search-wrap {
  display: inline-block;

  @include respond(desktopIpad) {
    position: relative;
  }

  input[type="text"] {
    height: rem(24);
    font-size: rem(12);
    display: inline-block;
    font-family: "Lato";
    font-weight: 400;
    border: none;
    outline: none;
    color: $color-light;
    border-radius: 4px;
    padding: rem(5) rem(12);
    padding-right: rem(60);
    width: 0px;
    position: absolute;
    top: 0;
    right: rem(13);
    background: none;
    z-index: 3;
    transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
    cursor: pointer;

    @include respond(mobile) {
      top: 0.9285714286rem;
      right: 6.928571rem;
      height: 1.86rem;
    }
  }

  input[type="text"]:focus {
    z-index: 1;
    border-color: transparent;
    background-color: $grey-49 !important;
    cursor: text;

    @include respond(desktop) {
      width: rem(269);
    }

    @include respond(ipad) {
      width: rem(169);
    }

    @media only screen and (max-width: 500px) {
      width: 76% !important;
    }

    @media only screen and (max-width: 441px) {
      width: 73% !important;
    }

    @media only screen and (max-width: 375px) {
      width: 71% !important;
    }

    @media only screen and (max-width: 360px) {
      width: 70% !important;
    }

    @include respond(mobile) {
      width: 70%;
    }
  }

  input[type="submit"] {
    height: rem(24);
    width: rem(47);
    display: inline-block;
    float: right;
    background: url("/svg/search.svg") center center no-repeat;
    text-indent: -10000px;
    border: none;
    z-index: 2;
    cursor: pointer;
    cursor: pointer;
    transition: opacity 0.4s ease-in-out;

    @include respond(desktopIpad) {
      background-size: 21px;
    }

    @include respond(mobile) {
      background-size: 17px;
    }
  }
}

.radio-toolbar {
  @include respond(desktop) {
    display: flex;
  }

  @include respond(mobile) {
    padding-bottom: rem(20);
  }
}

.radio-toolbar-card {
  display: flex;
  cursor: pointer;

  @include respond(desktopIpad) {
    margin: rem(35) rem(10) 0 rem(10);
  }

  @include respond(mobile) {
    margin: rem(55) rem(10) 0 rem(10);
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  input[type="checkbox"]:checked ~ label {
    border-color: $color-primary;
  }

  input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;

    &:checked {
      ~ label {
        background-color: $dark-4f;
      }
    }

    &:focus {
      + label {
        border: rem(2) solid $color-primary;
      }
    }
  }

  label {
    color: $color-light;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block;
    background-color: transparent;
    padding: rem(15) rem(20);
    min-width: rem(151);
    font-weight: 300;
    margin-bottom: 0;
    border: rem(2) solid $color-primary;
  }
}

.product-filter {
  &__header {
    @include respond(desktopIpad) {
      margin-bottom: rem(75);
    }

    @include respond(mobile) {
      margin-bottom: rem(75);
    }
  }

  &__link {
    display: flex;
    position: relative;
  }

  .header-nav__link {
    font-family: $font-alfa;
    padding: 0;

    &.active {
      color: $color-primary;

      img {
        transform: rotate(-180deg);
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    @include respond(desktopIpad) {
      font-size: rem(16);
      margin: 0 rem(30);
    }

    @include respond(mobile) {
      @include flex-y-center();
      font-size: rem(12);
      margin: 0 rem(20);
    }
  }
}

.product-count {
  @include respond(desktopIpad) {
    position: absolute;
    right: 0;
    top: 0;
  }

  color: $color-light;
}

.clear-all {
  white-space: nowrap;
  text-decoration: underline;
  color: $color-light;
  text-transform: capitalize;

  @include respond(desktopIpad) {
    font-size: rem(12);
    margin: 0 rem(30);
  }
}

.check-postal-input-wrapper {
  position: relative;
  margin-bottom: rem(46);

  @include respond(desktop) {
    max-width: rem(279);
  }

  @include respond(ipadMobile) {
    width: 100%;
  }

  a {
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem(20);
    font-size: rem(12);

    &:hover {
      color: $color-primary;
      text-decoration: none;
    }
  }
}

.check-postal-input {
  width: 100%;
  color: $grey-ea;
  border: none;
  background-color: $grey-49;
  padding: rem(6) rem(70) rem(8) rem(17);
  height: rem(30);
  border-radius: rem(5);
  font-weight: 300;

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: $grey-ea;
    font-size: rem(12);
    line-height: 14px;
  }
}

.checkout-btn {
  font-size: rem(14);
  line-height: rem(26);
  color: $color-grey;
  text-transform: uppercase;
  position: relative;
  margin-left: rem(40);

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: rem(1);
    bottom: 0;
    background-color: $color-grey;
    transform: translateY(rem(8));
    left: 0;
  }
}

.form-group-checkbox {
  display: block;
  margin-bottom: rem(20);

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    &:checked {
      + label {
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: rem(2);
          left: rem(5);
          width: rem(4);
          height: rem(9);
          border: solid $color-primary;
          border-width: 0 rem(1) rem(1) 0;
          transform: rotate(45deg);
        }
      }
    }
  }

  label {
    font-family: "Bellota Text";
    font-style: normal;
    position: relative;
    cursor: pointer;
    font-weight: 300;
    color: #f9f8f7;
    line-height: 0.8;

    @include respond(desktop) {
      font-size: rem(18);
    }

    @include respond(ipadMobile) {
      font-size: rem(14);
    }

    &::before {
      content: "";
      background-color: transparent;
      border: 1px solid $color-primary;
      padding: rem(6);
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: rem(10);
      margin-bottom: rem(3);
    }
  }
}

.date-input-card {
  @include flex-x-center();

  @include respond(desktop) {
    margin-bottom: rem(35);
  }

  @include respond(ipadMobile) {
    margin-bottom: rem(25);
  }

  &__input {
    &.date {
      input {
        padding: rem(15);
      }
    }

    &.month {
      input {
        padding: rem(12);
      }
    }

    &.year {
      input {
        padding: rem(7);
      }
    }

    input {
      // text-align: center;
      margin: 0 rem(10);
      width: rem(54);
      height: rem(54);
      font-weight: 500;
      color: $color-grey;
      text-transform: uppercase;
      border: 2px solid $color-primary;

      background-color: transparent;

      &:focus {
        box-shadow: none;
        outline: 0;
      }

      &::placeholder {
        color: $color-grey;
      }
    }
  }
}

.age-verification-yes-no {
  @include flex-x-center();
  margin-bottom: rem(25);
  overflow: hidden;
  @include respond(ipadMobile) {
    margin-bottom: rem(20);
  }

  label{
    margin-bottom: 0;
  }
  input {
    position: absolute !important;
    height: 0;
    width: 0;
    border: 0;
    display: none;
    overflow: hidden;

    &:checked {
      + label {
        background-color: #382003;
        box-shadow: none;
        color: #ffcfa1;
      }
    }
  }

  // label {
  //   border: 2px solid $color-primary;
  //   font-family: "Baskerville";
  //   letter-spacing: rem(0.03);
  //   color: $color-grey;
  //   line-height: 1;
  //   padding: rem(18) rem(40);
  //   background-color: rgba(106, 42, 17, 0.1);
  //   @include flex-center();
  //   margin: 0 rem(10);
  //   // width: rem(54);
  //   // height: rem(54);
  //   border-radius: rem(37);
  //   font-size: rem(14);

  //   &:hover {
  //     cursor: pointer;
  //     background-color: rgba(106, 42, 17, 0.35);
  //     // color: $color-secondary;
  //     color: #ffcfa1;
  //   }
  // }
}

.contact-us-form__heading {
  @include respond(desktop) {
    text-align: left;
  }
}
.contact-us-subheading {
  @include respond(desktop) {
    font-family: "Bellota Text";
    color: #eddbb5 !important;
  }
}
.contact-us-form {
  text-align: left;
  // background: linear-gradient(
  //   89.75deg,
  //   #303033 5.8%,
  //   #38383a 51.02%,
  //   #303033 94.2%
  // );
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);

  @include respond(desktop) {
    padding: rem(65);
    // max-width: rem(848);
  }

  @include respond(ipad) {
    padding: rem(40);
  }

  @include respond(mobile) {
    padding: rem(28) rem(26);
  }

  &.newsletter {
    .site-form {
      @include respond(desktop) {
        margin-bottom: rem(42);
      }
    }
  }

  .form-group-checkbox {
    @include respond(desktop) {
      margin-bottom: rem(70);
    }
  }

  &__btn {
    display: inline-block;

    @include respond(ipadMobile) {
      text-align: center;
    }
  }

  &__heading {
    .section-heading-2 {
      @include respond(desktop) {
        text-align: left;
        margin-bottom: rem(18) !important;
      }

      @include respond(ipadMobile) {
        margin-bottom: rem(10) !important;
        font-size: rem(18);
        line-height: 1 !important;
        text-align: center;
      }
    }

    p {
      @include description();

      @include respond(desktop) {
        max-width: rem(622);
      }

      @include respond(ipad) {
        margin: 0 auto 2.1428571429rem auto;
        text-align: center;
        max-width: rem(367);
      }

      @include respond(mobile) {
        margin-bottom: 2.1428571429rem;
      }
    }
  }
}
.newsletter {
  background-color: rgba(106, 42, 17, 0.3);
  @include respond(desktop) {
    border-radius: rem(20);
    margin-bottom: rem(135);
  }
  @include respond(ipadMobile) {
    margin-bottom: rem(50);
    border-radius: rem(5);
  }
  p {
    color: #eddbb5;
    font-family: "Bellota Text";
    font-style: normal;
  }
}
.site-input-group {
  &:not(:last-child) {
    @include respond(desktop) {
      margin-bottom: rem(20);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(15);
    }
  }

  textarea {
    @include inputStyle();
    resize: none;
    color: rgba(255, 255, 255, 0.4) !important;
    @include respond(desktop) {
      min-height: rem(181);
      border-radius:rem(50) !important;    
    }

    @include respond(ipadMobile) {
      min-height: rem(101);
    }
  }

  input {
    @include inputStyle();
    background-color: rgba(255, 255, 255, 0.08) !important;
    font-family: "Bellota Text";
    font-style: normal;
    
    color: rgba(255, 255, 255, 0.4) !important;
    @include respond(desktop) {
      border-radius: rem(50);
      font-size: rem(16);
    }
    @include respond(ipad) {
      border-radius: rem(15);
    }
    @include respond(mobile) {
      border-radius: rem(10);
    }
  }
}

.site-form {
  @include respond(desktop) {
    margin-bottom: rem(62);
  }

  @include respond(ipadMobile) {
    margin-bottom: rem(47);
  }
}
.contactusform{
  input{
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  ::placeholder{
    color: rgba(255, 255, 255, 0.4)!important;
  }
 
}
.section-heading-2 {
  &.xs {
    line-height: 1.2;

    @include respond(desktop) {
      font-size: rem(20);
    }

    @include respond(ipadMobile) {
      font-size: rem(16);
    }
  }
}

.contact-locate-store-card-wrapper {
  @include respond(desktop) {
    margin-bottom: rem(37);
  }

  @include respond(ipadMobile) {
    margin-bottom: rem(27);
  }
}

.contact-locate-store {
  text-align: left;

  @include respond(desktop) {
    padding-left: rem(50);
  }

  @include respond(mobile) {
    margin-bottom: rem(25);
  }

  .locate-store {
    @include respond(desktop) {
      margin-left: rem(16);
    }
  }

  .section-heading-2 {
    margin-bottom: rem(20) !important;
    padding-left: rem(15);
  }

  &__map {
    @include respond(mobile) {
      width: 100%;
    }
  }

  &__card {
    display: flex;
    align-items: flex-start;

    @include respond(desktop) {
      margin-bottom: rem(27);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(25);
    }

    &.address {
      img {
        margin-top: 4px;
      }

      p {
        @include respond(desktop) {
          max-width: rem(225);
          line-height: rem(22);
        }

        @include respond(ipadMobile) {
          line-height: rem(22);
          max-width: rem(180);
        }
      }
    }

    img {
      margin-top: rem(2);
      width: rem(22);
      height: rem(22);
    }

    a {
      padding-left: rem(7);
      color: #f9f8f7 !important;
      font-family: "Bellota Text";
      font-style: normal;
      @include respond(desktop) {
        line-height: rem(22);
      }

      @include respond(ipadMobile) {
        line-height: rem(22);
      }

      color: $white;
    }

    p {
      padding-left: rem(7);
      margin-bottom: 0;
      color: #f9f8f7;
      font-family: "Bellota Text";
      font-style: normal;
      @include respond(desktop) {
        font-size: rem(16);
      }

      @include respond(ipadMobile) {
        font-size: rem(14);
      }
    }
  }
}

.locate-store {
  text-decoration: none;
  position: relative;
  color: $color-primary;
  text-transform: uppercase;
  font-weight: 300;

  &:hover {
    text-decoration: none;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $color-primary;
    left: 0;
    bottom: 0;
    transform: translateY(2px);
  }
}

.contact-locate-store-section {
  &__row {
    @include respond(desktop) {
      max-width: rem(848);

      .contact-locate-store__map {
        width: 100%;
      }
    }
  }
}

.shop-filter-heading {
  position: relative;

  .top-nav__search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    right: 0;
    max-width: rem(188);
    flex-basis: rem(188);
  }

  // .react-select__menu{
  //     margin-top: 0;
  //     border-top-left-radius: 0;
  //     border-top-right-radius: 0;
  //     border: 0 !important;
  //     outline: 0 !important;
  // }
}

input:-webkit-autofill {
  background-color: $grey-49;
  box-shadow: 0 0 0px 1000px $grey-49 inset;
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px $grey-49 inset;
}

.clear-search-btn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  @include respond(mobile) {
    transform: translateY(rem(-60));
  }
}

.sign-up-form {
  text-align: left;

  @include respond(desktop) {
    margin-top: rem(75);
    max-width: rem(410);
  }

  .contact-us-form__heading {
    .section-heading-2 {
      @include respond(desktop) {
        line-height: 1 !important;
        margin-bottom: rem(10) !important;
      }
    }

    p {
      @include respond(desktop) {
        margin-bottom: rem(32) !important;
      }
    }
  }

  .site-form {
    @include respond(desktop) {
      margin-bottom: rem(90);
    }
  }
}

.shipping-add-form-section {
  .site-form {
    @include respond(desktop) {
      margin-bottom: rem(50);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(30);
    }
  }

  .form-group-checkbox {
    @include respond(desktop) {
      margin-bottom: rem(50);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(30);
    }
  }
}

.shipping-add-form {
  .site-input-group {
    @include respond(desktop) {
      margin-bottom: rem(20);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(15);
    }
  }
}

.top-nav__search {
  .react-select {
    .react-select__menu,
    .react-select__menu-list {
      // background-color: #382003 !important;
      border-radius: rem(5);
      &::-webkit-scrollbar {
        width: 7px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #777777;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #d49255;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #ffd59f;
      }
      .react-select__option {
        text-align: left !important;
        background-color: #382003 !important;
      }
    }
  }
}

.vlog-search {
  position: relative;

  input {
    width: rem(375);
    max-height: rem(52);
    padding: 1.2rem 3.2rem 1.2rem 1.5rem;

    @include respond(mobile) {
      display: none;
    }
  }

  img {
    position: absolute;
    right: rem(15);
    top: 50%;
    transform: translateY(-50%);
  }
}

.new-header-wrapper__topnav {
  position: absolute;

  @include respond(desktopIpad) {
    top: 47%;
    transform: translateY(-50%);
    left: 0;
    width: rem(230);
  }
  @include respond(mobile) {
    left: 0;
    top: rem(9);
    width: 100%;
  }
  .top-nav {
    padding: 0;
  }
}
.new-outside-header__logowrapper {
  @include respond(mobile) {
    padding-top: 3.5rem !important;
  }
}
