@mixin screenScale() {
  transform-origin: top;
  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    // transform: scale(1.1);
  }
  @media only screen and (min-width: 1366px) and (max-width: 1399px) {
    // transform: scale(1.18);
  }
  @media only screen and (min-width: 1400px) and (max-width: 1680px) {
    // transform: scale(1.246);
  }
  @media only screen and (min-width: 1681px) and (max-width: 1920px) {
    // transform: scale(1.4);
  }
  @media only screen and (min-width: 1921px) {
    // transform: scale(1.5);
  }
  @include respond(desktop) {
    max-width: 88vw;
    margin: 0 auto;
  }
}
// .great-grand-section{
//   overflow: hidden;
// }
.section {
  text-align: center;
  @include respond(desktop) {
    padding: rem(40) 0;
  }

  @include respond(ipadMobile) {
    padding: rem(40) 0;
  }
}
.footer-section {
  @include respond(desktop) {
    margin-top: rem(180);
  }
  @include respond(ipadMobile) {
    margin-top: rem(120);
  }
}
.main-section {
  overflow: hidden;
}
.main-section,
.header-nav,
.top-nav {
  @include screenScale();
}

.cardfully-section {
  .container {
    @include respond(mobile) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

.serious-bussiness-section {
  @include respond(desktop) {
    padding-top: rem(290);
  }

  @include respond(ipadMobile) {
    padding-top: rem(170);
  }
}

.bartender-section {
  @include respond(mobile) {
    overflow: hidden;
  }
}

.bartender-section {
  @include respond(desktop) {
    padding-top: rem(53);
    padding-bottom: rem(92) !important;
  }
  @include respond(ipadMobile) {
    padding-top: rem(35);
    padding-bottom: rem(80) !important;
  }
}

.rotate-card-one-section {
  @include respond(desktop) {
    padding-top: rem(165);
  }
  @include respond(mobile) {
    padding-top: rem(84);
  }
}

.rotate-card-two-section {
  @include respond(desktop) {
    padding-bottom: rem(101);
    padding-top: rem(100);
  }
}
.age-number-section {
  @include respond(ipadMobile) {
    padding-top: calc(3.3571428571rem * 2) !important;
  }
  .border-outline {
    @include respond(mobile) {
      transform: rotate(7deg) translate(0.21429rem, 0) !important;
    }
  }
}
.cardfully-section {
  @include respond(desktop) {
    padding: rem(89) 0 10rem;
  }
  @include respond(ipadMobile) {
    padding: calc(3.3571428571rem * 2) 0 calc(3.3571428571rem * 3);
  }
}
.product-detail.rotate-card-two-section {
  .customer-reviews {
    p {
      @include respond(mobile) {
        margin-bottom: 0 !important;
      }
    }
  }
  .section-heading-4 {
    text-align: left;
    @include respond(desktopIpad) {
      margin-bottom: rem(35);
    }
    @include respond(mobile) {
      margin-bottom: rem(20);
    }
  }
}
.recommended-section {
  &.product {
    .section-heading-4 {
      text-align: left;

      span {
        @include respond(mobile) {
          display: block;
        }
      }
      @include respond(desktopIpad) {
        margin-bottom: rem(35);
      }
      @include respond(mobile) {
        margin-bottom: rem(35);
        text-align: center;
        line-height: rem(24);
      }
    }
  }
  .section-heading-2 {
    @include respond(desktopIpad) {
      max-width: rem(578);
      margin: 0 auto rem(45) auto !important;
    }
    @include respond(mobile) {
      max-width: rem(261);
      margin: 0 auto rem(40) auto !important;
    }
  }
}
.some-people-section {
  @include respond(ipadMobile) {
    padding-top: calc(6.3571428571rem);
  }
  .section-heading-2 {
    @include respond(desktopIpad) {
      margin: 0 auto rem(45) auto !important;
    }
    @include respond(mobile) {
      margin: 0 auto rem(40) auto !important;
    }
    span {
      @include respond(mobile) {
        display: block;
      }
    }
  }
}

.site-masonry-section {
  padding-bottom: 0 !important;

  @include respond(mobile) {
    padding-top: calc(3.3571428571rem * 3);
  }
}

.rotate-five-section {
  @include respond(desktopIpad) {
    padding-top: 12.357143rem;
  }
  @include respond(mobile) {
    padding-top: 6.357143rem;
  }
}

.about-first-section {
  @include respond(desktop) {
    padding-top: 3.7857142857rem;
    padding-bottom: 4.357143rem;
  }
  @include respond(ipadMobile) {
    padding-bottom: 0;
  }
  .card-para {
    @include respond(desktop) {
      font-size: rem(18);
      line-height: rem(30);
    }
  }
  .section-heading-2 {
    @include respond(desktop) {
      font-size: rem(32) !important;
      line-height: rem(42) !important;
      margin-bottom: rem(40) !important;
    }
    @include respond(desktop) {
      font-size: rem(32) !important;
      line-height: rem(42) !important;
      margin-bottom: rem(40) !important;
    }
  }
}
.card-para {
  margin: 0 auto;
  @include respond(desktop) {
    max-width: rem(728);
    line-height: rem(30);
    font-size: rem(18);
  }
}
.about-page {
  .site-jumbotron {
    position: relative;
    z-index: 0;
    background-image: url("/images/about/about-banner.jpg");
    .section-heading-2 {
      position: absolute;
      text-align: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .section-heading-2 {
    margin-bottom: rem(10) !important;
  }
  .rotate-card-two {
    background-image: url("/images/about/card-img.png");
  }
  .card-wrapper.wrapper-box-shadow .border {
    @include respond(desktop) {
      clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    }
    @include respond(ipadMobile) {
      clip-path: polygon(0 8%, 100% 0, 100% 100%, 0% 100%) !important;
    }
    height: 100%;
    &::after {
      @include respond(desktop) {
        clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
      }
      @include respond(ipadMobile) {
        clip-path: polygon(0 8%, 100% 0, 100% 100%, 0% 100%) !important;
      }
    }
    @include respond(ipadMobile) {
      transform: translate(-1.428571rem, 1.5rem);
    }
    @include respond(mediumMobile) {
      transform: translate(-0.2rem, 1.2rem);
      width: 97%;
      height: 100%;
      clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
      &::after {
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
      }
    }
    @include respond(smallmobile) {
      clip-path: polygon(0 7%, 100% 0, 100% 100%, 0% 100%);
      &::after {
        clip-path: polygon(0 7%, 100% 0, 100% 100%, 0% 100%);
      }
    }
  }
  .card-wrapper.rotate-card-two-wrapper {
    .rotate-card-two {
      @include respond(ipadMobile) {
        max-width: calc(100% - 20px);
      }
    }
    .border {
      height: 33rem;
      @include respond(ipadMobile) {
        height: 33rem;
      }
      @include respond(mediumMobile) {
        height: 31rem;
        transform: translate(-0.2rem, 1.2rem);
        width: 97%;
      }
    }
  }
  .card-wrapper.award-border-wrapper {
    .rotate-card-one {
      @include respond(mediumMobile) {
        margin-left: 1rem;
      }
      @include respond(smallmobile) {
        padding: 6rem 1rem 3rem;
      }
    }
    .border {
      clip-path: polygon(0 3%, 100% 0, 100% 100%, 0% 100%);
      height: 100.5%;
      &::after {
        clip-path: polygon(0 3%, 100% 0, 100% 100%, 0% 100%);
      }
      @include respond(ipadMobile) {
        transform: translate(-1.4285714286rem, 1.5rem);
        clip-path: polygon(0 2%, 100% 0, 100% 100%, 0% 100%);
        height: 100%;
        &::after {
          clip-path: polygon(0 2%, 100% 0, 100% 100%, 0% 100%);
        }
      }
      @include respond(mediumMobile) {
        transform: translate(-0.2rem, 1.2rem);
        width: 97%;
        clip-path: polygon(0 1%, 100% 0, 100% 100%, 0% 100%);
        height: 100%;
        &::after {
          clip-path: polygon(0 1%, 100% 0, 100% 100%, 0% 100%);
        }
      }
    }
  }
}

.about-card-section {
  &.rotate-card-one-section {
    padding: 6rem 0 10rem;
    @include respond(ipadMobile) {
      padding: 4rem 0 7rem;
    }
  }
  .rotate-card-one {
    display: block;
    padding: rem(180) rem(100) rem(70) rem(80);
    clip-path: polygon(0 2.5%, 100% 0, 100% 100%, 0% 100%);
    @include respond(ipadMobile) {
      padding: rem(150) rem(40) rem(70);
      clip-path: polygon(0 2%, 100% 0, 100% 100%, 0% 100%);
    }
    @include respond(mediumMobile) {
      padding: rem(120) rem(25) rem(50);
      clip-path: polygon(0 1%, 100% 0, 100% 100%, 0% 100%);
    }
  }
}

.product-filter-section {
  margin-top: calc(5.214286rem * 2);

  @include respond(mobile) {
    margin-top: 11.214286rem;
  }
}

.common-header-spacing {
  margin-top: 10rem;
  @include respond(mobile) {
    margin-top: 8rem;
  }
}

.product-filter-main {
  .footer-section {
    margin-top: 0;
    z-index: 100;
  }
}
.about-text-wrapper {
  max-width: 50rem;
  margin: 0 auto;

  .btn {
    border: 1px solid;

    &:hover {
      border: 1px solid;
    }
  }
}
.about-text-info-conatiner {
  font-family: "Lato";
  text-align: left;
  p {
    color: $color-DE9;
    font-size: rem(18);
    line-height: rem(30);
    font-weight: 400;
    @include respond(ipadMobile) {
      font-size: rem(15);
      line-height: rem(24);
    }
    span {
      color: $color-primary;
    }
  }
}

.product-desc-main-section {
  @include respond(desktop) {
    margin-top: rem(88);
  }

  @include respond(ipadMobile) {
    padding: 11.571429rem 0;
  }
}

.rotate-card-two-section {
  &.product-detail {
    .rotate-card-two__image {
      display: none;
    }

    .section-heading-2 {
      max-width: rem(482);
      margin-bottom: rem(15) !important;

      @include respond(mobile) {
        font-size: rem(26);
        line-height: rem(26);
        margin-bottom: 20px !important;
      }
    }
    .border {
      @include respond(mobile) {
        width: 88% !important;
      }
    }
    .rotate-card-two {
      @include respond(mobile) {
        padding: 3.9285714286rem 2.2142857143rem rem(180) 2.2142857143rem !important;
      }
    }
    p {
      display: block !important;
      color: $color-light;

      @include respond(desktop) {
        font-size: rem(18);
        margin-bottom: rem(95);
      }
      @include respond(mobile) {
        line-height: rem(18);
        width: 70%;
        margin-bottom: rem(60) !important;
      }
      @include respond(ipadMobile) {
        font-size: rem(14);
        margin-bottom: rem(35);
      }
    }
  }
}

.product-detail-grand-section {
  .footer-section {
    margin-top: rem(60);
  }
}

.product-range-grand-section {
  .about-first-section {
    .section-heading-2 {
      span {
        @include respond(mobile) {
          display: block;
        }
      }
    }
  }
  .rotate-card-five-wrapper .section-heading-2 {
    .section-heading-2 {
      @include respond(mobile) {
        font-size: rem(22);
      }
    }
  }
}

.year-150-section {
  .section-sub-heading-2 {
    text-transform: capitalize;
  }
}

.timeline-section {
  .card-wrapper {
    .border {
      @include respond(desktop) {
        transform: translate(-1.528571rem, 1.9285714286rem);
      }
    }
  }
}
.age-number-section {
  .cardfully {
    @include respond(mobile) {
      background-position: 20% -27px !important;
    }
  }
}

.age-verifiation-section {
  min-height: 100vh;
  @include flex-center();
  
  @media only screen and (min-width: 2560px) {
    padding: 3rem 0;
  } 
  @include respond(ipad){
    align-items: center;
  }
}

.blending-section {
  .section-heading-2 {
    @include respond(mobile) {
      max-width: rem(164);
      font-size: rem(28) !important;
      line-height: rem(36) !important;
    }
  }
}

.product-range-grand-section {
  .rotate-card-five-wrapper .rotate-card-two {
    @include respond(mobile) {
      padding-bottom: 15.057143rem !important;
    }
  }
}
.terms-and-condition-section{
  background-image:radial-gradient(329.87% 29.49% at 64.92% 22.6%, #183047 0%, #100101 100%) !important;
}
.privacy-policy-grandsection{
  background-image: radial-gradient(329.87% 29.49% at 64.92% 22.6%, #183047 0%, #100101 70%) !important;
}
.contact-us-grandsection{
  background-image: radial-gradient(791.79% 78.68% at 45.21% 22.5%, #183047 0%, #100101 100%) !important;
}
.section.static-pages-section {
  @include respond(desktop) {
    padding-top: 9.6875rem !important;
    
  }
  @include respond(ipadMobile) {
    padding-top: 7.214286rem !important;
    text-align: left;
  }
}

.component-first-secion{
  @include respond(desktop) {
    padding: rem(51) 0 !important
  }
}
.no-location-card {
  &__desc {
    color: $color-grey;
    font-weight: 300;
  }
}
.video-section {
  @include respond(desktop) {
    margin-top: 0.88rem;
  }
  @include respond(ipad) {
    margin-top: rem(50);
  }
  @include respond(mobile) {
    margin-top: rem(107);
  }
}

.speaker {
  position: absolute;
  right: 0;
  width: 100px;
  height: 100px;
  background-color: #303033;
  border-radius: 50%;
  transform: scale(0.3);
  z-index: 2;
  cursor: pointer;

  @include respond(ipad) {
    transform: scale(0.3) translateY(102px);
  }
  @include respond(mobile) {
    transform: scale(0.3) translateY(-52px) translateX(33px);
  }
  &.stop {
    &::after {
      position: absolute;
      width: 7px;
      content: "";
      height: 90%;
      background: #303033;
      z-index: 222;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      top: 50%;
    }
    .sound {
      span {
        display: none;
      }
    }
  }
  .base {
    box-sizing: border-box;
    display: inline-block;
    background: $color-primary;
    background-clip: content-box;
    width: 4rem;
    height: 4rem;
    border: 1.4em solid transparent;
    border-right-color: $color-primary;
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    left: 0;
    border-radius: 18px;
  }
  .sound {
    position: absolute;
    width: 40%;
    right: 0;
    height: 100%;

    span {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 5px;
      background-color: $color-primary;
      transform-origin: left center;
      animation: scale-sound-data 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;

      &:first-child {
        width: 5px;
        height: 20%;
        left: 5%;
        animation-delay: 0.3s;
      }
      &:nth-child(2) {
        height: 30%;
        left: 20%;
        animation-delay: 0.6s;
      }
      &:last-child {
        height: 40%;
        left: 35%;
        animation-delay: 0.9s;
      }
    }
  }
}

@keyframes scale-sound-data {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.faqs-section {
  @include respond(desktop) {
    padding-top: rem(160);
  }
  @include respond(ipadMobile) {
    padding-top: rem(60);
  }
  .section-heading-2 {
    text-align: left;

    @include respond(desktop) {
     margin-bottom: rem(20) !important;
    }
    @include respond(ipadMobile) {
      margin-bottom: rem(20) !important;
    }
    span {
      > span {
        text-transform: lowercase;
      }
    }
  }
}

.home-page{
    .slick-slider{
      @include respond(mobile) {
        z-index: 1;
      }
  }
}

@include respond(ipadMobile) {
  .section.year-150-section .stick-element,
  #justWhisky, #legacyVault {
    top: -80px;
    transform: translateY(0);
  }
}

.blog-container {
  max-width: 51.5rem;
  margin: 0 auto;
  text-align: left;
  p {
    font-family: 'Lato';
    font-size: rem(18);
    font-weight: 400;
    line-height: rem(30);
    margin-bottom: rem(30);
    color: $color-grey;
    @include respond(mobile) {
      font-size: rem(14);
      line-height: rem(26);
      margin-bottom: 1.5rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  p.highlight {
    font-family: 'Lato';
    font-size: rem(22);
    font-style: italic;
    font-weight: 400;
    line-height: rem(34);
    margin-bottom: rem(30);
    color: $color-primary;
    @include respond(mobile) {
      font-size: rem(20);
      line-height: rem(30);
    }
  }
}

.author-info-section .seperator {
  border-top: 1px solid $color-primary;
  margin-bottom: rem(70);
}

.vlog-page {
  .blog-videoplayer {
    margin-bottom: 2rem;
    @include respond(mobile) {
      margin-bottom: 1.5rem;
    }
  }
}

.vlog-realted-content {
  .blog-hash-container {
    margin-bottom: 20px;
    @include respond(mobile) {
      margin-bottom: 26px;
      .blog-hash {
        margin-bottom: 0;
      }
    }
  }
}

.blog-with-bottle-page {
  .common-header-spacing {
    padding-bottom: 8rem;
  }
  @include respond(mobile) {
    .common-header-spacing {
      padding-bottom: 6rem;
    }
    .rotate-card-five-wrapper .rotate-card-two {
      padding: 3.9285714286rem 2.2142857143rem 13rem 2.2142857143rem !important;
    }

  }
}

.whisky-compare-section {
  padding-bottom: 8rem;
}

.store-locator-page{
  .product-filter-main{
    background: radial-gradient(35.97% 50% at 50% 50%, rgba(62, 75, 96, 0.58) 0%, rgba(24, 48, 71, 0.58) 48.44%, rgba(2, 0, 0, 0.58) 100%);
  }
  .shop-whiskey-card-wrapper__heading{
    width: 100%;
  }
}