.overflow-hidden {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.font-heading {
  font-family: "Alfa Slab One", sans-serif;
}

.font-medium {
  font-weight: 500;
}

.text-primary {
  color: #f9c467 !important;
}

.bg-primary {
  background-color: #f9c467 !important;
}

.justify-contents-end {
  justify-content: flex-end;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

// store locator start
.store-locator-page {
  @include respond(mobile) {
    .filter-popup {
      background: #1d1d20;
      color: #fff;
      border-radius: rem(20);
      position: absolute;
      overflow: hidden;

      &.closed {
        display: none;
        opacity: 0;
        pointer-events: none;
      }

      .filter-clear-btn {
        display: block;
        border: 0;
        background: transparent;
        margin-left: auto;
        color: #f9c467;
        padding: rem(20) rem(25);
      }

      .filter-popup-body {
        padding: rem(20);
        height: 50vh;
        overflow-y: auto;
        text-align: left;
        padding-bottom: rem(110);

        &::-webkit-scrollbar {
          width: rem(10);
        }

        &::-webkit-scrollbar-track {
          background: inherit;
        }

        &::-webkit-scrollbar-thumb {
          background: #f9c467;
          border-radius: rem(10);
        }
      }

      .filter-title {
        padding-bottom: rem(10);
      }

      .filter-pill {
        background-color: #242425;
        border-radius: rem(20);
        padding: rem(10) rem(20);
        color: #fff;
        margin-right: rem(10);
        margin-bottom: rem(10);
        border: 0;

        &.active {
          background: #f9c467;
          color: #1d1d20;
        }
      }

      .filter-popup-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 0 0 rem(20) rem(20);
        padding: rem(20) rem(25);
        background: #242425;
        display: flex;
        align-items: center;
        box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.25);
      }

      .filter-footer-btn {
        border: 0;
        padding: rem(10) rem(30);
        border-radius: rem(5);
        margin-right: rem(25);
        border-style: solid;
        border-width: rem(2);
      }

      .filter-apply-btn {
        background: #f9c467;
        color: #0f0f0f;
        border-color: #f9c467;
      }

      .filter-close-btn {
        background: transparent;
        color: #f9c467;
        border-color: #f9c467;

        &:hover {
          background: #f9c467;
          color: #0f0f0f;
        }
      }
    }

    .react-select-dropdown {
      max-width: none !important;
    }

    .mobile-store-locator-top-bar {
      position: fixed;
      top: rem(115);
      left: 0;
      right: 0;
      width: 90%;
      display: block;
      margin: 0 auto;
      z-index: 1;

      .site-input-group {
        display: flex;
      }

      .maps-autocomplete-input {
        background: #0f0f0f !important;
        border-radius: rem(20);
        line-height: 2;
      }

      .mobile-store-locator-top-bar-btn {
        margin-right: auto;
        color: #ffffff;
        background: transparent;
        border: 0;
        padding: rem(5) rem(5) rem(5) rem(15);

        svg {
          width: rem(25);
          height: rem(25);
        }
      }
    }

    .store-locator-title {
      display: none;
    }

    .product-filter-section {
      margin-top: 0;
      padding-top: 0;
    }

    .google-map-mobile {
      height: 84vh !important;
      max-height: 84vh !important;
      min-height: 84vh !important;
    }

    .bottom-sheet {
      display: block;
      background-image: url("/images/nikfaldo-details-bg.png");
      @include bgCover();
      max-height: 60vh;
      width: 100%;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      padding: rem(5) rem(5) rem(15) rem(5);
      // margin-bottom: rem(20);
      z-index: 4;
      border-radius: rem(20) rem(20) 0 0;
      transition: all 300ms ease-in-out;

      &.closed {
        bottom: -100%;
        display: none;
      }

      .bottom-sheet-body {
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: inherit;
        min-height: inherit;
        max-height: inherit;
        margin-left: auto;
        margin-right: auto;
        overflow-x: hidden;
        overflow-y: auto;
        padding: rem(5) 0 15vh 0;
        border-radius: rem(20) rem(20) 0 0;

        &::-webkit-scrollbar {
          width: rem(10);
        }

        &::-webkit-scrollbar-track {
          background: inherit;
        }

        &::-webkit-scrollbar-thumb {
          background: #3f3f3f;
          border-radius: rem(10);
        }
      }

      .bottom-sheet-close-btn {
        display: block;
        margin: rem(10) auto;
        width: rem(74);
        height: rem(5);
        background: #3f3f3f;
        border: 0;
        border-radius: rem(10);
      }

      .mobile-extended-card {
        &.closed {
          display: none !important;
        }
      }
    }
  }

  .react-select__menu {
    > div {
      padding: 0;
    }
  }

  .nav-tabs {
    border-bottom: 0;

    @include respond(mobile) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      justify-content: space-around;
      background: #242425;
      z-index: 20;
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.25);
    }

    .nav-link {
      color: #fff;
      background: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid transparent;

      @include respond(mobile) {
        padding: rem(25) rem(20);
      }

      &.active {
        color: #f9c467;
        border-bottom-color: #f9c467;
      }
    }
  }
}

// store locator end
@media (min-width: 992px) {
  .pac-target-input {
    padding-left: rem(17) !important;
  }
}

.google-maps-container {
  .sightings-card.shop-card-extended {
    width: 50% !important;

    .carousel {
      // flex: 3;

      .carousel-inner {
        .carousel-item {
          display: flex;
          flex: 1;
          flex-grow: 1;

          height: 100%;
          // height: 100%;
          // max-height: 100%;

          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .shop-card-extended-details {
      // flex: 2;
      height: auto;

      h4 {
        font-size: 25px;
        line-height: 167%;
      }

      .address-text {
        font-size: 14px !important;
        line-height: 167%;
      }
    }

    .shop-card-extended-details-actions {
      a {
        p {
          font-size: 14px;
          line-height: 167%;
        }
      }
    }
  }
}

.location-data-container {
  .maps-autocomplete-input input {
    border-radius: rem(10) !important;
    background: #0f0f0f;
  }

  .react-select {
    background: #1d1d20 !important;
  }

  .react-select__indicator {
    color: #464649 !important;
  }

  .react-select__control {
    // background: #1d1d20 !important;
    border-radius: rem(10) !important;

    &--is-focused {
      border-color: #f9c467 !important;

      .react-select__indicator {
        color: #f9c467 !important;
      }
    }
  }

  .google-maps-container {
    @include respond(mobile) {
      max-height: 84vh !important;
      height: 84vh;

      iframe {
        left: 0;
        top: 0;
        height: 100%;
      }
    }

    @include respond(desktop) {
      #map {
        // max-height: fit-content !important;
      }
    }

    @media (min-width: 360px) and (max-width: 700px) {
      .carousel-item {
        height: 320px !important;
      }
      .sightings-card.shop-card-extended {
        width: 100% !important;
      }
    }
  }

  .shop-card-extended {
    position: absolute;
    top: 0;
    bottom: 0;
    //width: 85%;
    height: 100%;
    background-color: #0f0f0f;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    .shop-card-close-btn {
      position: absolute;
      right: rem(10);
      top: rem(10);
      width: rem(36);
      height: rem(36);
      border-radius: rem(99);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      background-color: rgba(0, 0, 0, 0.5);
      color: #ffffff;
      z-index: 100;

      .svg {
        font-size: rem(35);
      }
    }

    .shop-card-extended-img {
      width: 100%;
      height: 70vh;
      object-fit: cover;
      object-position: center;
    }

    .shop-card-extended-details {
      // height: 30%;
      background-color: #0f0f0f;
      color: #c4c4c4;
      flex-grow: 2;

      h4 {
        font-size: 25px;
        line-height: 167%;
      }

      .address-text {
        font-size: 14px !important;
        line-height: 167%;
      }

      .shop-card-extended-details-actions {
        svg {
          display: block;
          margin: 0 auto;
        }

        a {
          color: #c4c4c4;
          text-decoration: none;

          p {
            font-size: 14px;
            line-height: 167%;
          }
        }
      }
    }

    .address-text {
      line-height: 1.5;
    }
  }
}

#maps-listing-container {
  position: relative;
  max-width: 100%;
  top: auto;
  left: auto;
  transform: none;
}

.shop-closed {
  color: #ff8181;
}
.address-card.custom-info-window {
  background-color: rgba(56, 32, 3, 0.5);
}
.address-card.custom-info-window .address-card__text {
  text-align: left;
}
.address-card {
  display: flex;
  padding: 1.25rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);

  .shop-name {
    color: #ffffff;
  }

  &:hover {
    .shop-name {
      background: -webkit-radial-gradient(
        0% 50%,
        100% 250.08%,
        #ffd59f 0%,
        #ca974a 36.99%,
        #ffcfa4 67.58%,
        #d59255 100%,
        #e19e61 100%
      );
      background: radial-gradient(
        100% 250.08% at 0% 50%,
        #ffd59f 0%,
        #ca974a 36.99%,
        #ffcfa4 67.58%,
        #d59255 100%,
        #e19e61 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    background-color: rgba($color: #382003, $alpha: 0.5);
    border-radius: rem(10);
  }

  .address-card__image {
    flex-shrink: 0;
    height: auto;
    width: 9.3rem;
    object-fit: cover;
    object-position: center;
    // align-self: flex-start;
    margin-right: rem(18);
    border-radius: rem(5) !important;
    border-radius: 0.4125rem;
  }

  .address-card__text {
    width: calc(100% - 9.3rem);
    @include respond(mobile) {
      text-align: left;
    }
    h4 {
      font-size: rem(16) !important;
      line-height: 1.6 !important;
      font-family: "Alfa Slab One";
      font-weight: 400;
      color: $white;
    }

    .address-text {
      font-weight: 400;
      color: $white;
      @include respond(desktopIpad) {
        font-family: "Alfa Slab One";
        font-size: rem(18) !important;
        line-height: 1.6 !important;
      }
      @include respond(mobile) {
        font-size: rem(14) !important;
        line-height: 1.3 !important;
      }
    }
  }
}

.shop-name {
  color: #fff;
  font-size: rem(25) !important;
}

.address-text {
  color: #c4c4c4;
  font-size: rem(14) !important;
  line-height: 1.5;
}

.shop-actions {
  margin-top: rem(10);
  display: flex;
  align-items: center;

  .shop-action-link {
    text-decoration: none;
    margin-right: rem(10);
    display: inline-flex;
    align-items: center;

    .shop-action-text {
      margin-left: rem(10);
      color: #c4c4c4;

      font-size: 14px !important;
      line-height: 167%;
    }

    @include respond(mobile) {
      &.direction {
        background: #f9c467;
        border-radius: rem(99);
        padding: rem(12) rem(18);

        .shop-action-text {
          color: #0f0f0f !important;
        }
      }

      &.call {
        background: #242425;
        border-radius: rem(99);
        padding: rem(12) rem(18);

        .shop-action-text {
          color: #f9c467 !important;
        }
      }
    }
  }
}

.site-masonry-section {
  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    bottom: -56px;

    li {
      border-radius: 99px;
    }
  }

  @media (max-width: 760px) {
    .insta-heading-2 {
      font-size: rem(20);
    }
  }
}

.insta-gallery-new {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: rem(20) rem(20);

  @media (max-width: 760px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .item1,
    .item2,
    .item3,
    .item4,
    .item5,
    .item6 {
      grid-area: auto !important;
    }

    .insta-text p {
      font-size: rem(12);
    }
  }

  .item1 {
    grid-area: 1 / 1 / 2 / 2;
  }

  .item2 {
    grid-area: 1 / 2 / 2 / 3;
  }

  .item3 {
    grid-area: 1 / 3 / 2 / 4;
  }

  .item4 {
    grid-area: 2 / 1 / 3 / 2;
  }

  .item5 {
    grid-area: 2 / 2 / 4 / 4;
  }

  .item6 {
    grid-area: 3 / 1 / 4 / 2;
  }

  .insta-item {
    position: relative;

    .insta-text {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      overflow-y: auto;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: all 300ms linear;

      p {
        padding: rem(20);
        font-size: rem(18);
      }
    }

    .insta-text:hover {
      background-color: rgba(0, 0, 0, 0.8);
      opacity: 1;
    }
  }
}

.about-us-2 {
  .main-section {
    overflow: visible;
    // max-width: 1410px !important;
  }

  .timeline-cover {
    background-position: center;
    background-size: cover;

    .title {
      font-size: rem(40);
      line-height: 1.2;
    }
  }

  .timeline-cover::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .timeline {
    position: relative;
    overflow-x: visible;
    overflow-y: visible;
    height: 100%;
    z-index: 10;

    .timeline-item-bg {
      background-size: cover;
      background-position: center;
      position: relative;
    }

    .timeline-item-bg::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.95);
    }

    .timeline-item {
      .title {
        // color: #fff;
        color: $color-primary;
        font-size: rem(30);
        line-height: 1.4;
      }

      .content {
        font-size: rem(20);
        line-height: 1.2;
      }

      @media (min-width: 756px) {
        .title {
          font-size: rem(48);
          line-height: rem(58);
        }

        .content {
          font-size: rem(30);
          line-height: 1.2;
        }
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 100%;
    }

    @include respond(mobile) {
      img {
        transform: inherit !important;
      }
    }

    section {
      min-height: 90vh;
      padding: 8rem 0 max(5vh, 2rem);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .container {
      width: 100%;
      max-width: 80rem;
      padding: 0 max(5vw, 1rem);
    }

    nav {
      top: 0;
      width: 100%;
      left: 0;
      background: #2b2b2b;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      overflow: hidden;
      height: 6rem;
      z-index: 9;

      &::after {
        content: "";
        position: absolute;
        top: 1.3rem;
        left: 0;
        width: 100%;
        height: 0.4rem;
        background: #bfbebe;
        pointer-events: none;
      }
    }

    .marker {
      position: absolute;
      top: 1.75rem;
      left: 4rem;
      width: 1rem;
      height: 1rem;
      transform: translate3d(-50%, -50%, 0);
      background: transparent;
      border-radius: 100%;
      border-color: transparent;
      z-index: 2000;

      &::before {
        content: "";
        position: absolute;
        top: rem(-3);
        right: 100%;
        width: 4rem;
        height: 0.4rem;
        background-color: #f9c467;
      }
    }

    .nav__track {
      position: relative;
      min-width: max(200rem, 200%);
      padding: 1rem max(100rem, 100%) 0 0;
      height: rem(100);
    }

    .nav__list {
      list-style: none;
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 0;
    }

    .nav__link {
      position: relative;
      display: block;
      min-width: 8rem;
      padding: 2.25rem 1rem 0.5rem;
      text-align: center;
      color: inherit;
      text-decoration: none;
      z-index: 1;
      transition: color 150ms;

      &:hover,
      &:focus {
        color: var(--activeColor);
        text-decoration: underline;
      }

      &::after {
        content: "";
        position: absolute;
        top: 4px;
        left: 50%;
        width: 4px;
        height: 20px;
        background-color: #bfbebe;
        border-radius: 0;
        transform: translate3d(-50%, 0, 0);
        transform-origin: center center;
      }

      span {
        display: block;
        transition: transform 200ms;
      }

      &.is-active {
        span {
          transform: scale(1.4);
          color: var(--activeColor);
        }

        &::after {
          display: none;
        }
      }
    }

    .section__heading {
      font-size: clamp(2rem, 12vmin, 7rem);
      line-height: 1;
      letter-spacing: -0.06em;
      margin: 0 0 1rem;
      grid-row: 1;
      grid-column: 1 / span 7;
      align-self: end;
      position: relative;
      z-index: 1;

      span {
        display: block;
      }

      span:first-child {
        font-size: clamp(1rem, 10vmin, 5rem);
      }

      span:nth-child(2) {
        word-break: break-word;
      }
    }

    .section__image {
      grid-row: 1;
      grid-column: 5 / span 3;
      position: relative;
      box-shadow: 0.45rem 0.45rem 8rem rgb(0 0 0 / 0.3);
      align-self: center;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: hsl(var(--h, 0) 50% 50%);
        mix-blend-mode: screen;
      }
    }
  }

  .banner-bg {
    background-position: center;
    background-size: cover;
    @include respond(desktopIpad) {
      padding: rem(200) 0;
      min-height: rem(800);
      @include flex-center();
    }
    @include respond(mobile) {
      padding: rem(70) 0;
      min-height: rem(600);
      @include respond(mobile) {
        display: flex;
        align-items: flex-end;
        text-align: center;
      }
    }
    .banner {
      @include respond(mobile) {
        @include flex-x-center();
        text-align: center !important;
      }
    }
  }

  .banner {
    .overline {
      font-size: rem(15);
      line-height: rem(18);
    }

    .title {
      font-size: rem(30);
      line-height: rem(41);
    }

    .content {
      font-size: rem(12);
      line-height: 1.67;
      max-width: rem(606);
    }
  }

  .section-1 {
    .overline {
      font-size: rem(15);
      line-height: rem(18);
    }

    .title {
      font-size: rem(30);
      line-height: rem(41);
    }

    .content {
      font-size: rem(12);
      line-height: 1.67;
    }

    .clipped-border {
      clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    }

    .clipped-border-cover {
      display: block;
      position: absolute;
      width: 98%;
      height: 100%;
      left: rem(25);
      bottom: rem(25);
    }

    .clipped-border-bg {
      display: block;
      position: absolute;
      left: 2px;
      top: 2px;
      height: calc(100% - 4px);
      width: calc(100% - 4px);
    }
  }

  .section-2 {
    .overline {
      font-size: rem(15);
      line-height: rem(18);
    }

    .title {
      font-size: rem(30);
      line-height: rem(41);
    }

    .content {
      font-size: rem(12);
      line-height: 1.67;
    }

    .clipped-border {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
    }

    .clipped-border-cover {
      display: block;
      position: absolute;
      width: 97%;
      height: 97%;
      right: rem(25);
      top: rem(25);
    }

    .clipped-border-bg {
      display: block;
      position: absolute;
      right: 2px;
      top: 2px;
      height: calc(100% - 4px);
      width: calc(100% - 4px);
    }
  }

  .bottles-slider::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    background-image: url("/images/about/slider-bg.jpg");
    background-size: cover;
    background-position: center;
    display: none;
  }

  #awards {
    background: radial-gradient(
      35.97% 50% at 50% 50%,
      rgba(62, 75, 96, 0.58) 0%,
      rgba(24, 48, 71, 0.58) 48.44%,
      rgba(2, 0, 0, 0.58) 100%
    );
    @include respond(ipad) {
      padding: rem(60) 0;
    }
  }
  .bottles-slider {
    position: relative;

    .bottles-slider-card-content::-webkit-scrollbar {
      width: 5px;
    }

    .slider-plank {
      position: absolute;
      background-image: url("/images/about/slider-plank.png");
      background-position: bottom center;
      background-size: cover;
      width: 100%;
      height: rem(160);
      bottom: 0;
      display: none;
    }

    .slick-track {
      display: flex;
      flex-wrap: wrap;
      // align-items: flex-end;
    }

    .slick-dots {
      top: rem(-35);
      bottom: auto;

      li {
        border-radius: rem(99);
        width: rem(8);
        height: rem(8);
      }

      li.slick-active {
        background-color: #f9c467 !important;
        transform: none;
      }
    }

    .bottles-slider-item {
      //   padding-top: rem(60);
      //   padding-bottom: rem(60);
      position: relative;
    }

    .bottles-slider-card {
      color: #fff;
      background-color: #151515;
      border-radius: rem(10);
      margin: 0.5rem;
      padding: 2rem;
      max-width: rem(340);
      border: 1px solid #2d4054;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 1.9rem;
        height: 1.9rem;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(1.25rem);
        transform: translateX(-50%) translateY(1rem) rotate(45deg);
        z-index: 1;
        border-bottom: 1px solid #2d4054;
        border-right: 1px solid #2d4054;
        background-color: #151515;
      }
    }

    .bottles-slider-card-title {
      font-size: rem(20);
      line-height: rem(27);
    }

    .bottles-slider-card-content {
      font-size: rem(12);
      line-height: 1.67;
    }
  }

  #footer-newsletter {
    background-image: url("/images/about/newsletter-bg.jpg");
    background-size: cover;

    .footer-newsletter-title {
      font-size: rem(12);
      line-height: rem(14);
      font-weight: 500;
    }

    .footer-newsletter-input {
      box-shadow: none;
      border: 1px solid rgba(255, 255, 255, 0.28);
      border-radius: 0;
      margin-right: rem(10);
      background-color: rgba(31, 31, 32, 1);
      color: rgba(255, 255, 255, 0.42);
      height: rem(34);
    }

    .footer-newsletter-btn {
      font-size: rem(12);
      line-height: rem(14);
      height: rem(34);
      background-color: rgba(255, 255, 255, 0.78);
      outline: none;
      border: none;
      padding-left: rem(14);
      padding-right: rem(14);
    }
  }

  @media (min-width: 756px) {
    .bottles-slider {
      .slick-arrow {
        display: none;
        background-image: url("/svg/slider-arrow-primary.svg") !important;
        border-color: #f9c467 !important;
        background-color: rgba(0, 0, 0, 0.4);
        width: rem(40) !important;
        height: rem(40) !important;
      }

      .slick-arrow.slick-prev {
        left: rem(12);
      }

      .slick-arrow.slick-next {
        right: rem(12);
      }

      .bottles-slider-card {
        max-width: rem(400);
        text-align: left;
      }

      .bottles-slider-card-title {
        font-size: rem(24);
        line-height: rem(33);
      }

      .bottles-slider-card-content {
        &::-webkit-scrollbar {
          width: 7px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #7d6233;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #d49255;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #ffd59f;
        }
      }
    }

    .banner {
      .overline {
        font-size: rem(32);
        line-height: rem(38);
      }

      .title {
        font-size: rem(48);
        line-height: rem(66);
      }

      .content {
        font-size: rem(20);
        line-height: 1.67;
        max-width: rem(606);
      }
    }

    .section-1 {
      .overline {
        font-size: rem(36);
        line-height: 1.67;
      }

      .title {
        font-size: rem(48);
        line-height: rem(66);
      }

      .content {
        font-size: rem(20);
        line-height: 1.67;
      }

      .clipped-border {
        clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
      }

      .clipped-border-cover {
        display: block;
        position: absolute;
        width: 98%;
        height: 100%;
        left: rem(25);
        bottom: rem(25);
      }

      .clipped-border-bg {
        display: block;
        position: absolute;
        left: 2px;
        top: 2px;
        height: calc(100% - 4px);
        width: calc(100% - 4px);
      }
    }

    .section-2 {
      .overline {
        font-size: rem(36);
        line-height: 1.67;
      }

      .title {
        font-size: rem(48);
        line-height: rem(66);
      }

      .content {
        font-size: rem(20);
        line-height: 1.67;
      }

      .clipped-border {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
      }

      .clipped-border-cover {
        display: block;
        position: absolute;
        width: 97%;
        height: 97%;
        right: rem(25);
        top: rem(25);
      }

      .clipped-border-bg {
        display: block;
        position: absolute;
        right: 2px;
        top: 2px;
        height: calc(100% - 4px);
        width: calc(100% - 4px);
      }
    }

    #footer-newsletter {
      .footer-newsletter-title {
        font-size: rem(24);
        line-height: rem(29);
        margin-bottom: 0;
      }

      .footer-newsletter-input {
        height: rem(48);
        font-size: rem(14);
        line-height: rem(17);
        max-width: rem(307);
      }

      .footer-newsletter-btn {
        font-size: rem(14);
        line-height: rem(17);
        height: rem(48);
      }
    }
  }
}

// Response
@media (max-width: 1024px) {
  .about-us-2 {
    .info-block-section {
      padding: rem(50) 0;

      .info-section-tagline {
        // padding-top: 30px;
      }

      .info-section-image {
      }

      .info-section-description {
        padding: 20px 0;
      }
    }
  }
}

@media (max-width: 576px) {
  .about-us-2 {
    .timeline {
      .timeline-item {
        .content {
          padding-bottom: 15px;
        }
      }
    }

    .slick-slide {
      height: 55vh;
    }
  }
}

.underline-heading {
  font-family: "Bellota Text", cursive;
  font-weight: 400;
  margin-bottom: 0;
  color: $color-primary;
  text-transform: uppercase;

  @include respond(desktopIpad) {
    font-size: rem(18);
  }
  @include respond(mobile) {
    font-size: rem(12);
  }
  span {
    position: relative;

    @include respond(desktopIpad) {
      padding: 0 rem(30);
    }
    @include respond(mobile) {
      padding: 0 rem(20);
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      background-color: $color-primary;
      top: 50%;
      @include respond(desktopIpad) {
        width: rem(20);
      }
      @include respond(mobile) {
        width: rem(15);
      }
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
}

.new-about-jumbotron {
  .heading-bright-1 {
    margin-top: rem(20);
  }
  .nikfaldo-desc {
    @include respond(desktopIpad) {
      max-width: rem(354);
      margin-top: rem(60);
      font-size: rem(14);
    }
    @include respond(mobile) {
      font-size: rem(12);
      max-width: rem(231);
      margin: rem(25) auto 0 auto;
    }
  }
}
.new-about-description-card {
  .heading-bright-1 {
    margin-top: rem(20);
  }
  .nikfaldo-desc {
    @include respond(desktopIpad) {
      max-width: rem(354);
      margin: rem(50) auto 0 auto;
      font-size: rem(14);
    }
    @include respond(mobile) {
      font-size: rem(12);
      //   max-width: rem(231);
      margin: rem(25) 0 0 0;
    }
  }
}

.how-it-started-section,
.new-market-new-challenges-section {
  background: radial-gradient(
    35.97% 50% at 50% 50%,
    rgba(62, 75, 96, 0.58) 0%,
    rgba(24, 48, 71, 0.58) 48.44%,
    rgba(2, 0, 0, 0.58) 100%
  );
  .row {
    @include respond(mobile) {
      padding: 0 rem(20);
    }
  }
}

.how-it-started-image {
  text-align: center;
  @include respond(mobile) {
    margin-top: rem(45);
  }
  img {
    @include respond(desktopIpad) {
      border: 10px solid $color-primary;
    }
    @include respond(mobile) {
      border: 5px solid $color-primary;
    }
  }
}

.new-markets-original-flavour-section {
  background-image: url("/images/menu-bg.jpg");
  @include bgCover();
}
.new-markets-original-flavour-section {
  .row {
    @include respond(desktop) {
      padding: 3.5rem 2.5rem;
    }
    @include respond(ipad) {
      padding: 3rem 1rem;
    }
    background-image: url("/images/nikfaldo-details-bg.png");
    @include bgCover();
    @include respond(mobile) {
      padding: rem(35) rem(30);
      margin: 0;
      text-align: center;
    }
  }
}

.new-markets-original-flavour-image {
  position: relative;
  text-align: center;
  @include respond(mobile) {
    margin-top: rem(20);
  }
  &::after {
    content: "";
    position: absolute;

    height: rem(20);
    background: radial-gradient(
      100% 250.08% at 0% 50%,
      #ffd59f 0%,
      #d7a356 36.99%,
      #ffcfa4 67.58%,
      #d59255 100%,
      #e19e61 100%
    );

    transform: translateX(-50%);

    @include respond(desktopIpad) {
      left: 50%;
      bottom: -1.25rem;
      width: 15.375rem;
    }
    @include respond(mobile) {
      width: 19.142857rem;
      bottom: -1.25rem;
      left: 50%;
    }
  }
}

.black-bull-stands-the-test-of-time-section {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-image: url("/images/about-blabkbull-stand.jpg");
  .black-bull-stands-the-test-of-time-image {
    display: none;
  }
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) -30.23%,
      #000000 65.64%
    );
    bottom: 0;
    left: 0;
  }
  .row {
    position: relative;
    z-index: 1;
  }
  .new-about-description-card {
    text-align: center;
    max-width: rem(600);
    margin: 0 auto;
    .nikfaldo-desc {
      max-width: rem(433);
    }
  }
}
#timeline-marker-nav,
.one-vision-section {
  background-color: $black;
  background-image: url("/images/line-bg-2.png");
  @include bgCover();
}
.timeline {
  .heading-bright-1 {
    span {
      background: radial-gradient(
        100% 250.08% at 0% 50%,
        #b9741c 0%,
        #cb7c04 36.99%,
        #c15e06 67.58%,
        #ed7100 100%,
        #e19e61 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-transform: uppercase;
    }
  }
}

.about-us-2 .timeline .timeline-item-bg {
  background-image: none !important;
}

.timeline_text {
  @include respond(mobile) {
    margin-bottom: rem(20);
  }
  .nikfaldo-desc {
    max-width: rem(450);
    margin-top: rem(20);
    line-height: 1.1;
    @include respond(desktopIpad) {
      font-size: rem(40);
    }
    @include respond(mobile) {
      font-size: rem(18);
    }
  }
}
.guided-by-principle-designed-for-taste-section {
  background-image: url("/images/about-guided-by-principle.jpg");
  @include bgCover();
  .section-6-image {
    display: none;
  }
  .new-about-description-card {
    .nikfaldo-desc {
      max-width: 32.285714rem;
      @include respond(desktopIpad) {
        margin: 3.5714285714rem 0 0 0;
      }
    }
  }
  .row {
    @include respond(desktopIpad) {
      padding: rem(60) rem(70);
    }
    @include respond(mobile) {
      padding: rem(40) rem(20);
      margin: 0;
    }
    background: radial-gradient(
      35.97% 50% at 50% 50%,
      rgba(62, 75, 96, 0.5) 0%,
      rgba(24, 48, 71, 0.5) 48.44%,
      rgba(2, 0, 0, 0.5) 100%
    );
    .col-md-6 {
      &:nth-child(2) {
        @include respond(desktopIpad) {
          max-width: 75%;
          flex-basis: 75%;
        }
      }
      &:last-child {
        display: none;
      }
    }
  }
}

.the-ultimate-accodale-section {
  .row {
    // align-items: stretch !important;
    [class*="col-"] {
      &:first-child {
        @include respond(desktopIpad) {
          padding-left: 0 !important;
        }
      }
      &:last-child {
        @include respond(desktopIpad) {
          padding-right: 0 !important;
        }
      }
    }
  }

  .new-about-description-card {
    height: 100%;
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
    justify-content: center;
    background-image: url("/images/nikfaldo-details-bg.png");
    @include bgCover();
    @include respond(desktopIpad) {
      padding: rem(50);
    }
    @include respond(mobile) {
      padding: rem(30);
    }
    .nikfaldo-desc {
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
    }
  }
}

.slider-heading-1 {
  color: $color-primary1;
  font-family: "benchninebold";
  text-transform: uppercase;
  margin-bottom: 0;
  @include respond(desktopIpad) {
    font-size: rem(36);
  }
  @include respond(mobile) {
    padding: rem(25);
  }
}
.bottles-slider-card-content {
  font-family: "Bellota Text";
}
.the-ultimate-accodale-image {
  text-align: center;
}
.bottles-slider-card-content {
  margin-top: rem(20);
  .nikfaldo-desc {
    color: $white;
  }
}

.award-bottle-displa-card {
  background: rgba(106, 42, 17, 0.2);
  box-shadow: 4px 4px 5px rgb(0 0 0 / 31%);
  border-radius: 14px;
  max-width: rem(213);
  height: rem(276);
  margin: rem(40) auto 0 auto;
  display: flex;
  border: 1px solid #943502;
  img {
    margin-bottom: rem(35);
  }
  span {
    display: inline-flex;
    margin: 1rem auto 1.2rem auto;
  }
  h5 {
    display: inline-block;
    font-size: rem(14);
    font-family: "Alfa Slab One";
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    color: $white;
    margin-top: rem(20);
  }
}

.explore-nikfaldo-show-more-card__btn {
  @include respond(ipad) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.about-us-2 {
  .timeline .nav__link span {
    font-family: "Alfa Slab One";
    font-weight: 400;
    font-size: rem(20);
    color: #f9c467;
  }
}
.the-ultimate-accodale-section {
  .row {
    [class*="col-"] {
      &:first-child {
        @include respond(desktop) {
          padding-right: 0;
        }
      }
    }
    @include respond(desktop) {
      border: 1px solid $color-primary;
    }
  }
}
