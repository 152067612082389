.noklo-faldo-video-wrapper {
  position: relative;
  @include respond(desktop) {
    min-height: calc(100vh - 10.5714285714rem);
  }
}
video {
  width: 100% !important;
  height: 100% !important;
}
.niklo-faldo-video {
  padding-top: 54%;
  video {
    object-fit: cover;
  }
  @include respond(largedesktop) {
    margin-top: 1rem;
  }
  @include respond(ipadMobile) {
    margin-top: 8.6rem;
  }
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    html {
      background-color: #161616;
    }
  }
  &__video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.95);
    transition: 0.5s ease-in-out opacity, 0.5s ease-in-out visibility,
      0.5s ease-in-out transform;
    will-change: opacity, visibility, transform;
    &:nth-child(1) {
      background-color: rgba($color: #161616, $alpha: 1);
    }
    &:nth-child(2) {
      background-color: #544220;
    }
    &:nth-child(3) {
      background-color: #0a0a0acc;
    }
    &:nth-child(4) {
      background-color: #433115;
    }
    &.active {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: rem(3);
      background-image: linear-gradient(
        90deg,
        #544220 4.98%,
        #dac278 27.78%,
        #cab36e 65.18%,
        #433115 97.82%
      );
      bottom: 0;
      left: 0;
    }
  }
}
.video-timeline-wrapper {
  display: flex;
  max-width: rem(800);
  margin: 0 auto;
  position: absolute;
  width: 100%;
  bottom: rem(20);
  left: 50%;
  transform: translateX(-50%);
  @include respond(ipadMobile) {
    width: calc(100% - 30px);
  }
  &::before {
    content: "";
    position: absolute;

    background-color: #f1c576;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    @include respond(desktop) {
      width: rem(10);
      height: rem(10);
    }
    @include respond(ipadMobile) {
      width: rem(6);
      height: rem(6);
    }
  }
  .video-timeline-item {
    position: relative;
    display: flex;
    align-items: center;
    width: 50%;
    transition: width 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    cursor: pointer;
    padding: 16px 0;
  }
  .video-timeline-item-line-wrapper {
    position: relative;
    width: 100%;
  }
  .video-timeline-item-line-progress {
    position: absolute;
    z-index: 2;
    height: 1px;
    background-color: #ece1ae;
    opacity: 1;
    transition: 1s ease;
  }
  .video-timeline-item-line-bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 1px;
    background-color: #ece1ae;
  }
  .video-timeline-dot-filled {
    background-color: $white;
  }
  .video-timeline-active {
    width: 100%;
    pointer-events: none;
  }
  .video-timeline-dot {
    flex-shrink: 0;
    transition: background 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    background-color: #f1c576;
    border-radius: 50%;
    @include respond(desktop) {
      width: rem(10);
      height: rem(10);
    }
    @include respond(ipadMobile) {
      width: rem(6);
      height: rem(6);
    }
  }
}

.nickfaldo-header-section {
  .header-nav {
    @include respond(desktop) {
      padding: 1.7rem rem(32);
    }
    @include respond(ipadMobile) {
      padding: 1rem 1.5rem;
    }
    background-image: linear-gradient(
      93.99deg,
      #0d0d0d 8.91%,
      #131313 29.71%,
      #282828 54.34%,
      #141414 78.11%,
      #0e0e0e 99.4%
    );
    .site-logo {
      width: rem(147);
    }
  }
  .nickfaldo-link {
    @include flex-between();
    @include respond(desktop) {
      flex-basis: 65%;
      max-width: 65%;
    }
    &__edition {
      @include respond(desktop) {
        display: none;
      }

      @include respond(ipadMobile) {
        font-size: rem(9);
        font-family: "libre_baskervilleitalic";
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        color: #dfddd7;
      }
    }
    &__shop {
      @include respond(ipadMobile) {
        display: none !important;
      }
      &:hover {
        text-decoration: none;
      }
    }
    ul {
      @include respond(ipadMobile) {
        display: flex;
        flex-direction: revert;
        flex-wrap: wrap;
        max-width: rem(225);
        margin: 0 auto !important;
        justify-content: space-between;
        padding-top: rem(100);
      }
    }
    @include respond(ipadMobile) {
      position: fixed;
      width: 100vh;
      background: black;
      min-height: 86vh;
      max-width: 100%;
      left: 0;
      z-index: 5;
      bottom: 0;
      display: none;
    }
    li {
      &:nth-last-child(-n + 2) {
        @include respond(ipadMobile) {
          margin-top: rem(25) !important;
        }
      }
      &:nth-child(even) {
        .nickfaldo-link__edition {
          color: $color-primary1;
        }
      }
      &:not(:nth-last-child(-n + 2)) {
        .nickfaldo-link__edition {
          top: 0;
          transform: translateY(rem(-17));
        }
      }
      &:nth-last-child(-n + 2) {
        .nickfaldo-link__edition {
          bottom: 0;
          transform: translateY(rem(17));
        }
      }
      @include respond(ipadMobile) {
        margin: 0 !important;
        background: linear-gradient(
          107.9deg,
          #0d0d0d 21.51%,
          #131313 37.67%,
          #282828 56.82%,
          #141414 75.28%,
          #0e0e0e 91.83%
        );
        border: 1px solid #f1c675;
        border-radius: 8px;
        width: rem(100);
        height: rem(100);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    a {
      color: $white !important;
      font-weight: 700;
      display: inline-block;
      text-transform: uppercase;
      font-family: "libre_baskervillebold";
      position: relative;
      @include respond(desktop) {
        font-size: rem(18);
      }
      @include respond(ipadMobile) {
        font-size: rem(50);
      }
      &.active {
        color: $color-primary1 !important;
        &:hover {
          text-decoration: none;
        }
        &::after {
          content: "";
          position: absolute;
          width: rem(31);
          height: rem(18);
          @include bgContain();
          background-image: url("/svg/bb-active-nikfaldo.svg");
          @include respond(desktop) {
            right: 0;
            transform: translateX(110%);
          }
          @include respond(ipadMobile) {
            left: 50%;
            transform: translateX(-54%) translateY(rem(17));
            bottom: 0;
          }
        }
      }
    }

    li {
      a {
        @include respond(desktop) {
          margin: 0 rem(37);
        }
      }
      &::before,
      &::after {
        display: none !important;
      }
      &:first-child {
        a {
          margin-left: 0;
        }
      }
      &:last-child {
        a {
          margin-right: 0;
        }
      }
    }
  }
}
.yellow-menu-close {
  display: none;
}
.nikfaldo-menu-open {
  .yellow-menu {
    display: none;
  }
  .yellow-menu-close {
    display: block;
  }
  @include respond(ipadMobile) {
    overflow: hidden;
  }
  .nickfaldo-link {
    @include respond(ipadMobile) {
      display: block;
    }
  }
}

.readmore-modal__video {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: 1px solid $color-primary;
  border-radius: rem(10);

  iframe {
    position: absolute;
    width: calc(100% - 5rem);
    height: calc(100% - 5rem);
    left: 2.5rem;
    top: 2.5rem;
    border-radius: 0.625rem;
    border: none;
  }
}
