.exploreRangeSection {
  background-image: url("/images/black-bull-whiskey-bg.jpg");
  @include bgCover();
  @include respond(desktop) {
    padding: 3rem rem(35) !important;
  }
}
.discover-video-section {
  @include respond(desktop) {
    padding: 5rem 0 !important;
  }
  @include respond(mobile) {
    padding: 3.9285714286rem 0;
  }
}
.nikfaldo-details {
  padding-bottom: rem(40);
  @include respond(desktopIpad) {
    // background-image: url("/images/nikfaldo-details-bg.png");
    background: radial-gradient(
      50% 154.13% at 50% 50%,
      #183047 0%,
      #100101 100%
    );
    padding-top: 6.514286rem;
  }
  @include respond(ipadMobile) {
    background-color: $black;
    // padding-top: 10rem;
    padding-bottom: rem(40);
  }
}

.nikfaldo-section-fiftyfive-years, .nikfaldo-section-fifty-years{
  .add-to-card-nikfaldo__btn{
    @include respond(mobile){
      display: block !important;
    }
  }
  .add-to-card-nikfaldo__image{
    @include respond(mobile){
      background: none;
      margin: 0;
    }
  }
}
.add-to-card-nikfaldo {
  @include respond(ipad) {
    padding-top: 5.514286rem;
  }
  &__row {
    background-image: url("/images/nikfaldo-details-bg.png");
    @include bgCover();
    padding-bottom: 2.7rem;
    @include respond(desktopIpad) {
      padding-top: 2.7rem;
    }
    @include respond(mobile) {
      padding-top: 9.7rem;
    }
  }
  &__image {
    @include respond(mobile) {
      background-image: url("/images/nikfaldo-details-bg.png");
      @include bgCover();
      padding: rem(32);
      @include flex-center();
      margin-top: rem(20);
    }
    img {
      @include respond(desktop) {
        width: rem(348);
      }
      @include respond(ipadMobile) {
        width: rem(206);
      }
    }
  }
  &__logo {
    @include respond(ipad) {
      text-align: center;
    }
    @include respond(mobile) {
      display: none !important;
    }
    img {
      @include respond(desktop) {
        width: rem(318);
      }
      @include respond(ipadMobile) {
        width: rem(176);
      }
    }
  }
  &__card {
    position: relative;
    height: 100%;

    @include flex-center();
    flex-direction: column;
    @include respond(desktop) {
      padding-top: rem(40);
    }
    @include respond(ipadMobile) {
      padding-top: rem(25);
    }
    .nikfaldo-btn {
      @include respond(desktop) {
        position: absolute;
        right: 0;
        bottom: 6.5rem;
        transform: translateX(2.785714rem);
      }
      @include respond(ipadMobile) {
        margin-top: rem(20);
      }
    }
  }
  &__btn {
    img {
      width: rem(177);
    }
    @include respond(desktop) {
      margin-top: rem(20);
    }
    @include respond(ipadMobile) {
      display: none;
    }
  }
  &__percent {
    h5 {
      color: $color-primary1;
      font-family: "benchninebold";
      text-transform: uppercase;
      margin-bottom: 0;
      letter-spacing: 0.23em;
      @include respond(desktop) {
        font-size: rem(22);
        margin-top: rem(20);
      }
      @include respond(ipadMobile) {
        font-size: rem(18);
        margin-top: rem(10);
      }
    }
  }
  &__age {
    @include flex-y-center();
    @include respond(desktopIpad) {
      margin-top: rem(20);
    }

    span {
      color: $color-primary1;
      @include respond(ipadMobile) {
        letter-spacing: 0.23em;
      }
      &:nth-child(2) {
        @include respond(desktop) {
          font-family: "libre_baskervillebold";
          font-size: rem(45);
          margin: 0 rem(10);
        }
        @include respond(ipadMobile) {
          font-size: rem(16);
          font-family: "benchninebold";
          margin: 0 rem(5);
        }
      }
      &:not(:nth-child(2)) {
        font-family: "benchninebold";
        text-transform: uppercase;
        @include respond(desktop) {
          font-size: rem(18);
        }
        @include respond(ipadMobile) {
          font-size: rem(16);
        }
      }
    }
  }
}

.nikfaldo-btn {
  background-color: $color-primary1;
  display: inline-block;
  font-family: "libre_baskervillebold";
  color: $black;
  letter-spacing: 0.05em;
  border-radius: 4px;
  &:hover {
    color: $black;
    text-decoration: none;
  }
  @include respond(desktop) {
    padding: rem(15) rem(25);
    font-size: rem(16);
  }
  @include respond(ipadMobile) {
    padding: rem(15) rem(25);
    font-size: rem(16);
  }
}

.nikfaldo-main-section {
  overflow: inherit !important;
}
.nikfaldo-body {
  .header-section__top {
    background-color: $black !important;
  }
  background-color: $black !important;
}

.pt-lg-95-sm-55 {
  @include respond(desktopIpad) {
    padding: 5.5rem 0;
  }
  @include respond(mobile) {
    padding: rem(50) 0;
  }
}
.bg-darkg {
  background-color: #1f1f1f;
}
.product-gallery-section {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    background-color: #1f1f1f;
    bottom: 0;
    height: 40%;
    left: 0;
    z-index: -1;
    display: none;
  }
  @include respond(desktop) {
    // padding-top: rem(20) !important;
  }
}
.scroll-stories-section {
  @include respond(desktop) {
    padding: rem(95) 0 rem(95) 0;
  }
  @include respond(ipadMobile) {
    padding: rem(55) 0;
  }
  &.first {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 120%;
      background: radial-gradient(
        50% 154.13% at 50% 50%,
        #183047 0%,
        #100101 100%
      );
      @include bgCover();
      z-index: -1;
    }
    .scroll-stories-section__body {
      @include respond(ipadMobile) {
        margin-top: rem(10);
      }
    }
  }
  &__row {
    @include respond(ipadMobile) {
      padding: rem(20) 0;
      background-image: url("/images/nikfaldo-details-bg.png");
      @include bgCover();
      margin: 0 rem(20);
    }
  }

  &.second {
    .scroll-stories-section__image {
      &::after {
        top: 25%;
      }
    }
  }
  &.third {
    .scroll-stories-section__image {
      &::after {
        top: 50%;
      }
    }
  }
  &.four {
    .scroll-stories-section__image {
      &::after {
        top: 75%;
      }
    }
  }
  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include respond(desktop) {
      padding-left: 5rem;
      height: 100%;
    }
    @include respond(ipadMobile) {
      margin-top: rem(25);
      text-align: center;
    }
  }
  &__image {
    position: relative;

    img {
      @include respond(desktop) {
        width: rem(630);
      }
    }
    &::after {
      @include respond(desktop) {
        content: "";
        position: absolute;
        width: 2px;
        background-color: $color-primary1;
        right: 0;
        top: 0;
        height: calc(100% / 4);
        transform: translateX(3.8rem);
        z-index: 1;
      }
    }
  }
  &__body {
    @include respond(desktop) {
      margin-top: rem(30);
    }

    .nikfaldo-desc {
      &:not(:first-child) {
        margin-top: rem(18);
      }
    }
  }
}
.nikfaldo-desc {
  font-family: "Bellota Text", cursive;
  font-weight: 400;
  margin-bottom: 0;
  color: $text-85;
  @include respond(desktop) {
    line-height: 1.5;
  }
  @include respond(ipadMobile) {
    line-height: 1.4;
  }
  &:not(.lg) {
    @include respond(desktop) {
      font-size: rem(16);
    }
    @include respond(ipadMobile) {
      font-size: rem(14);
    }
  }
  &.lg {
    @include respond(desktop) {
      font-size: rem(28);
    }
    @include respond(ipadMobile) {
      font-size: rem(20);
    }
  }
}
.nikfaldo-section-heading {
  font-family: "Alfa Slab One";
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: rem(24);
  background: linear-gradient(
    174.88deg,
    #cdcdcd -12.31%,
    #e3e3e3 29.39%,
    #ffffff 49.89%,
    #919191 95.89%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.product-description-card {
  &__years,
  &__details {
    @include bgCover();
  }
  .row {
    [class*="col-"] {
      &:first-child {
        @include respond(desktop) {
          padding-right: 0;
        }
      }
      &:last-child {
        @include respond(desktop) {
          padding-left: 0;
        }
      }
    }
  }
  &__image2 {
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 29.3125rem;
    }
  }
  &__image {
    @include respond(desktop) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: rem(227);
      z-index: 1;
    }
  }
  &__details {
    background-image: url("/images/description-bg.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include respond(desktopIpad) {
      height: 100%;
    }
    text-align: center;
    @include respond(desktop) {
      padding: 6.642857rem 5rem;
    }
    @include respond(ipadMobile) {
      padding: rem(20);
    }
    .nikfaldo-desc {
      max-width: rem(232);
      text-align: center;
      margin: 0 1rem 0 auto;
    }
    &--image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include respond(desktop){
        margin: 2rem 5rem 0 auto;
      }
      .cross {
        width: rem(15);
        margin: rem(15) 0;
      }
      margin-top: rem(30);
      .bull {
        width: rem(90);
      }
      .nikfaldo-sign {
        width: rem(95);
      }
      p {
        color: $color-primary1;
      }
    }
  }
  &__years {
    background-image: url("/images/nikfaldo-details-bg.png");
    @include respond(desktop) {
      height: 100%;
      padding: rem(172) 13.3rem rem(172) 8.3rem;
    }
    @include respond(ipadMobile) {
      padding: rem(20);
    }
    &--body {
      @include respond(desktop) {
        margin-top: rem(30);
      }
      @include respond(ipadMobile) {
        font-size: rem(30);
      }
      .buy-now-sm-btn {
        margin-top: rem(15);
      }
    }
  }
}
.buy-now-sm-btn {
  display: inline-block;
  font-family: "benchninebold";
  padding: 0.4857142857rem 1.5714285714rem 0.1857142857rem 1.5714285714rem;
  text-transform: uppercase;
  font-size: rem(16);
  background-color: #a08e58;
  color: $white;
  &:hover {
    text-decoration: none;
    color: $white;
  }
}
.nikfaldo-desc-2 {
  font-family: "benchninebold";
  color: $text-85;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 1.4;
  span {
    font-family: "Bellota Text" !important;
    text-transform: initial;
  }
  margin-top: rem(15);
  // &:not(:first-child) {
  // }
  &:not(.lg) {
    @include respond(desktop) {
      font-size: rem(20);
    }
    @include respond(ipadMobile) {
      font-size: rem(16);
    }
  }
  &.lg {
    background: linear-gradient(
      174.88deg,
      #cdcdcd -12.31%,
      #e3e3e3 29.39%,
      #ffffff 49.89%,
      #919191 95.89%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    opacity: 0.3;
    font-family: "Alfa Slab One";
    @include respond(desktop) {
      font-size: rem(45);
    }
    @include respond(ipadMobile) {
      font-size: rem(30);
    }
  }
}

.explore-range-card {
  @include flex-x-center();
  align-items: center;

  &__image {
    @include flex-center();
    margin: 0 rem(5);

    @include respond(ipad) {
      width: 20.428571rem !important;
    }
    @include respond(ipadMobile) {
      width: 11.428571rem;
      height: 100%;
      padding: 1.4rem 1.14rem;
    }
    padding: 1.4rem 2.14rem;

    &.active {
      background-image: url("/images/explore-range-bg.png");
      @include bgCover();
      border: 1px solid $color-primary1;
      @include respond(desktop) {
        width: rem(311);
        height: rem(550);
      }
      @include flex-column();
    }
    &:not(.active) {
      border: 3px solid #161616;
      @include respond(desktop) {
        width: rem(188);
        height: 21rem;
      }
      img {
        width: 7.571429rem;
        @include respond(desktopIpad) {
          filter: grayscale(1);
        }
      }
    }
    p {
      text-transform: uppercase;
      color: $color-primary1;
      font-family: "benchninelight";
      font-size: rem(24);
      margin-top: rem(20);
      margin-bottom: 0;
    }
  }
}

.legend-tales-section {
  background-image: url("/images/nikfaldo-details-bg.png");
  @include bgCover();
  @include respond(desktop) {
    padding: 3rem rem(100);
  }
  @include respond(ipadMobile) {
    padding: rem(30) rem(10);
  }
  .nikfaldo-section-heading {
    color: $text-c3;
  }
  .row {
    @include respond(ipadMobile) {
      margin: 0;
    }
    [class*="col-"] {
      padding: 0 rem(5);
    }
  }
}
.scroll-stories-card {
  width: 100%;
  border: 1px solid $color-primary1;
  margin-bottom: rem(10);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  @include respond(desktop) {
    height: rem(164);
  }
  @include respond(ipadMobile) {
    height: rem(100);
  }
}
.falvour-wheels-section {
  background-image: url("/images/nikfaldo-details-bg.png");
  @include bgCover();
  @include respond(desktop) {
    padding: rem(115) rem(30);
  }
  @include respond(ipadMobile) {
    padding: rem(20) rem(10);
    // margin: 0 rem(20) !important;
    position: relative;
  }
  @include respond(mobile) {
    margin-top: 1.0714285714rem;
  }
  .row {
    .col-md-8 {
      &:not(.d-block) {
        @include respond(ipad) {
          @include flex-y-center();
        }
      }
    }
  }
  &::after {
    @include respond(mobile) {
      content: "";
      position: absolute;
      height: 50%;
      width: 100%;
      left: 0;
      bottom: 0;

      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 77.77%);
    }
  }
}

.falvour-wheels-card {
  text-align: center;
  @include respond(desktop) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @include respond(ipadMobile) {
    margin-top: rem(120);
  }

  &__image {
    position: relative;
    @include respond(desktop) {
      margin-top: rem(50);
    }
    @include respond(ipadMobile) {
      margin-top: rem(30);
    }
    img {
      &:first-child {
        width: rem(230);
        opacity: 0.1;
      }
      &:last-child {
        width: rem(177);
        position: absolute;
        top: 58%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
    }
  }
}

.discover-video-card {
  border: 1px solid $color-primary1;
  @include flex-center();
  cursor: pointer;
  background-image: url("/images/nikfaldo-details-bg.png");
  @include bgCover();
  @include respond(desktop) {
    padding: rem(30);
    height: rem(450);
  }
  @include respond(ipad) {
    height: 29.285714rem !important;
  }
  @include respond(ipadMobile) {
    padding: rem(20);
    height: rem(200);
    width: 100%;
  }
  &__image {
    @include respond(mobile) {
      display: flex;
    }
    img {
      &:nth-child(1) {
        // width: rem(229);
        // transform: translateX(rem(25));
      }
      &:nth-child(2) {
        // width: rem(229);
        z-index: 1;
        position: relative;
        @include respond(mobile) {
          width: 2rem;
        }
      }
      &:nth-child(3) {
        width: rem(172);
        transform: translateX(rem(-25));
      }
    }
    position: relative;
    .play {
      position: absolute !important;
      top: 50%;
      left: 45%;
      z-index: 2;
      transform: translate(-50%, -50%);
    }
  }
}

.video-modal {
  .video-modal-btn {
    position: absolute;
    top: rem(15);
    right: rem(15);
    cursor: pointer;
    img {
      width: rem(20);
    }
  }
  .readmore-modal-body {
    @include flex-y-center();
  }
  .modal-content {
    min-height: 75vh;
  }
  .modal-dialog {
    max-width: 80%;
    min-height: 100vh;
    margin: 0 auto;
  }
}
.discover-video-section {
  @include respond(ipad) {
    padding: rem(40) 0;
  }
}
#pinScrollSection,
#pinBottleScrollSection {
  @include respond(desktop) {
    min-height: 100vh;
    @include flex-center();
  }
}
.scroll-stories-section__imagewrapper {
  position: relative;
  @include respond(desktop) {
    height: rem(388);
    width: 100%;
  }
  .scroll-stories-section__image {
    @include respond(desktop) {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.scroll-stories-section__cardwrapper {
  width: 100%;
  height: 100%;
  position: relative;

  .scroll-stories-section__card {
    @include respond(desktop) {
      background-color: $black;
    }
    &:not(:first-child) {
      position: absolute;
      left: 0;
      top: 0;
    }
    &:nth-child(1) {
      z-index: 1;
    }
    &:nth-child(2) {
      z-index: 2;
    }
    &:nth-child(3) {
      z-index: 3;
    }
    &:nth-child(4) {
      z-index: 4;
    }
  }
}

.pinScrollImageWrapper {
  position: relative;
  .pinScrollImage {
    position: absolute;
    left: 0;
    top: 0;
    &:nth-child(1) {
      z-index: 1;
    }
    &:nth-child(2) {
      z-index: 2;
      .scroll-stories-section__image {
        &::after {
          top: 25%;
        }
      }
    }
    &:nth-child(3) {
      z-index: 3;
      .scroll-stories-section__image {
        &::after {
          top: 50%;
        }
      }
    }
    &:nth-child(4) {
      z-index: 4;
      .scroll-stories-section__image {
        &::after {
          top: 75%;
        }
      }
    }
  }
}

.scroll-stories-section__card {
  @include respond(desktop) {
    background-color: #1f1f1f !important;
  }
}

.scroll-bottle-section {
  .row {
    position: relative;
  }
  .add-to-card-nikfaldo__image {
    img {
      width: rem(472);
    }
  }
  &__border {
    position: absolute;
    width: 100%;
    height: rem(4);
    background-color: #432c20;
    bottom: rem(50);
  }
}
.scroll-bottle-description-card {
  height: 100%;
  @include flex-center();
  flex-direction: column;
  &__image {
    img {
      width: rem(330);
    }
  }
  h3,
  h4 {
    font-family: "libre_baskervillebold";
    text-transform: uppercase;
    color: $color-primary1;
  }
  h3 {
    @include respond(desktop) {
      font-size: rem(70);
      margin: 0 rem(25);
    }
    @include respond(ipadMobile) {
      font-size: rem(55);
      margin: 0 rem(5);
    }
  }
  h4 {
    letter-spacing: 0.265em;
    @include respond(desktop) {
      font-size: rem(27);
      margin-bottom: 0;
    }
    @include respond(ipadMobile) {
      font-size: rem(20);
    }
  }
}

.bottlebgLogo {
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18.571429rem;
}

.scroll-bottle-section-inner {
  height: 100%;
  position: relative;
  @include flex-center();
  z-index: 1;
  .product-description-card {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    &:not(.scrollBottleNotes) {
      .product-description-card__details,
      .product-description-card__years {
        background-image: none;
      }
    }
    &.tasting-notes {
      padding: rem(35) 0;
    }
  }
}

.scrollBottleAgeYear {
  @include respond(desktop) {
    z-index: 1;
    background-image: url("/images/age-bg-image.png");
    @include bgCover();
  }
}

.bg-black {
  background-color: $black;
}

.add-to-card-nikfaldo__image.scrollBottleImage {
  position: relative;
  z-index: 2;
}

.scroll-bottle-section__row {
  [class*="col-"] {
    &:last-child {
      z-index: -1;
    }
  }
}

.explore-range-card {
  p {
    @include respond(desktopIpad) {
      display: none;
    }
  }
  &__image {
    &.active-1 {
      &:not(.noActive) {
        ~ div {
          &:nth-child(2) {
            order: 1 !important;
          }
          &:last-child {
            order: 3 !important;
          }
        }
      }
    }
    &.active {
      order: 2;
      p {
        display: block;
      }
    }
    &.noActive {
      &:first-child {
        order: 1;
      }
      &:last-child,
      &:nth-child(2) {
        order: 3;
      }
    }
  }
}

.productGallery {
  .slick-arrow {
    background-image: url("/svg/slider-arrow-primary.svg") !important;
    background-size: contain !important;
    border: none !important;
    @include respond(desktopIpad) {
      width: 1.6428571429rem !important;
      height: 5.6428571429rem !important;
    }
    @include respond(mobile) {
      width: 1rem !important;
      height: 4.5rem !important;
    }
    &.slick-next {
      @include respond(desktopIpad) {
        transform: translate(rem(-75), -50%) rotate(0deg) !important;
      }
      @include respond(mobile) {
        transform: translate(4px, -50%) rotate(0deg) !important;
      }
    }
    &.slick-prev {
      
      @include respond(desktopIpad) {
        transform: translate(rem(65), -50%) rotate(180deg) !important;
      }
      @include respond(mobile) {
        transform: translate(-4px, -50%) rotate(180deg) !important;
      }
    }
    @include respond(desktop) {
      width: rem(23);
      height: rem(79);
    }
  }
  &__image {
    @include respond(desktop) {
      width: rem(557);
    }
    img {
      margin: 0 auto;
      @include respond(desktop) {
        width: rem(557);
      }
    }
  }
  &__smallimage {
    img {
      @include respond(desktop) {
        width: rem(37);
      }
    }
  }
  .slick-slider {
    &:last-child {
      .slick-slide {
        margin: 0 rem(4);
        @include respond(desktopIpad) {
          width: rem(37) !important;
        }
        @include respond(mobile) {
          width: rem(30) !important;
        }
        &.slick-current {
          .productGallery__smallimage {
            border: 1px solid $color-primary1;
          }
        }
      }
      .slick-track {
        @include flex-x-center();
        margin-top: rem(10);
      }
    }
  }
}
.explore-bottle-1,
.explore-bottle-2 {
  &:not(.active) {
    img {
      @include respond(desktop) {
        width: 5.7rem !important;
      }
    }
  }
}
.explore-bottle-3 {
  &:not(.active) {
    img {
      @include respond(desktop) {
        width: 5.8125rem !important;
      }
    }
  }
}

// .explore-bottle-1{
//   &.active {
//     img {
//       @include respond(desktop) {
//         width: 12rem !important;
//       }
//     }
//   }
// }

.explore-range-card {
  &.desktop {
    @include respond(mobile) {
      display: none !important;
    }
  }
  &.mobile {
    @include respond(desktopIpad) {
      display: none !important;
    }
    .explore-range-card__image {
      @include respond(mobile) {
        flex-direction: column;
        background-image: url("/images/explore-range-bg.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border: 1px solid $color-primary1;
      }
    }
    .row {
      flex-wrap: nowrap;
      overflow-x: auto;
      padding-bottom: rem(20);

      /* width */
      &::-webkit-scrollbar {
        height: rem(6);
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $color-primary1;
      }

      .col {
        &:not(:last-child) {
          .explore-range-card__image {
            border-right: none;
          }
        }
        &:first-child {
          .explore-range-card__image {
            margin-left: rem(20);
            margin-right: 0;
          }
        }
        &:nth-child(2) {
          .explore-range-card__image {
            margin: 0;
          }
        }
        &:last-child {
          .explore-range-card__image {
            margin-right: rem(20);
            margin-left: 0;
          }
        }
      }
    }
  }
}

.tasting-notes {
  @include respond(ipadMobile) {
    padding: rem(25) rem(30);
    // margin: rem(15) 1.4285714286rem !important;
    position: relative;
    background-image: url(/images/nikfaldo-details-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .product-description-card {
    .nikfaldo-section-heading {
      @include respond(mobile) {
        text-align: center;
      }
    }
    &__image2 {
      @include respond(mobile) {
        margin-top: rem(20);
      }
      img {
        @include respond(mobile) {
          width: rem(180);
        }
      }
    }
    &__years--body {
      @include respond(ipadMobile) {
        margin-top: rem(20);
      }
      .nikfaldo-desc-2 {
        @include respond(ipadMobile) {
          font-size: rem(20);
        }
      }
    }
    &__details {
      @include respond(ipadMobile) {
        background-image: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}

.product-description-card {
  &:not(:first-child) {
    @include respond(desktopIpad) {
      margin-top: rem(25);
    }
  }
}
.mobile-product {
  @include respond(ipadMobile) {
    // padding: rem(25) rem(30);
    // margin: 0 1.4285714286rem !important;
    position: relative;
  }
  .product-description-card__years {
    @include respond(ipadMobile) {
      text-align: center;
    }
  }

  .product-description-card__details {
    @include respond(ipad) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @include respond(ipadMobile) {
      padding: rem(25) !important;
      // margin-top: rem(15);
      margin-bottom: rem(15);
    }
    @include respond(mobile) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  &__image {
    .product-description-card__details--image {
      @include respond(ipadMobile) {
        margin: 0 0 rem(20) 0;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        display: none;
      }
      img {
        @include respond(ipadMobile) {
          margin: 0 !important;
        }
        &:nth-child(2) {
          @include respond(ipadMobile) {
            margin: 0 rem(15px) !important;
          }
        }
      }
    }
    @include respond(desktop) {
      display: none;
    }
    img {
      @include respond(ipadMobile) {
        width: rem(260);
        margin-bottom: rem(20);
      }
    }
  }
}

.mobile-scroll-slider {
  // margin: 0 rem(20);
  @include respond(ipad) {
    padding: rem(30) rem(20);
  }
  @include respond(mobile) {
    padding: rem(20);
  }
  .row {
    @include respond(ipad) {
      align-items: center;
    }
  }
  background-image: url(/images/nikfaldo-details-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .scroll-stories-section__card {
    .nikfaldo-section-heading {
      margin-bottom: rem(10);
    }
  }
}
.scroll-mobile-slider {
  padding-bottom: 5.14rem;
  .slick-arrow {
    display: none !important;
  }
  .slick-slide {
    > div {
      &:first-child {
        margin: 0 rem(7);
      }
    }
  }
}

.eighteen-years,
.thirty-years {
  @include respond(desktop) {
    width: 9rem !important;
  }
  @include respond(ipadMobile) {
    width: 7.714286rem !important;
  }
}
.scroll-stories-section {
  @include respond(desktop) {
    // overflow: hidden;
  }
}
.product-description-card__details--image {
  .eighteen-years,
  .thirty-years {
    @include respond(ipadMobile) {
      width: 9.714286rem !important;
    }
  }
}
.scroll-bottle-section-inner {
  @include respond(desktop) {
    // max-width: 80vw;
    width: 85%;
  }

  .eighteen-years,
  .thirty-years {
    @include respond(desktop) {
      width: 14.857143rem !important;
    }
    @include respond(ipadMobile) {
      width: 9.714286rem !important;
    }
  }
}

.chartjs-render-monitor {
  @include respond(desktop) {
    height: 301px;
    transform: scale(1.3);
  }
}

.noklo-faldo-video-wrapper {
  .speaker {
    top: 50%;
    @include respond(desktop) {
      transform: translateY(-50%) scale(0.4);
    }
    @include respond(ipadMobile) {
      transform: translateY(-50%) scale(0.3);
    }
  }
}

@mixin bgBannerShadow() {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 77.77%);
    border-left: 1px solid rgba(12, 12, 12, 0.1);
    border-top: 1px solid rgba(12, 12, 12, 0.1);
    border-bottom: 1px solid rgba(12, 12, 12, 0.1);
  }
}
@mixin bgBannerBull($image) {
  position: relative;
  background-image: url($image);
  background-repeat: no-repeat;

  @include respond(desktop) {
    background-position: 13.9rem rem(30);
  }
  @include respond(ipadMobile) {
    background-position: 7.9rem 0.142857rem;
  }
  @include respond(desktop) {
    background-size: contain;
    padding-left: 7.7142857143rem;
    text-align: left;
  }
  @include respond(ipadMobile) {
    background-size: 67%;
    padding-left: 3.3rem;
    justify-content: flex-start;
  }
}
.nikfaldo-section {
  &-fiftyfive-years {
    .add-to-card-nikfaldo {
      // background-image: url("/images/golden-glitter-bg.png");
    }

    .scrollBottleAgeYear {
      .scroll-main-description-bottle {
        img {
          width: 20rem;
        }
      }
    }

    .add-to-card-nikfaldo__card .nikfaldo-btn {
      background-color: $color-danger1;
      color: $white;
    }
    .white-svg-nick {
      display: none;
    }
  }
  &-fifty-years {
    // @include bgBannerShadow();
    .add-to-card-nikfaldo__percent h5,
    .add-to-card-nikfaldo__age span {
      color: $text-b0;
    }
    .white-svg-nick {
      display: none;
    }
  }
  &-thirty-years {
    .add-to-card-nikfaldo {
      @include bgBannerShadow();
    }
    .add-to-card-nikfaldo__image {
      @include bgBannerBull("/images/half-bull-yellow.png");
    }
    .yellow-svg-nick {
      display: none;
    }
    .add-to-card-nikfaldo__card .nikfaldo-btn {
      background-color: $color-primary2;
      color: $white;
    }
    .add-to-card-nikfaldo__percent h5,
    .add-to-card-nikfaldo__age span,
    .nikfaldo-section-heading {
      color: $color-primary2;
    }
  }
  &-eighteen-years {
    .yellow-svg-nick {
      display: none;
    }
    .add-to-card-nikfaldo__card .nikfaldo-btn {
      background: linear-gradient(
        90deg,
        #969696 34.16%,
        #f3f2f2 63.34%,
        #9e9d9d 92.01%
      );
    }
    .add-to-card-nikfaldo {
      @include bgBannerShadow();
    }
    .add-to-card-nikfaldo__image {
      @include bgBannerBull("/images/half-bull-white.png");
      padding-left: rem(108);
    }
    .add-to-card-nikfaldo__percent h5,
    .add-to-card-nikfaldo__age span,
    .nikfaldo-section-heading,
    .nikfaldo-desc-2,
    .nikfaldo-desc {
      color: #dfddd7;
    }
  }
}

.product-description-wrapper {
  .product-description-card {
    &:not(:first-child) {
      // margin-top: rem(35);
    }
  }
}
.scroll-main-description-bottle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  @include respond(ipadMobile) {
    display: none;
  }
}
.new-bottle-section {
  .product-description-card__years {
    @include respond(desktopIpad) {
      // padding: 12.2857142857rem 8rem;
    }
  }
}

.wheels-edition-section {
  .wheels-edition-section__row {
    img {
      @include respond(desktop) {
        width: rem(546);
      }
    }
  }
}

.new-market-new-challenges-section {
  .heading-bright-1 {
    @include respond(desktop) {
      max-width: 91%;
    }
  }
}
.nikfaldo-section-fiftyfive-years {
  .nikfaldo-section-heading,
  .nikfaldo-desc-2.lg {
    background: linear-gradient(
      90deg,
      #fdce7b 0%,
      #f9c467 45.83%,
      #c9a341 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .add-to-card-nikfaldo__row {
    background-image: url("/images/golden-glitter-bg.png");
  }
}

.nikfaldo-section-fifty-years {
  .falvour-wheels-section {
    .nikfaldo-desc-2.lg {
      background: linear-gradient(
        101.54deg,
        #969696 28.76%,
        #d3cfcf 43.37%,
        #ffffff 53.84%,
        #9f9e9e 67.92%,
        #9c9c9c 94.15%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  .product-gallery-section::after {
    display: none;
  }
}
