.section-heading-2 {
    font-family: $font-alfa;
    color: $white;
    text-transform: uppercase;
    span {
        color: $color-primary;
    }
    @include respond(desktop) {
        font-size: rem(42);
        line-height: rem(52);
        margin-bottom: rem(75) !important;
    }
    @include respond(ipadMobile) {
        font-size: rem(28);
        line-height: rem(36);
        margin-bottom: rem(55) !important;
    }
    &.sm {
        @include respond(desktop) {
            font-size: rem(32) !important;
            line-height: rem(42) !important;
        }
        @include respond(ipadMobile) {
            line-height: rem(36) !important;
        }
    }
}

.bartender-section {
    .section-heading-2 {
        margin: 0 auto;

        @include respond(mobile) {
            max-width: rem(199);
        }

        span {
            display: block;
        }
    }
}

.product-filter-section {
    .section-heading-2 {
        max-width: 100% !important;
    }
}
.section-sub-heading-2 {
    color: $color-primary;
    font-weight: 300;
    font-size: rem(22);
    line-height: rem(30);
    @include respond(ipadMobile) {
        font-size: rem(18);
    }
}

.section-heading-3 {
    color: $color-DE9;
    font-size: rem(18);
    line-height: rem(24);
    font-family: $font-alfa;
    span {
        color: $color-primary;
    }
    @include respond(ipadMobile) {
        font-size: rem(18);
    }
}

.product-desc-section-heading {
    text-align: left;

    @include respond(desktop) {
        position: absolute;
        left: 15px;
        top: -25px;
    }
    @include respond(ipadMobile) {
        margin-bottom: rem(10);
    }
    p {
        margin-bottom: 0;
        color: $color-light;
    }
}

.section-heading-4 {
    font-family: $font-alfa;
    font-weight: 400;
    line-height: 1;
    color: $white;
    text-transform: uppercase;

    @include respond(desktop) {
        margin-bottom: rem(14);
        font-size: rem(22);
    }
    @include respond(ipadMobile) {
        font-size: rem(18);
        margin-bottom: rem(40) !important;
    }
    span {
        color: $color-primary;
    }
}

.tester-masonry-heading {
    .section-heading-4 {
        @include respond(desktop) {
            margin-bottom: rem(35);
        }
        @include respond(ipadMobile) {
            margin-bottom: rem(20);
        }
    }
    text-align: left;
    @include flex-between();

    a {
        @include textLink();
        @include respond(ipadMobile) {
            font-size: rem(12) !important;
        }
    }
}
.bordered-heading {
    position: relative;
    @include flex-y-center();
    justify-content: space-between;

    @include respond(desktop) {
        margin-bottom: rem(60);
    }
    @include respond(ipadMobile) {
        margin-bottom: rem(40);
    }

    .section-heading-4 {
        margin-bottom: 0;
        white-space: nowrap;

        @include respond(desktop) {
            margin-right: rem(70);
        }
        @include respond(ipadMobile) {
            font-size: rem(14);
            margin-right: rem(25);
            margin-bottom: 0 !important;
        }
    }
    span {
        display: inline-block;
        max-width: 100%;
        height: rem(2);
        width: 100%;

        background-color: $color-primary;
    }
}

.indian-review-section-heading {
    &.top-nav {
        transform: scale(1);
    }
    align-items: flex-start !important;

    @include respond(mobile) {
        flex-wrap: wrap;
        margin-bottom: rem(35);
    }
    .top-nav__search {
        @include respond(mobile) {
            max-width: rem(279);
        }
    }
    .section-heading-4 {
        @include respond(mobile) {
            width: 100%;
        }
    }
    .react-select {
        width: rem(279);
    }
}

.your-cart-wrapper__heading {
    @include flex-y-center();
    @include respond(desktopIpad) {
        margin-bottom: rem(50);
    }
    @include respond(mobile) {
        margin-bottom: rem(32);
        @include flex-between();
    }
    .section-heading-2 {
        margin-bottom: 0 !important;

        @include respond(desktopIpad) {
            margin-right: rem(40);
            padding-left: rem(15);
        }
        @include respond(mobile) {
            font-size: rem(18) !important;
        }
    }
}
.text-primary{
    color: $color-primary !important;
}

.blog-heading {
    font-family: 'Alfa Slab One';
    font-size: rem(32);
    font-weight: 400;
    line-height: rem(42);
    color: $color-grey;
    @include respond(mobile) {
        font-size: rem(28);
        line-height: rem(36);
        text-align: center;
        span {
            display: block;
        }
    }
    span {
        color: $color-primary;
    }
}

.blog-date {
    font-family: 'Lato';
    font-size: rem(18);
    font-weight: 300;
    line-height: normal;
    color: $color-grey;
}

p.blog-date-duration {
    font-family: 'Lato';
    font-size: rem(12);
    font-weight: 400;
    line-height: normal;
    margin-bottom: rem(60);
    @include respond(mobile) {
        text-align: center;
        margin-bottom: rem(30);
    }
}

.blog-sub-heading {
    font-family: 'Lato';
    font-size: rem(22);
    font-weight: 300;
    line-height: rem(22);
    color: $color-primary;
    margin-bottom: rem(60);
    @include respond(mobile) {
        text-align: center;
        margin-bottom: rem(30);
    }
}

.info-tag {
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: $color-grey;
}

.blog-card-heading {
    font-family: 'Alfa Slab One';
    font-size: rem(18);
    font-weight: 400;
    line-height: rem(28);
    color: $color-grey;
    span {
        color: $color-primary;
    }
}

