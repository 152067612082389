html {
  scroll-behavior: smooth;
  font-size: 16px !important;
  @include respond(smallmobile) {
    font-size: 14px !important;
  }

  @media only screen and (min-width: 1280px) and (max-width: 1365px) {
    font-size: 16px !important;
  }
  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    font-size: 18px !important;
  }
  @media only screen and (min-width: 1441px) and (max-width: 1680px) {
    font-size: 20px !important;
  }
  @media only screen and (min-width: 1681px) and (max-width: 1920px) {
    font-size: 22px !important;
  }
  @media only screen and (min-width: 1921px) and (max-width: 2559px) {
    font-size: 24px !important;
  }
  @media only screen and (min-width: 2560px) {
    font-size: 26px !important;
  }
}
@media (min-width: 576px){
  .container-sm, .container{
    // max-width: 100% !important;
  }
}
body {
  font-size: 1rem !important;
  // background-color: $black !important;
  background-image:radial-gradient(70.36% 55.66% at 53.77% 44.34%, #183047 0%, #100101 100%);
  line-height: 1 !important;
  font-family: $font-lato !important;
  font-weight: 400;
}
ul {
  padding: 0;
  margin: 0 !important;
  list-style-type: none;
}
.new-main-section {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    @media only screen and (min-width: 1200px) {
      max-width: rem(1120) !important;
    }
  }
}
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  @media only screen and (min-width: 1200px) {
    max-width: 77vw !important;
  }
  @media only screen and (min-width: 992px) {
    max-width: 820px;
  }
}
// @media only screen and (min-width: 1200px) {
// }
