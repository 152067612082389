@mixin hovergradient() {
  background: linear-gradient(
    192.82deg,
    #f4c064 24.44%,
    #fdf9bd 41.36%,
    #c06b3c 89.55%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.new-outside-header {
  position: fixed;
  width: 100%;
  z-index: 6;
  background-color: transparent;
  transition: 0.5s ease-in-out background-color;
  will-change: background-color;

  &__humberger {
    position: absolute;
    @include respond(desktopIpad) {
      top: 50%;
      transform: translateY(-50%);
    }
    right: 0;
    @include respond(mobile) {
      width: rem(25);
    }
    img {
      @include respond(desktopIpad) {
        width: rem(33);
      }
      @include respond(mobile) {
        right: 0;
        width: rem(18);
      }
    }
  }
  &__logowrapper {
    transition: 0.5s ease-in-out background-image;
    will-change: background-image;
    @include bgCover();

    position: relative;
    transition: 0.5s ease-in-out padding;
    will-change: padding;

    @include respond(desktopIpad) {
      padding: 0.7rem rem(15);
      text-align: center;
    }
    @include respond(mobile) {
      padding: rem(20) 0;
      text-align: left;
    }
    &--logo {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: 0.5s ease-in-out width;
      will-change: width;
      @include respond(desktopIpad) {
        width: rem(156);
      }
      img.d-none.d-md-block {
        // &:first-child {
        //   width: rem(26);
        // }
        width: rem(137);
      }
      img.d-block.d-md-none {
        width: rem(99);
      }
    }
  }
}

.new-header-wrapper {
  &.open {
    .new-outside-header {
      @include respond(mobile) {
        background-image: url("/images/line-bg-2.png");
      }
    }
    .new-outside-header__logowrapper {
      @include respond(desktopIpad) {
        background-image: url("/images/line-bg-2.png");
        padding: 0.3rem 1.0714285714rem;
      }
      @include respond(mobile) {
        padding: rem(15) 0;
      }
    }
    .new-outside-header__logowrapper--logo {
      @include respond(desktopIpad) {
        width: 7.4rem;
      }
    }
    .new-outside-header {
      background-color: $black;
    }
  }
}
a {
  cursor: pointer;
}
.new-inside-header {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: radial-gradient(50% 154.13% at 50% 50%, #183047 0%, #100101 100%);

  z-index: 18;
  top: 0;
  left: 0;
  &__close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include respond(desktopIpad) {
      right: rem(15);
    }
    @include respond(mobile) {
      right: 0;
    }
    img {
      @include respond(mobile) {
        right: 0;
        width: rem(20);
      }
    }
  }
  &__logowrapper {
    text-align: center;
    position: relative;

    @include respond(desktop) {
      padding: 0.7rem rem(15);
    }
    @include respond(mobile) {
      padding: 1rem 0;
    }
    &--logo {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        &:first-child {
          width: rem(26);
        }
        &:last-child {
          @include respond(desktopIpad) {
            margin-top: rem(5);
            width: rem(137);
          }
          @include respond(mobile) {
            width: rem(78);
          }
        }
      }
    }
  }
  &__submenu {
    display: none;
    @include respond(desktopIpad) {
      position: absolute;
      left: 28rem;
      top: 1.5rem;
      // text-align: center;
      z-index: 1;
    }
    @include respond(mobile) {
      margin-top: rem(20) !important;
      padding-left: rem(20);
    }
    > li {
      a {
        &:hover {
          ~ .new-inside-header__submenu--bottle {
            @include respond(desktop) {
              display: flex;
            }
          }
        }
        &.active {
          border-radius: 2.6428571429rem;
          display: inline-block;
          padding: 1rem 1.8rem;
          font-size: 1rem;
          text-align: center;
          text-transform: uppercase;
          border: 1px solid #d67d1b;
          transform: translateX(-1.8rem);
        }
        &:not(.active) {
          &:hover {
            @include hovergradient();
          }
        }
      }
      &:first-child {
        a {
          margin-top: 0 !important;
        }
      }
    }
    a {
      display: inline-flex;
      align-items: center;
      background: linear-gradient(
        101.54deg,
        #969696 28.76%,
        #d3cfcf 43.37%,
        #ffffff 53.84%,
        #9f9e9e 67.92%,
        #9c9c9c 94.15%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-family: "Alfa Slab One";

      @include respond(desktopIpad) {
        font-size: rem(18);
      }
      @include respond(margin-top) {
        font-size: rem(14);
      }
    }
  }
  &__menuwrapper {
    @include respond(desktopIpad) {
      margin-top: rem(70);
    }
    @include respond(mobile) {
      margin-top: rem(20);
    }
  }
  &__menu {
    @include respond(desktop) {
      max-width: 52rem;
      flex-basis: 52rem;
      position: relative;
    }
    > li {
      &:hover {
        .new-link-menu {
          text-decoration: none;
          @include hovergradient();
        }
        a {
          ~ .new-inside-header__submenu {
            display: block;
          }
        }
      }
      &:not(:first-child) {
        a {
          @include respond(desktopIpad) {
            margin-top: rem(30);
          }
          @include respond(mobile) {
            margin-top: rem(30);
          }
        }
      }
    }
  }
}
.new-link-menu {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  background: linear-gradient(
    174.88deg,
    #cdcdcd -12.31%,
    #e3e3e3 29.39%,
    #ffffff 49.89%,
    #919191 95.89%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: "Alfa Slab One";

  @include respond(desktopIpad) {
    font-size: rem(24);
  }
  @include respond(mobile) {
    font-size: rem(20);
  }
}
.new-inside-header__menu > li {
  position: relative;
}
.new-inside-header__submenu--bottle {
  position: fixed;
  transform: translateX(17rem) translateY(-15rem);
  top: 20rem;
  display: none;
  right: 10%;
  transform: translateY(-50%);
  background: rgba(106, 42, 17, 0.2);
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 31%);
  border-radius: rem(18);
  width: rem(279);
  height: rem(373);
  justify-content: center;
  border: 1px solid #ffca74;
  padding: 2.5rem 1.25rem;
  img {
    filter: drop-shadow(0px 8px 20px #ffca74);
  }
}

.crafted-section__logo {
  @include respond(mobile) {
    align-items: center;
    margin-bottom: rem(20);
  }
}
.subMenuLink {
  @include respond(mobile) {
    pointer-events: none;
  }
}
.new-inside-header__menu > li:not(:first-child) a {
  a {
    @include respond(desktopIpad) {
      margin-top: rem(33) !important;
    }
    @include respond(mobile) {
      margin-top: rem(30) !important;
    }
  }
}
