.footer-section {
    padding: rem(50) 0;
    background: $color-secondary;
    box-shadow: 0px 4px 55px rgba(0, 0, 0, 0.25);
    position: relative;
    &::before {
        top: 0;
        left: 50%;
        content: "";
        width: 100%;
        height: 5px;
        max-width: rem(734);
        position: absolute;
        transform: translateX(-50%);
        border-top: 5px solid $color-primary;
        @include respond(ipadMobile) {
            max-width: 500px;
        }
        @include respond(mediumMobile) {
            max-width: 260px;
            border-top: 3px solid $color-primary;
        }
    }

    .footer-links-title {
        font-size: rem(12);
        line-height: rem(14);
        color: $color-DE9;
        font-family: "Lato";
        margin-bottom: rem(20);
    }
    .footer-link {
        font-size: rem(11);
        line-height: rem(15);
        text-transform: uppercase;
        font-family: "Alfa Slab One";
        line-height: 1.2;
        color: $color-DE9;
        text-decoration: none;
        cursor: pointer;
        margin-bottom: rem(10);
        &:last-child {
            margin-bottom: 0;
        }
    }
    .footer-social-link {
        background: $color-DE9;
        width: rem(34);
        height: rem(34);
        border-radius: 50%;
        margin-bottom: rem(10);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: rem(25);
        }
        @include respond(mediumMobile) {
            width: rem(25);
            height: rem(25);
            margin-bottom: 0;
            margin-right: rem(14);
            img {
                max-width: rem(18);
            }
        }
    }
    .footer-social-link-conatiner {
        @include respond(mediumMobile) {
            display: flex;
        }
    }
    .footer-site-logo-info {
        max-width: rem(300);
        margin: 50px auto 0;
        text-align: center;
        img {
            max-width: rem(135);
            margin-bottom: rem(15);
            width: 5rem;
        }
        p {
            font-size: rem(14);
            line-height: rem(17);
            font-family: "Lato";
            font-style: italic;
            color: $color-DE9;
            margin-bottom: 0;
            span {
                position: relative;
                &:first-child {
                    color: $white;
                    &::after {
                        display: none;
                    }
                }
                &:last-child {
                    color: $color-primary;
                    &::after {
                        background-color: $color-primary;
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    left: 0;
                    bottom: -2px;
                }
                a{

                    &:hover{
                        text-decoration: none;
                        color: $color-primary;;
                    }
                }
            }
        }
    }
}
.footer-links-inner{
    margin-bottom: rem(10);
}
.back-to-top-container {
    position: fixed;
    transform: rotate(90deg) translateY(-35px);
    bottom: rem(90);
    right: 20px;
    cursor: pointer;
    @include respond(ipadMobile) {
        display: none !important;
    }
}
.back-to-top-btn {
    display: flex;
    align-items: center;
    img,
    svg {
        margin-right: rem(8);
    }
    p {
        margin-bottom: 0;
        color: $color-DE9;
    }
}

.shop-check-out-page {
    .footer-section {
        margin-top: rem(200);
    }
    .section.product-filter-section {
        @include respond(ipadMobile) {
            margin-top: 5rem;
        }
    }
}
