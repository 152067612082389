@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Lato:wght@300;400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bellota+Text&display=swap');

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 12, 2022 */

@font-face {
  font-family: "libre_baskervillebold";
  src: url("/fonts/librebaskerville-bold-webfont.woff2") format("woff2"),
    url("/fonts/librebaskerville-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "libre_baskervilleitalic";
  src: url("/fonts/librebaskerville-italic-webfont.woff2") format("woff2"),
    url("/fonts/librebaskerville-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "libre_baskervilleregular";
  src: url("/fonts/librebaskerville-regular-webfont.woff2") format("woff2"),
    url("/fonts/librebaskerville-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// ssc
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 13, 2022 */

@font-face {
  font-family: "benchninelight";
  src: url("/fonts/benchnine-light-webfont.woff2") format("woff2"),
    url("/fonts/benchnine-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "benchnineregular";
  src: url("/fonts/benchnine-regular-webfont.woff2") format("woff2"),
    url("/fonts/benchnine-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "benchninebold";
  src: url("/fonts/benchnine-bold-webfont.woff2") format("woff2"),
    url("/fonts/benchnine-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
