.btn {
  text-transform: uppercase;
  border-radius: 0;
  cursor: pointer;
  transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
  font-family: $font-alfa !important;
  clip-path: polygon(0 29%, 100% 0, 100% 100%, 0% 100%);
  letter-spacing: normal;
  text-align: center;

  &.sm {
    min-width: rem(115);
    padding: rem(25) rem(10) rem(8) rem(10);
    font-size: rem(10);
    @include flex-x-center();
    span {
      line-height: 1;
    }
  }

  span {
    display: block;

    @include respond(desktop) {
      line-height: rem(25);
    }
    @include respond(ipadMobile) {
      line-height: rem(16);
    }
  }
  &:focus,
  &:active {
    box-shadow: none !important;
    outline: 0 !important;
  }

  @include respond(desktop) {
    // min-width: rem(133);
    font-size: rem(14);
    padding: rem(18) rem(40);
  }

  @include respond(ipadMobile) {
    min-width: rem(141);
    font-size: rem(12);
    white-space: nowrap;
    padding: rem(27.39) rem(20.33) rem(10.71) rem(30.77);
  }

  &.btn-primary {
    // border-color: rgba(106, 42, 17, 0.35);
    // background-color: rgba(106, 42, 17, 0.35);
    // color: #FFCFA1 !important;
    border-color: $color-primary;
    background-color: $color-primary;
    color: $color-secondary !important;
    border: 2px solid $color-primary;
    
  

    &:focus,
    &:active {
      background-color: $color-primary !important;
      border-color: $color-primary !important;
    }
    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }
}

.read-more-btn,
.markdown a {
  color: $color-light;
  font-family: "Lato";
  font-weight: 600;
  font-size: rem(12);
  line-height: rem(24);
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  margin-right: rem(20);

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    right: -12px;
    border-top: 2px solid $color-primary;
    border-left: 2px solid $color-primary;
    transform: rotate(135deg);
  }
  &:hover {
    text-decoration: none;
    &::after {
      border-color: $color-primary;
    }
  }
}

.qty-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  background: $grey-49;
  border-radius: 4px;
  font-family: "Lato";
  font-weight: 600;
  font-size: rem(14);
  line-height: rem(17);
  color: $color-light;
  @include respond(mediumMobile) {
    width: 100px;
  }
  .button {
    border-radius: 4px;
    background: $color-primary;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black;
    cursor: pointer;
    user-select: none;
    text-decoration: none !important;
    @include respond(mediumMobile) {
      height: 20px;
      width: 20px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.link-underline {
  color: $color-grey;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 300;
  position: relative;

  // &::after {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 1px;
  //   left: 0;
  //   bottom: 0;
  //   transform: translateY(2px);
  //   background-color: $color-grey;
  // }
  @include respond(mobile) {
    font-size: rem(12);
  }
}

.subscribe-btn {
  clip-path: none;
  padding: 1rem rem(30);
  font-family: 'Alfa Slab One';
  font-size: rem(12);
  font-weight: 400;
  line-height: rem(20);
  border-radius: 8px;
}

.primary-plus-circle-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-primary;
  border-radius: 50%;
  &.large {
    width: rem(90);
    height: rem(90);
  }
  width: rem(60);
  height: rem(60);
}

