.new-page-footer, .blue-gradient {
  background: radial-gradient(50% 154.13% at 50% 50%, #183047 0%, #100101 100%);
}
.new-page-inner-footer {
  max-width: 46.728571rem;
  margin: 0 auto;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $color-primary;
    top: rem(50);
    left: 50%;
    transform: translateX(-50%);
  }
  @include respond(desktopIpad) {
    padding: rem(100) 4rem rem(50) 4rem;
    display: flex;
    flex-wrap: wrap;
  }
  @include respond(mobile) {
    padding: rem(50) 0;
    display: flex;
    flex-wrap: wrap;
  }
  &__logo {
    text-align: center;
    padding-top: rem(40);
    img {
      width: rem(156);
    }
    p {
      font-size: rem(14);
      margin-bottom: 0;
      margin-top: rem(16);
      span {
        font-style: italic;
        color: $white;
      }
      a {
        color: $color-primary;
      }
    }
  }
  &__link {
    @include respond(mobile) {
      margin-top: rem(40);
    }
    .nikfaldo-desc {
      font-size: rem(14);
      margin-bottom: rem(20);
    }
    &--media {
      @include respond(mobile) {
        flex-direction: row !important;
      }
      li {
        &:not(:first-child) {
          a {
            @include respond(mobile) {
              margin-top: 0 !important;
              margin-left: rem(10);
            }
          }
        }
      }
    }
    ul {
      @include flex-column();

      li {
        &:not(:first-child) {
          a {
            @include respond(desktopIpad) {
              margin-top: rem(10);
            }
            @include respond(mobile) {
              margin-top: rem(10);
            }
          }
        }
        a {
          display: inline-block;
          font-size: rem(12);
          font-family: "Alfa Slab One";
          color: $white;
          text-transform: uppercase;
          line-height: 1.1;
          @include respond(mobile) {
            line-height: 1.6;
          }
        }
      }
    }
  }
}
.explore-blackbullwhiskey-card{
  &__heading{
    border-radius: rem(9);
    position: absolute;
    top: 0;
    background-color: $black;
    border: 1px solid rgba($color: $color-primary1, $alpha: .5);
    left: 50%;
    transform: translateX(-50%) translateY(rem(-27));
    padding: rem(9) rem(15);
    width: 100%;
    @include respond(desktop) {
      max-width: rem(658) !important;
      font-size: rem(24) !important;
    }
    @include respond(ipadMobile) {
      font-size: 1rem !important;
    }
    @include respond(mobile){
      transform: translateX(-50%) translateY(-3.6875rem);
    }
  }
}
.explore-blackbullwhiskey-card__insta {
  border-radius: rem(10);
  background: linear-gradient(180deg, rgba(37, 58, 81, 0.89) 0%, rgba(37, 58, 81, 0) 100%);
  @include respond(desktop) {
    margin-top: rem(55);
    padding: rem(64) rem(54) rem(84) rem(54);
  }
  @include respond(ipadMobile) {
    margin-top: rem(32);
    padding: 3rem 2rem 3rem 2rem;
  }
 
}
.explore-blackbullwhiskey-card__insta--items2,
.explore-blackbullwhiskey-card__insta--items1 {
 @include bgCover();
  // width: rem(300);
  border-radius: rem(6);
  border: 1px solid $color-primary;
  margin: 0 rem(7) rem(15) rem(7);
  position: relative;
  &:hover{
    .explore-blackbullwhiskey-card__innertext{
      @include respond(desktopIpad){
        display: flex;
      }
    }
  }
  &::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid $color-primary;
    @include respond(desktop){
      width: calc(100% - 2.5rem);
      height: calc(100% - 2.5rem);
    }
    @include respond(mobile){
      width: calc(100% - 1.5rem);
      height: calc(100% - 1.5rem);
      display: none;
    }
  }
  @include respond(mobile) {
    margin-bottom: rem(5);
  }
}
.explore-blackbullwhiskey-card__insta--items1 {
  @include respond(desktop){
    height: rem(306);
  }
  @include respond(ipad){
    height: rem(206);
  }
  @include respond(mobile){
    height: rem(135);
  }
}
.explore-blackbullwhiskey-card__insta--items2 {
  height: 12.5571428571rem;
  @include respond(desktopIpad) {
    margin-bottom: rem(2);
  }
}

.explore-blackbullwhiskey-card__insta{
  .slick-prev, .slick-next{
    @include respond(mobile){
      width: rem(30);
    height: rem(30);
    }
  }
  .slick-dots{
    bottom: -1.8125rem;
  }
  .slick-prev{
    left: -2.5rem;
  }
  .slick-next{
    right: -2.5rem;
  }
}