.bartender-slider-card-wrapper {
  position: relative;
  max-width: rem(359);

  @include respond(desktop) {
    margin-left: rem(19.5);
    margin-right: rem(19.5);
  }

  @include respond(ipadMobile) {
    margin-left: rem(30);
    margin-right: rem(30);
    max-width: 100%;
  }

  @include respond(mobile) {
    @include flex-end();
  }

  &:hover {
    .card-border {
      @include respond(desktop) {
        transform: translate(rem(-17), rem(17)) scale(1);
        opacity: 1;
      }
    }
  }

  .card-border {
    @include respond(mobile) {
      transform: translate(-1.4142857143rem, 2.2142857143rem) scale(0.97);
    }
  }
}

.card-border {
  position: absolute;
  left: 0;
  z-index: 1;
  bottom: 0;
  border: 0.25px solid $color-primary;

  @include respond(desktop) {
    transition: 0.5s ease-in-out opacity, 0.5s ease-in-out transform;
    will-change: opacity, transform;
    opacity: 0;
    transform-origin: right;
    transform: translate(rem(-17), rem(17)) scale(0.9);
  }

  @include respond(desktopIpad) {
    width: rem(303);
    height: rem(479);
  }

  @include respond(mobile) {
    transform: translate(rem(-10), rem(31)) scale(0.97);
    width: 100%;
    height: 100%;
  }

  @include respond(ipad) {
    transform: translate(-3.214286rem, 3.814286rem) scale(0.9);
  }
}

.bartender-slider-card {
  position: relative;
  z-index: 2;
  background-image: $black-gradient;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);

  @include respond(desktop) {
    padding: rem(70) rem(115) rem(52) rem(54);
  }

  @include respond(ipadMobile) {
    padding: rem(49) rem(108) rem(36) rem(35);
  }

  @include respond(mobile) {
    max-width: 100%;
    width: 100%;
  }

  &.icons {
    .bartender-slider-card__image {
      top: rem(18);
    }
  }

  &__header {
    font-family: $font-alfa;
    color: $white;

    @include respond(desktop) {
      font-size: rem(22);
      margin-bottom: rem(30) !important;
    }

    @include respond(ipadMobile) {
      text-align: left;
      font-size: rem(18);
      margin-bottom: rem(25) !important;
    }
  }

  &__image {
    position: absolute;

    @include respond(desktop) {
      top: rem(40);
      right: rem(42);
    }

    @include respond(ipadMobile) {
      top: rem(30);
      right: rem(32);
    }
  }

  &__body {
    @include respond(desktop) {
      margin-bottom: rem(56);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(80);
    }

    ul {
      @include flex-column();

      li {
        position: relative;
        font-family: $font-lato;
        font-weight: 300;
        color: $color-grey;
        text-transform: uppercase;
        text-align: left;

        @include respond(desktop) {
          font-size: rem(20);
        }

        @include respond(ipadMobile) {
          font-size: rem(14);
        }

        &:not(:last-child) {
          @include respond(desktop) {
            padding-bottom: rem(15);
            margin-bottom: rem(15);
          }

          @include respond(ipadMobile) {
            padding-bottom: rem(15);
            margin-bottom: rem(15);
          }

          &::after {
            @include borderLine();
          }
        }
      }
    }
  }
}

.card-para {
  text-align: left;
  margin-bottom: 0;
  color: $color-grey;

  @include respond(desktop) {
    line-height: rem(24);
    font-size: rem(14);
  }

  @include respond(ipadMobile) {
    line-height: rem(24);
    font-size: rem(14);
  }

  &.md {
    @include respond(desktop) {
      line-height: rem(20);
      font-size: rem(18);
    }
  }
}

.rotate-card-one-wrapper,
.card-wrapper,
.rotate-card-five-inner-wrapper {
  position: relative;
  filter: drop-shadow(0px 0px 15px #00000040);
}

.ipadwheel {
  @include respond(ipad) {
    display: flex;
    align-items: center;
  }
}
.rotate-card-one {
  min-height: 400px;

  @include respond(desktopIpad) {
    @include flex-between();
    align-items: flex-end;
    clip-path: polygon(0 28%, 100% 0, 100% 100%, 0% 100%);
  }

  @include respond(mobile) {
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  }

  background-color: $color-secondary;

  @include respond(desktop) {
    padding: rem(180) rem(98) rem(64) rem(84);
  }

  @include respond(ipad) {
    padding: rem(180) rem(60) rem(64) rem(60);
  }

  @include respond(mobile) {
    padding: rem(117) rem(31) rem(54) rem(31);
  }

  &__image {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;

    @include respond(desktop) {
      transform: translate(rem(-93), rem(-152));
    }

    @include respond(ipad) {
      width: rem(600);
      transform: translate(-4.642857rem, -6.857143rem);
    }

    @include respond(mobile) {
      transform: translate(-32px, rem(-44));
    }
  }

  .card-para {
    @include respond(desktop) {
      max-width: rem(294);
    }

    @include respond(ipad) {
      max-width: rem(252);
    }

    @include respond(mobile) {
      font-size: rem(12);
      line-height: rem(18);
      text-align: right;
      width: rem(252);
      margin-left: auto;
      position: absolute;
      top: 50%;
      right: 2.2142857143rem;
    }
  }

  .section-heading-2 {
    text-align: right;

    @include respond(desktop) {
      max-width: rem(376);
      margin-bottom: rem(58) !important;
    }

    @include respond(ipad) {
      margin-bottom: rem(38) !important;
    }

    @include respond(mobile) {
      margin-bottom: rem(156) !important;
    }

    @include respond(ipadMobile) {
      max-width: rem(244);
    }
  }

  &__body {
    @include flex-column();
    align-items: flex-end;
  }
}

.card-wrapper {
  position: relative;

  .border {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $color-primary;
    width: 100%;
    border-width: 0 !important;
    height: 100%;

    @include respond(desktopIpad) {
      max-width: rem(944);
      clip-path: polygon(0 0, 100% 0, 100% 72%, 0% 100%);
    }

    @include respond(desktop) {
      transform: translate(-1.4285714286rem, 1.885714rem);
    }

    @include respond(ipad) {
      transform: translate(rem(-20), rem(35));
    }

    @include respond(mobile) {
      height: 95%;
      width: 95%;
      clip-path: polygon(0 0, 100% 0, 100% 91%, 0% 100%);
      transform: translate(0.571429rem, 2rem);
    }

    &::after {
      content: "";
      position: absolute;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background-color: $color-secondary;
      clip-path: polygon(0 0, 100% 0, 100% 72%, 0% 100%);
      top: 1px;
      left: 1px;
      right: 3px;
      bottom: 3px;

      @include respond(mobile) {
        clip-path: polygon(0 0, 100% 0, 100% 91%, 0% 100%);
      }
    }
  }

  &.cardfully {
    .section-heading-2 {
      span {
        @include respond(desktopIpad) {
          display: block;
        }
      }
    }

    background-size: cover;
    background-position: right -1px;
    background-repeat: no-repeat;

    @include respond(desktopIpad) {
      height: rem(509);
    }

    @include respond(mobile) {
      height: rem(650);
    }

    .border {
      display: none;
      z-index: -1;
      height: 100%;
      width: 100%;

      @include respond(desktopIpad) {
        clip-path: polygon(0 0, 100% 28%, 100% 100%, 0% 100%);
      }

      @include respond(desktop) {
        transform: translate(rem(-27), rem(27));
      }

      @include respond(ipad) {
        transform: translate(rem(-20), rem(35));
      }
    }
  }
}

.rotate-card-two {
  position: relative;
  z-index: 1;
  background-image: url("/images/home-jumbotron.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($color: $color-dark, $alpha: 0);
  }

  @include respond(desktop) {
    padding: rem(84) rem(98) rem(98) rem(109);
  }

  @include respond(ipad) {
    padding: rem(84) rem(98) rem(98) rem(109);
  }

  @include respond(mobile) {
    padding: rem(55) rem(31) rem(320) rem(31);
    max-width: calc(100% - 35px);
    margin-left: auto;
  }

  @include respond(desktopIpad) {
    clip-path: polygon(0 0, 100% 0, 100% 72%, 0% 100%);
  }

  @include respond(mobile) {
    clip-path: polygon(0 0, 100% 0, 100% 91%, 0% 100%);
  }

  &__image {
    position: absolute;
    z-index: 2;
    bottom: 0;
    right: 0;

    width: 400px;

    @include respond(desktop) {
      transform: translate(rem(20), rem(110));
    }

    @include respond(ipad) {
      transform: translate(2.428571rem, -3.142857rem);
      width: rem(200);
    }

    @include respond(mobile) {
      transform: translate(20px, 7.6rem);
      width: rem(159);
    }
  }

  &__body {
    position: relative;
    z-index: 1;
    text-align: left;

    @include respond(desktop) {
      min-height: 350px;
    }

    .section-heading-2 {
      @include respond(desktopIpad) {
        max-width: rem(470);
        margin-bottom: rem(179) !important;
      }

      @include respond(mobile) {
        margin-bottom: rem(20) !important;
        font-size: rem(18);
        line-height: rem(24);
      }

      @include respond(ipadMobile) {
        max-width: rem(305);
      }
    }
  }
}

.cardfully-section {
  &:not(.age-number-section) {
    .rotate-card-three + .border-outline {
      @include respond(mobile) {
        transform: rotate(7deg) translate(-2.28571rem, 0) !important;
      }
    }
  }

  .card-para.md {
    max-width: 32rem;
  }
}

.rotate-card-three {
  position: absolute;
  background-color: $color-secondary;
  bottom: 0;
  left: 0;
  width: 100%;

  @include respond(desktopIpad) {
    transform: translate(rem(-20), rem(109));
    max-width: rem(910);
    @include flex-between();
    align-items: flex-end;
    padding: rem(112) rem(67) rem(72) rem(109);
    clip-path: polygon(0 0, 100% 28%, 100% 100%, 0% 100%);
  }

  @include respond(mobile) {
    max-width: calc(100% - 41px);
    text-align: left;
    transform: translate(0, rem(80));
    padding: rem(66) rem(92) rem(60) rem(39);
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
  }

  + .border-outline {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    transform-origin: left;
    border-top: 1px solid $color-primary;

    @include respond(desktop) {
      transform: rotate(6deg) translate(rem(-13), -1rem);
      width: rem(916);
      height: 17rem;
    }

    @include respond(ipad) {
      transform: rotate(6deg) translate(-1.88571rem, 1rem);
      width: 49.428571rem;
      height: 16rem;
    }

    @include respond(mobile) {
      transform: rotate(7deg) translate(-1.78571rem, 0rem) !important;
      width: 97%;
      height: 25.428571rem;
    }

    @media (min-width: 1400px) and (max-width: 1680px) {
      transform: rotate(6deg) translate(-0.9285714286rem, -1rem);
      width: 58.428571rem;
      height: 17rem;
    }

    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 1px;
      background-color: $color-primary;
      right: 0;
      transform-origin: top;

      @include respond(desktopIpad) {
        transform: rotate(-6deg);
      }

      @include respond(mobile) {
        transform: rotate(-7deg);
      }

      @media (min-width: 1000px) and (max-width: 1024px) {
        transform: rotate(-6deg);
        // transform: rotate(-7deg);
        right: 58px;
        height: 245px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $color-primary;
      transform-origin: right;

      @include respond(desktop) {
        transform: rotate(-6deg);
        right: -32px;
        bottom: 0;
      }

      @include respond(ipad) {
        transform: rotate(-6deg);
        right: -23px;
        bottom: 0;
      }

      @include respond(mobile) {
        right: -43px;
        bottom: 1px;
        transform: rotate(-7deg);
      }

      @media (min-width: 1400px) and (max-width: 1440px) {
        transform: rotate(-6deg);
        right: -32px;
        bottom: 0;
      }
    }
  }

  &__body {
    min-height: 160px;

    @include respond(mobile) {
      margin-bottom: rem(60) !important;
    }
  }

  .card-para {
    @include respond(mobile) {
      font-size: rem(12);
    }
  }

  .section-heading-2 {
    text-align: left;
    max-width: rem(448);

    @include respond(desktopIpad) {
      margin-bottom: rem(28) !important;
    }

    @include respond(mobile) {
      margin-bottom: rem(21) !important;
      font-size: 1.8rem !important;
    }
  }
}

.age-is-not-section {
  .cardfully {
    background-image: url("/images/age-is-not-card.jpg");
  }

  .section-heading-2 {
    @include respond(desktopIpad) {
      max-width: rem(400);
    }
  }
}

.serious-bussiness-wrapper {
  .rotate-card-one {
    justify-content: flex-end !important;

    @include respond(desktop) {
      padding: rem(169) rem(98) rem(64) rem(84);
      clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    }

    @include respond(mobile) {
      clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
    }
  }

  .section-heading-2 {
    margin-bottom: rem(24) !important;

    @include respond(desktop) {
      max-width: rem(572) !important;
    }

    @include respond(ipad) {
      max-width: rem(472) !important;
    }

    @include respond(mobile) {
      max-width: rem(208) !important;
    }
  }

  .card-para {
    @include respond(desktopIpad) {
      margin: 0 0 rem(62) auto !important;
    }

    @include respond(mobile) {
      margin: 0 0 rem(77) auto !important;
      position: relative !important;
      right: 0 !important;
    }
  }

  .rotate-card-one__image {
    @include respond(desktop) {
      transform: translate(-0.642857rem, -19.857143rem);
    }

    @include respond(ipad) {
      width: rem(500);
    }

    @include respond(mobile) {
      transform: translate(6%, -7.142857rem);
    }
  }
}

.site-masonry {
  .social-media-card {
    @include respond(mobile) {
      max-width: 100%;
    }
  }
}

.social-media-card {
  max-width: rem(420);
  flex-basis: rem(420);
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;

  &__image {
    width: 100%;
    @include bgCover();
    background-position: top !important;

    &.lg {
      height: rem(395);
    }

    &.sm {
      height: rem(220);
    }
  }

  &__body {
    height: 120px;
    background-color: $white;
    padding: rem(22);
  }

  &__profile {
    @include flex-center();

    .social-media-card__profileimage {
      @include bgCover();
      border-radius: 50%;
      width: rem(24);
      height: rem(24);
      margin-right: rem(8);
    }

    margin-bottom: rem(20);

    h3 {
      margin-bottom: 0;
      color: $black;
      font-size: rem(12);
    }
  }

  &:not(.tester-comment-card) {
    p {
      margin-bottom: 0;
      font-family: $font-lato;
      font-style: italic;
      font-size: rem(14);
      color: $grey-77;
      line-height: rem(16.8);
      width: rem(170);
      margin: 0 auto;
      min-height: rem(32);
      @include text-truncate(4);

      @include respond(smallmobile) {
        @include text-truncate(5);
      }
    }
  }
}

.checkbox-slider-card {
  .bartender-slider-card__body {
    @include respond(desktop) {
      margin-bottom: rem(48);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(54);
    }

    ul {
      display: none;
    }
  }

  .radio-group {
    text-align: left;
  }

  &__form {
    display: block !important;
  }

  &__para {
    display: block !important;
    font-family: $font-lato;
    font-weight: 300;
    text-align: left;
    color: $color-light;
    letter-spacing: 0.6px;

    @include respond(desktop) {
      font-size: rem(20);
      line-height: rem(24);
      margin-bottom: rem(30);
    }

    @include respond(ipadMobile) {
      font-size: rem(16);
      line-height: rem(23);
      margin-bottom: rem(26);
    }
  }
}

.recommended-card {
  @include bgCover();
  @include flex-between();
  align-items: flex-end;
  position: relative;

  &::before {
    content: "";
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 603565.01%,
      #000000 620917.01%
    );
    width: rem(296.29);
    height: rem(173.52);
    bottom: 0;
    left: 0;
    position: absolute;
  }

  h3 {
    max-width: rem(179);
  }

  &.angela-martez {
    &::before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }

    h3 {
      max-width: rem(179);
    }
  }

  &.charles-mclean {
    h3 {
      max-width: rem(142);
    }
  }

  &.johnny-wood {
    h3 {
      max-width: rem(130);
    }
  }

  @include respond(desktopIpad) {
    padding: rem(380) rem(30) rem(30) rem(30);
  }

  @include respond(ipad) {
    margin-bottom: rem(20);
  }

  @include respond(mobile) {
    padding: rem(425) rem(34) rem(38) rem(35);
    margin-bottom: rem(38);
  }

  &__header {
    text-align: left;
    position: relative;

    h3 {
      font-family: $font-lato;
      font-weight: 600;
      font-style: italic;
      color: $color-light;
      font-size: rem(16);
      line-height: rem(19);

      @include respond(desktopIpad) {
        margin-bottom: rem(17.33);
      }

      @include respond(mobile) {
        margin-bottom: rem(18.5);
      }
    }

    p {
      margin-bottom: 0;
      font-family: $font-lato;
      color: $color-light;
      text-transform: uppercase;
      font-size: rem(10);
      line-height: rem(12);
    }
  }

  &__footer {
    position: relative;
    @include flex-center();
    border: 2px solid $white;
    border-radius: 50%;

    img {
      width: rem(10);
    }

    @include respond(desktopIpad) {
      width: rem(32);
      height: rem(32);
    }

    @include respond(mobile) {
      width: rem(34);
      height: rem(34);
    }
  }
}

.some-people-row,
.recommended-card-row {
  > [class*="col-"] {
    &:first-child {
      padding-left: 15px;
    }

    &:last-child {
      padding-right: 15px;
    }
  }
}

.recommended-card-row,
.some-people-row,
.review-row {
  > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.some-people-row-sm {
  > [class*="col-"] {
    &:first-child {
      margin-bottom: rem(20);
    }
  }
}

.peoples-card {
  @include respond(mobile) {
    margin-bottom: rem(38);
  }

  &__image {
    @include bgCover();
  }

  &.lg {
    .peoples-card__image {
      width: 100%;

      @include respond(desktopIpad) {
        height: rem(390);
      }

      @include respond(mobile) {
        height: rem(390);
      }
    }

    .peoples-card__body {
      @include respond(desktopIpad) {
        align-items: flex-end;
        @include flex-between();
        padding: rem(36) rem(40);
      }

      @include respond(mobile) {
        padding: rem(20) rem(30);
        @include flex-column();
        align-items: flex-start;
      }
    }
  }

  &.sm {
    display: flex;

    .peoples-card__image {
      height: rem(247) !important;
      width: calc(100% - 152px);

      @include respond(desktop) {
        height: rem(390);
      }

      @include respond(ipad) {
        height: rem(264) !important;
      }
    }

    .peoples-card__body {
      padding: 40px 18px 23px 19px;
      @include flex-column();
      justify-content: space-between;
      max-width: rem(152);
    }
  }

  &__body {
    background-color: $white;
  }

  &__header {
    text-align: left;

    @include respond(mobile) {
      margin-bottom: rem(20);
    }

    h3 {
      @include whiteCardHeading();

      @include respond(desktopIpad) {
        max-width: rem(225);
      }
    }

    p {
      @include whiteCardPara();
    }
  }
}

.site-masonry {
  margin-left: -10px;
  margin-right: -10px;
}

.rotate-card-five-wrapper {
  &.ltr {
    .rotate-card-two__body {
      align-items: flex-end;
      margin-left: auto;
    }

    .rotate-card-two__image {
      left: 0;

      @include respond(desktopIpad) {
        // transform: translate(-2.3rem, 10.757143rem);
        transform: translate(-2.3rem, 7rem);
      }

      @include respond(mobile) {
        transform: translate(-20px, 7.6rem);
      }
    }

    .section-heading-2 {
      text-align: right;
    }

    .rotate-card-two {
      @include respond(desktopIpad) {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 79%);
      }

      @include respond(mobile) {
        margin: unset;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 91%);
      }
    }

    .border {
      @include respond(desktopIpad) {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 79%);
        right: 0;
        transform: translate(2.071429rem, 1.9285714286rem);
      }

      @include respond(mobile) {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 91%);
      }

      &::after {
        @include respond(desktopIpad) {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 79%);
        }

        @include respond(mobile) {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 91%);
        }
      }
    }
  }

  .rotate-card-five-inner-wrapper {
    z-index: 1;
  }

  .border {
    @include respond(desktopIpad) {
      clip-path: polygon(0 0, 100% 0, 100% 79%, 0% 100%);
    }

    @include respond(mobile) {
      clip-path: polygon(0 0, 100% 0, 100% 91%, 0% 100%);
    }

    @include respond(ipad) {
      transform: translate(2.071429rem, 1.928571rem) scale(0.95) !important;
    }

    &::after {
      @include respond(desktopIpad) {
        clip-path: polygon(0 0, 100% 0, 100% 79%, 0% 100%);
      }

      @include respond(mobile) {
        clip-path: polygon(0 0, 100% 0, 100% 91%, 0% 100%);
      }
    }
  }

  .rotate-card-two__body {
    @include flex-column();
    align-items: flex-start;

    @include respond(desktopIpad) {
      width: 60%;
    }
  }

  .rotate-card-two__image {
    @include respond(desktopIpad) {
      // transform: translate(2.3rem, 10.757143rem);
      transform: translate(2.3rem, 7rem);
    }

    @include respond(ipad) {
      width: 19.285714rem;
    }
  }

  .rotate-card-two {
    background-color: $color-secondary;

    @include respond(desktopIpad) {
      clip-path: polygon(0 0, 100% 0, 100% 79%, 0% 100%);
    }

    @include respond(mobile) {
      clip-path: polygon(0 0, 100% 0, 100% 91%, 0% 100%);
      padding: 3.9285714286rem 2.2142857143rem 19.057143rem 2.2142857143rem !important;
    }
  }

  .section-heading-2 {
    @include respond(desktop) {
      margin-bottom: rem(17) !important;
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(21) !important;
    }

    span {
      display: block;

      &:nth-last-child(-n + 2) {
        color: $white !important;
      }
    }
  }

  p {
    color: $color-light;
    text-transform: uppercase;

    @include respond(desktop) {
      font-size: rem(18);
      line-height: rem(22);
      margin-bottom: rem(70) !important;
    }

    @include respond(ipadMobile) {
      font-size: rem(12);
      line-height: rem(18);
      margin-bottom: rem(60) !important;
    }
  }

  .rotate-card-two {
    background-image: none;

    &::after {
      display: none;
    }
  }
}

@mixin productCardActive() {
  .btn {
    visibility: visible;
    opacity: 1;

    @include respond(desktopIpad) {
      bottom: rem(18);
    }

    @include respond(mobile) {
      bottom: rem(18);
    }
  }

  + .card-border {
    opacity: 1;
    transform: translate(rem(-42), rem(-6)) scale(0.86);

    @include respond(mobile) {
      transform: translate(-0.8rem, 0) scale(0.86);
    }
  }
}

.product-fliter-card-wrapper {
  &:hover {
    text-decoration: none;
  }
}
.ml-n32 {
  margin-left: -32px;
}
.out-of-stock {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: rem(10);
  color: #000;
  background: #f97272;
  padding: rem(10);
  font-size: rem(12);
  top: rem(5);
  left: rem(5);
}
.product-btn-wrap {
  display: flex;
}

.product-fliter-card {
  text-align: center;
  background: $black-gradient;
  position: relative;
  z-index: 2;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);

  &__btn {
    display: flex;
    position: absolute;
    left: 0;
    width: 100%;
    justify-content: space-between;
    &.out-of-stock-btn {
      > .btn {
        min-width: 100% !important;
        width: 100% !important;
        clip-path: polygon(0 29%, 100% 0, 100% 100%, 0 100%) !important;
      }
    }
    > .btn {
      &:first-child {
        background: #121212;
        border-color: #121212;
        color: #fff !important;
        clip-path: polygon(0 0, 100% 29%, 100% 100%, 0% 100%);
      }
    }
    .btn {
      width: 50%;
      min-width: 50%;
      right: 0;
      left: inherit !important;
      transform: none !important;
      margin: 0;
      font-size: 0.7rem !important;
      padding: 1.528571rem 1rem 0.328571rem 1rem !important;
      visibility: visible !important;
      opacity: 1 !important;
      white-space: nowrap;
    }
    div {
      width: 50%;
      .btn {
        width: 100% !important;
      }
    }
  }
  &:hover {
    text-decoration: none;
  }

  @include respond(desktopIpad) {
    padding: 1.0714285714rem 5.7142857143rem 3.771429rem 3.5714285714rem;
    margin-bottom: rem(50);
    // min-height: rem(385);
  }

  @include respond(mobile) {
    padding: rem(10) rem(80) rem(15);
    margin-bottom: rem(50);
    width: 100%;
    margin-left: auto;
  }

  h3 {
    margin-bottom: 0;
    color: $color-light;
    font-weight: 300;
    text-transform: uppercase;
    font-size: rem(14);

    @include respond(desktopIpad) {
      min-height: rem(35);
    }
  }

  img {
    @include respond(desktopIpad) {
      margin-bottom: rem(8);
    }

    @include respond(mobile) {
      width: rem(155);
    }
  }

  &.active {
    @include productCardActive();

    @include respond(mobile) {
      width: 95%;
    }
  }

  @include respond(desktop) {
    &:hover {
      @include productCardActive();
    }
  }

  .btn {
    // position: absolute;
    left: 50%;
    transform: translateX(-16%);
    transition: 0.5s ease-in-out bottom, 0.5s ease-in-out visibility,
      0.5s ease-in-out opacity;
    visibility: hidden;
    opacity: 0;
    padding: 0.4285714286rem 1.4285714286rem 0rem 1.4285714286rem;

    @include respond(desktop) {
      bottom: 0;
    }

    @include respond(ipadMobile) {
      bottom: rem(18);
    }
  }

  + .card-border {
    width: 100%;
    opacity: 0;
    height: 100%;
    transform: translate(rem(-42), rem(-6)) scale(0.76);

    @include respond(mobile) {
      transform: translate(-0.8rem, 0) scale(0.86);
    }
  }
}

.about-card {
  display: grid;
  grid-template-columns: 1fr 3fr;
  color: $color-light;
  text-align: left;
  font-family: "Lato";
  margin-bottom: rem(100);

  @include respond(ipadMobile) {
    margin-bottom: rem(60);
  }

  // @include respond(mediumMobile) {
  //     grid-template-columns: auto;
  // }
  &:last-child {
    margin-bottom: 0;
  }

  .about-card-img {
    img {
      max-width: rem(120);
    }

    @include respond(mediumMobile) {
      margin-bottom: 1rem;

      img {
        max-width: rem(55);
      }
    }

    @include respond(smallmobile) {
      margin-bottom: 1rem;

      img {
        max-width: rem(55) !important;
      }
    }
  }

  .about-card-body {
    h4 {
      font-size: rem(18);
      line-height: rem(24);
      color: $color-primary;
      font-weight: bold;
      margin-bottom: rem(15);

      span {
        color: $color-primary;
        font-weight: 300;
      }
    }

    @include respond(mediumMobile) {
      h4 {
        font-size: 1rem;
        margin-bottom: rem(8);
      }
    }

    .about-card-awards {
      margin-bottom: rem(36);

      p {
        font-size: rem(14);
        line-height: rem(30);
        margin-bottom: rem(0);

        @include respond(ipadMobile) {
          line-height: normal;
          margin-bottom: rem(16) !important;
        }
      }

      p span {
        color: $color-primary;
      }

      @include respond(mediumMobile) {
        margin-bottom: rem(25);

        p {
          font-size: rem(10);
          margin-bottom: rem(8);
        }
      }
    }

    .about-card-badges-container {
      .bottle-badges {
        margin-right: rem(15);

        &:last-child {
          margin: 0;
        }

        @include respond(ipadMobile) {
          margin-right: rem(10);
          margin-bottom: rem(10);
        }

        @include respond(mediumMobile) {
          max-width: rem(28);
          margin-right: rem(5);
          margin-bottom: rem(5);
        }
      }
    }
  }
}

.mb-50 {
  margin-bottom: rem(50) !important;
}

.mb-60 {
  margin-bottom: rem(60) !important;
}

.mb-90 {
  margin-bottom: rem(90) !important;
}

.mb-100 {
  margin-bottom: rem(100) !important;
}

.article-wrapper-one {
  margin-bottom: rem(90) !important;

  @include respond(ipadMobile) {
    margin-bottom: rem(50) !important;
  }
}

.article-wrapper-two {
  margin-bottom: rem(60);
}

// .address-card {
//   padding: rem(35) 0 rem(25);
//   border-bottom: 1px solid $color-primary;
//   font-family: "Lato";
//   padding-right: 3rem;
//   text-align: left;

//   min-width: 400px;

//   &.no-border {
//     border: none !important;
//   }

//   &.active {
//     border-bottom: 3px solid $color-primary;
//   }

//   &.custom-info-window {
//     // padding: rem(30) rem(50) !important;
//     padding: 0.5rem !important;
//     background: $color-secondary;
//     border: none;
//   }

//   .address-card__image {
//     flex-shrink: 0;
//     width: 25%;
//     height: auto;
//     max-width: 125px;
//     max-height: 125px;
//     object-fit: cover;
//     object-position: center;
//     // align-self: flex-start;
//     margin-right: rem(18);
//     border-radius: rem(5) !important;
//   }

//   .address-card__text {
//     h4 {
//       font-size: 25px !important;
//       line-height: 167% !important;
//     }

//     .address-text {
//       font-size: 14px !important;
//       line-height: 167% !important;
//     }
//   }

//   .shop-name {
//     font-family: "Lato";
//     font-size: rem(18);
//     font-weight: 400;
//     line-height: rem(22);
//     margin-bottom: rem(17);
//     color: $color-primary;
//   }

//   .address-text {
//     font-family: "Lato";
//     font-size: rem(16);
//     font-weight: 400;
//     line-height: rem(22);
//     color: $color-grey;
//     margin-bottom: rem(0);
//   }

//   .hours-open {
//     font-size: rem(16);
//     font-weight: 400;
//     line-height: rem(22);
//     margin-bottom: 0;
//     color: $color-grey;

//     span {
//       font-size: rem(16);
//       font-weight: 400;
//       line-height: rem(22);
//       color: $color-primary;
//     }
//   }

//   .directions {
//     font-size: rem(12);
//     font-weight: 400;
//     line-height: rem(14.4);
//     margin-bottom: 0;
//     color: $color-primary;

//     &.action-link {
//       text-decoration: underline;
//       text-transform: uppercase;
//     }
//   }

//   @include respond(mediumMobile) {
//     padding: rem(15);

//     &.custom-info-window {
//       // padding: rem(20) rem(40) rem(30) rem(30) !important;
//       padding: 10px !important;
//       box-shadow: none !important;
//       margin-bottom: 0;
//       min-width: 90vw;
//       overflow: auto;

//       .shop-name {
//         text-overflow: ellipsis;
//         white-space: nowrap;
//         overflow: hidden;
//       }
//     }
//   }
// }

.text-underline {
  text-decoration: underline !important;
}
.add-to-cart {
  padding: 16px;
  display: grid;
  place-items: center;
}
.add-to-cart-btn {
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  height: 45px;
  width: 100%;
  border-radius: 24px;
}
.add-to-cart-btn-detail {
  background: #303033;
  width: 233px;
  color: #f9c467;
}
.add-to-cart-detail {
  padding: 16px 0;
}
.prodct-details-card {
  @include respond(desktop) {
    @include flex-between();
  }

  width: 100%;

  > div {
    padding: 0 15px;
  }

  &__preview {
    img {
      transform: translateY(-20px);
    }
  }

  &__list {
    ul {
      @include flex-column();

      @include respond(desktop) {
        margin-top: rem(160) !important;
      }

      li {
        text-align: center;
        border: 1px solid #f2d9b6;

        @include respond(desktop) {
          &:not(:last-child) {
            margin-bottom: rem(30);
          }

          .bottle-preview {
            width: rem(18);
          }
        }

        a {
          @include flex-center();

          &.active {
            border: 2px solid $color-primary;
            opacity: 1;
          }

          @include respond(desktop) {
            width: rem(65);
            height: rem(65);
            padding: rem(4) rem(7);
          }
        }
      }
    }
  }
}

.prodct-check-out {
  @include respond(desktop) {
    width: rem(346);
  }

  text-align: left;

  &__header {
    @include respond(desktop) {
      margin-bottom: rem(26);
    }

    .star-rating {
      @include respond(ipadMobile) {
        position: absolute;
        right: rem(20);
        bottom: 0;
      }
    }

    @include respond(ipadMobile) {
      position: relative;
      margin-bottom: rem(20);
      text-align: left;
    }

    .section-heading-2 {
      margin-bottom: 0 !important;

      @include respond(ipadMobile) {
        font-size: rem(18);
      }
    }

    p {
      margin-bottom: 0;
      color: $color-primary;
      text-transform: uppercase;
      font-weight: 300;
      font-size: rem(18);

      @include respond(ipadMobile) {
        font-size: rem(14);
      }
    }
  }

  &__price {
    @include respond(desktop) {
      margin-bottom: rem(38);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(30);
    }

    h2 {
      color: $color-primary;
      font-weight: 700;
      margin-bottom: rem(26);
    }
  }

  &__code {
    @include respond(desktop) {
      margin-bottom: rem(55);
    }

    @include respond(ipad) {
      display: flex;
      justify-content: flex-start !important;
      flex-wrap: wrap;
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(50);
    }

    @include respond(mobile) {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-end;
    }
  }

  &__body {
    margin-bottom: rem(55);
    color: $grey-ea;
    margin-bottom: rem(35);

    @include respond(desktop) {
      line-height: rem(30);
      font-size: rem(18);
    }

    @include respond(ipadMobile) {
      line-height: rem(26);
    }

    p {
      @include respond(desktop) {
        margin-bottom: rem(35);
      }

      @include respond(ipadMobile) {
        margin-bottom: rem(23);
      }
    }

    &--link {
      @include respond(mobile) {
        @include flex-between();
        flex-wrap: wrap;
      }

      a {
        @include textLink();
        text-decoration: none !important;
        padding-bottom: 2px;
        border-bottom: 1px solid $color-light;

        &:first-child {
          @include respond(ipad) {
            margin-left: 0;
          }
        }

        &:nth-child(2n-3) {
          @include respond(desktop) {
            margin-left: 0;
          }
        }

        @include respond(desktopIpad) {
          margin: 0 rem(32) rem(15) rem(32);
        }

        @include respond(mobile) {
          margin: 0 0 rem(30) 0;
        }
      }

      .locate {
        color: $color-primary;
        font-size: rem(14);
        border-bottom: 1px solid $color-primary;
      }
    }
  }

  .checkout-btn {
    vertical-align: bottom;
    margin-bottom: rem(20) !important;
    display: inline-block;

    @include respond(mobile) {
      line-height: 1;
    }

    &:hover {
      color: $color-light;
      text-decoration: none;
    }
  }

  .product-badges-container {
    margin-bottom: rem(5);
  }
}

.product-badges-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -14px;
  margin-right: -14px;

  img {
    max-width: 70%;
    padding: 0 14px;
    margin-bottom: rem(15);
  }
}

.see-more {
  cursor: pointer;
  font-weight: 600;
  color: $color-light;
  @include flex-y-center();
  line-height: 1;

  @include respond(desktop) {
    font-size: rem(12);
    margin-bottom: rem(35);
  }

  @include respond(ipadMobile) {
    margin-bottom: rem(50);
    font-size: rem(14);
  }

  &:hover {
    text-decoration: none;
    color: $color-light;
  }

  img {
    transform: rotate(-90deg);
  }
}

.product-desc-section-card-wrapper {
  @include respond(mobile) {
    width: 94%;
    margin-left: auto;
  }

  .border {
    width: 100%;
    height: 100%;

    @include respond(desktop) {
      clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
    }

    @include respond(ipadMobile) {
      clip-path: polygon(0 3%, 100% 0, 100% 100%, 0% 100%);
      transform: translate(-1.428571rem, 2rem);
    }

    &::after {
      width: calc(100% - 2px);
      height: calc(100% - 2px);

      @include respond(desktop) {
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
      }

      @include respond(ipadMobile) {
        clip-path: polygon(0 3%, 100% 0, 100% 100%, 0% 100%);
      }
    }
  }
}

.slick-track {
  .slick-slide {
    &:first-child {
      .product-desc-section-card {
        @include respond(mobile) {
          flex-direction: column-reverse;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.product-desc-section-card {
  background-color: $color-secondary;
  width: 100%;
  align-items: flex-start;

  @include respond(desktop) {
    padding-bottom: rem(90);
    padding-left: calc(7rem - 3.0714285714rem);
    padding-right: calc(7rem - 3.0714285714rem);
    clip-path: polygon(0 15%, 100% 0, 100% 100%, 0% 100%);
  }

  @include respond(ipad) {
    padding-bottom: rem(90);
    padding-left: calc(7rem - 3.0714285714rem);
    padding-right: calc(7rem - 3.0714285714rem);
  }

  @include respond(ipadMobile) {
    flex-wrap: wrap;
    clip-path: polygon(0 3%, 100% 0, 100% 100%, 0% 100%);
    padding-top: rem(60);
  }

  &__description {
    @include respond(ipad) {
      margin: 0 auto rem(100) auto;
    }

    @include respond(mobile) {
      margin-top: rem(60);
    }
  }

  > div {
    @include respond(desktop) {
      padding: 0 rem(43);
    }

    @include respond(ipad) {
      padding: 0 rem(23);
    }

    @include respond(mobile) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  &__details {
    text-align: left;

    @include respond(desktop) {
      max-width: calc(100% - 321px);
      flex-basis: calc(100% - 321px);
    }

    @include respond(ipadMobile) {
      max-width: calc(100%);
      flex-basis: calc(100%);
    }
  }
}

.product-details-card {
  display: flex;
  position: relative;
  max-width: rem(251);
  flex-basis: rem(251);

  &:not(:last-child) {
    margin-bottom: 3.357143rem;
  }

  &__image {
    @include flex-center();
    border: 1px solid $color-primary;
    border-radius: 50%;
    position: absolute;
    bottom: 0;

    @include respond(desktop) {
      transform: translate(rem(60), rem(30));
      right: rem(60);
      width: rem(60);
      height: rem(60);
    }

    @include respond(ipadMobile) {
      transform: translate(rem(54), rem(26));
      width: rem(54);
      right: rem(54);
      height: rem(54);
    }
  }

  h3 {
    font-size: rem(14);
    border-bottom: 2px solid $color-primary;
    text-align: left;
    position: relative;
    color: $color-light;
    margin-bottom: 0;

    @include respond(desktop) {
      margin-right: rem(60);
      padding: 0 rem(12) rem(12) rem(40);
      width: rem(202);
    }

    @include respond(ipadMobile) {
      margin-right: rem(54);
      width: rem(196);
      padding: 0 rem(10) rem(10) rem(40);
    }

    &::after {
      content: "";
      position: absolute;
      width: rem(24);
      height: rem(10);
      left: 0;
      background-color: $color-primary;
      clip-path: polygon(0 45.5%, 100% 0, 100% 100%, 0% 100%);
      bottom: 0;
      transform: translateY(1px);
    }
  }
}

// .product-desc-component-wrapper {
//     @include respond(desktopIpad) {
//         margin-bottom: rem(35);
//     }
//     @include respond(mobile) {
//         margin-bottom: rem(40);
//     }
// }
.product-desc-component {
  display: flex;

  @include respond(mobile) {
    flex-direction: column;
  }

  &:not(:last-child) {
    @include respond(desktopIpad) {
      margin-bottom: rem(32);
    }

    @include respond(mobile) {
      margin-bottom: 1.857143rem;
    }
  }

  &__header {
    color: $color-primary;
    margin-bottom: 0;
    font-family: $font-alfa;

    @include respond(desktop) {
      font-size: rem(16);
      min-width: rem(120);
      padding-top: 3px;
    }

    @include respond(ipadMobile) {
      font-size: rem(14);
      margin-bottom: rem(10);
      min-width: rem(120);
    }
  }

  &__body {
    display: flex;
    width: 100%;

    @include respond(mobile) {
      align-items: center;
    }
  }
}

.badge-circle {
  border-radius: 50%;
  border: 2px solid $color-light;
  display: inline-block;
  margin: 0 rem(10);

  &:nth-child(2) {
    margin-left: rem(20);
  }

  @include respond(desktop) {
    width: rem(35);
    height: rem(35);
  }

  @include respond(ipadMobile) {
    width: rem(35);
    height: rem(35);
  }

  &.orange {
    background-color: $color-warning;
  }

  &.burgundy {
    background-color: $color-danger;
  }
}

.card-para-1 {
  margin-bottom: 0;
  font-weight: 300;
  color: $color-light;

  @include respond(desktop) {
    font-size: rem(18);
    line-height: rem(24);
  }

  @include respond(ipadMobile) {
    font-size: rem(16);
    line-height: rem(22);
  }
}

.product-desc-comment-wrapper {
  display: flex;

  @include respond(mobile) {
    flex-wrap: wrap;
  }
}

.product-desc-comment-card {
  @include flex-column();

  &:not(:last-child) {
    @include respond(mobile) {
      margin-bottom: rem(40);
    }
  }

  &:first-child {
    @include respond(desktopIpad) {
      padding-right: rem(27);
    }
  }

  &:last-child {
    @include respond(desktopIpad) {
      padding-left: rem(27);
    }
  }

  @include respond(desktopIpad) {
    max-width: 50%;
    flex-basis: 50%;
  }

  @include respond(mobile) {
    max-width: 100%;
    flex-basis: 100%;
  }

  span {
    display: inline-block;
    @include bgCover();
    border-radius: 50%;

    @include respond(desktop) {
      width: rem(100);
      height: rem(100);
      margin-bottom: rem(20);
    }

    @include respond(ipadMobile) {
      width: rem(60);
      height: rem(60);
      margin-bottom: rem(16);
    }
  }
}

.tester-comment-card {
  @include respond(ipad) {
    max-width: rem(300);
  }

  @include respond(mobile) {
    width: 100%;
    padding-left: rem(15);
    padding-right: rem(15);
  }

  .social-media-card__body {
    @include flex-column();
    text-align: left;
    align-items: flex-start;

    padding: rem(22) rem(33);
  }

  h3 {
    min-height: rem(34);
    @include text-truncate-2();
    @include whiteCardHeading();
  }

  p {
    @include whiteCardPara();
    margin-bottom: rem(7);
  }
}

@mixin inactiveStar() {
  fill: $color-grey;
  opacity: 0.7;
}

.star-rating {
  svg {
    @include respond(ipadMobile) {
      width: rem(12);
      height: rem(12);
    }
  }

  &.one {
    svg {
      &:not(:first-child) {
        path {
          @include inactiveStar();
        }
      }
    }
  }

  &.two {
    svg {
      &:nth-child(n + 3) {
        path {
          @include inactiveStar();
        }
      }
    }
  }

  &.three {
    svg {
      &:nth-child(n + 4) {
        path {
          @include inactiveStar();
        }
      }
    }
  }

  &.four {
    svg {
      &:nth-child(n + 5) {
        path {
          @include inactiveStar();
        }
      }
    }
  }

  svg {
    margin: 0 rem(3.5);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.prodct-details-card-image-slider {
  min-height: rem(395);
  width: 100%;
  padding: rem(27);
  background-image: $black-gradient;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);

  img {
    width: rem(150);
    margin: 0 auto;
  }
}

.best-buy-card {
  @include respond(desktopIpad) {
    padding: 0 rem(8.5);
    width: 100%;
  }

  @include respond(mobile) {
    padding: 0 rem(5);
  }

  &__image {
    @include bgCover();
    width: 100%;

    @include respond(desktopIpad) {
      height: rem(318);
      margin-bottom: rem(30);
    }

    @include respond(mobile) {
      width: rem(147);
      height: rem(173);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(30);
    }
  }

  &__heading {
    text-transform: uppercase;
    font-weight: 300;
    color: $color-light;
    margin-bottom: rem(10);

    @include respond(desktop) {
      font-size: rem(18);
    }

    @include respond(ipadMobile) {
      font-size: rem(14);
    }
  }

  &__price {
    color: $color-primary;
    font-weight: 700;

    @include respond(desktop) {
      font-size: rem(18);
      margin-bottom: rem(26);
    }

    @include respond(ipadMobile) {
      font-size: rem(16);
      margin-bottom: rem(21);
    }
  }

  .btn {
    display: inline-block !important;
  }
}

.indian-review-section {
  .social-media-card {
    max-width: 100%;
    flex-basis: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.customer-reviews {
  text-align: left;

  &__body {
    margin-bottom: rem(42);
  }

  &__star {
    @include flex-y-center();
    margin-bottom: rem(40);
  }

  p {
    font-size: rem(12) !important;
    margin-bottom: 0 !important;
    margin-left: rem(12);
  }

  &__footer {
    text-decoration: underline;

    &:hover {
      color: $color-primary;
    }

    @include respond(desktop) {
      font-size: rem(18);
    }

    @include respond(ipadMobile) {
      font-size: rem(12);
    }
  }
}

.customer-reviews-star-count {
  display: flex;

  &:not(:last-child) {
    @include respond(desktop) {
      margin-bottom: rem(18);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(18);
    }
  }

  p {
    font-weight: 300;
    margin-left: 0 !important;

    @include respond(desktop) {
      font-size: rem(18) !important;
      margin-right: rem(15) !important;
    }

    @include respond(ipadMobile) {
      font-size: rem(16) !important;
      margin-right: rem(15) !important;
    }
  }

  &__range {
    position: relative;
    width: 100%;
    border-radius: 5px;
    max-width: rem(280);
    height: rem(18);
    background-color: $grey-49;

    .bar {
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 5px;
      background-color: $color-primary;
    }
  }
}

.asked-question {
  text-align: left;

  h3 {
    color: $color-light;
    font-family: $font-lato;
    font-weight: 300;
    text-transform: uppercase;

    @include respond(desktop) {
      font-size: rem(12);
      margin-bottom: rem(16);
    }

    @include respond(ipadMobile) {
      font-size: rem(12);
      margin-bottom: rem(16);
    }
  }

  ul {
    li {
      &:not(:last-child) {
        margin-bottom: rem(16);
      }

      a {
        text-transform: uppercase;
        color: $color-primary;
        text-decoration: underline;

        &:hover {
          color: $color-primary;
        }

        @include respond(desktop) {
          font-size: rem(12);
        }

        @include respond(ipadMobile) {
          font-size: rem(14);
          line-height: 1.5;
        }
      }
    }
  }
}

hr {
  border-color: $color-primary !important;
  margin: 0 !important;
}

.you-might-like-card {
  display: flex;

  &__link {
    max-width: rem(150);
    @include flex-column();

    &:first-child {
      @include respond(desktop) {
        margin-left: 0;
      }
    }

    &:last-child {
      @include respond(desktop) {
        margin-right: 0;
      }
    }

    @include respond(desktop) {
      margin-left: rem(28);
      margin-right: rem(28);
    }

    @include respond(ipadMobile) {
      font-size: rem(12);
      margin: 0 rem(10);
    }

    img {
      @include respond(desktop) {
        width: rem(136);
        margin-bottom: rem(36);
      }

      @include respond(ipadMobile) {
        width: rem(92);
        margin-bottom: rem(12);
      }
    }

    span {
      text-transform: uppercase;
      font-weight: 300;
      color: $color-light;

      @include respond(desktop) {
        font-size: rem(18);
      }

      @include respond(ipadMobile) {
        font-size: rem(12);
      }
    }
  }
}

.video-section {
  video {
    @include respond(desktop) {
      width: 100%;
    }
  }
}

.steps-card {
  position: relative;
  border: 1px solid $color-primary;
  @include flex-center();
  border-radius: 50%;

  @include respond(desktop) {
    width: rem(135);
    height: rem(135);
  }

  @include respond(ipad) {
    width: rem(85);
    height: rem(85);
  }

  @include respond(mobile) {
    width: rem(70);
    height: rem(70);
  }

  @include respond(mediumMobile) {
    width: rem(46);
    height: rem(46);
  }

  img {
    @include respond(ipad) {
      width: rem(35);
    }

    @include respond(mobile) {
      width: rem(25);
    }

    @include respond(mediumMobile) {
      width: rem(18);
    }

    &.bottle-svg {
      @include respond(ipad) {
        width: rem(20);
      }

      @include respond(mobile) {
        width: rem(13);
      }
    }

    &.delivery-svg {
      @include respond(ipadMobile) {
        width: 3.5rem;
      }

      @include respond(mediumMobile) {
        width: 2rem;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    height: 1px;
    background-color: $color-primary;
    top: 50%;
    transform: translateY(-50%);

    @include respond(desktop) {
      left: -92%;
      width: 80%;
    }

    @include respond(ipad) {
      left: -125%;
      width: 100%;
    }

    @include respond(mobile) {
      left: -98%;
      width: 80%;
    }

    @include respond(mediumMobile) {
      width: 70%;
      left: -90%;
      transform: translateY(-55%);
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 8px;
    transform: scale(1.5);
    background-image: url("/svg/arrow.svg");
    @include bgContain();

    @include respond(desktop) {
      left: -14%;
    }

    @include respond(ipad) {
      left: -28%;
    }

    @include respond(mobile) {
      left: -28%;
    }

    @include respond(mediumMobile) {
      transform: scale(1);
    }
  }
}

.steps-card-container {
  @include flex-between();

  @include respond(mediumMobile) {
    max-width: 360px;
    margin: 0 auto;
  }

  .step-card-wrapper {
    &:first-child .steps-card::before,
    &:first-child .steps-card::after {
      display: none;
    }

    @include respond(mediumMobile) {
      width: 74px;
    }

    .steps-card {
      margin: 0 auto 22px;
    }

    h4,
    p {
      font-family: "Lato";
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;

      @include respond(ipadMobile) {
        font-size: 11px;
        font-weight: 300;
        line-height: 15px;
      }
    }

    p {
      color: $color-primary;
      margin-bottom: 0;
    }

    h4 {
      color: $color-light;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
}

.video-section {
  iframe {
    width: 100%;

    @include respond(desktop) {
      height: rem(535);
    }

    @include respond(ipad) {
      height: rem(435);
    }

    @include respond(mobile) {
      height: rem(300);
    }
  }
}

.product-filter-sm {
  &__header {
    @include flex-between();
    margin-bottom: rem(38);

    a {
      font-family: $font-alfa;
      font-size: rem(16);
      color: $color-primary;

      &:hover {
        text-decoration: none;
        color: $color-primary;
      }
    }
  }

  &__body {
    padding-top: rem(24);
    border-top: 0.5px solid $color-primary;
  }
}

.product-filter-sm-grp {
  h3 {
    font-family: $font-alfa;
    font-size: rem(16);
    color: $color-light;
    margin-bottom: rem(18);
    text-align: left;
  }

  .btn {
    display: inline-block;
  }

  .radio-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .radio-toolbar-card {
      margin: 0 0 rem(18) 0;
      width: calc(100% / 2 - 15px);
    }
  }
}

.section {
  position: relative;
}

.stick-element {
  position: absolute;
  width: 100%;
  top: 0;
  height: 1px;
  left: 0;
  transform: translateY(-50px);
  visibility: hidden;
}

.product-range-grand-section {
  .stick-element {
    transform: translateY(0);
  }

  .about-first-section .stick-element {
    top: -40px;
  }
}

.section-heading-2 {
  &.xs {
    @include respond(desktop) {
      font-size: rem(22);
    }

    @include respond(ipadMobile) {
      font-size: rem(18);
    }
  }
}

.input-error-wrapper {
  position: relative;

  .site-input-group__error {
    position: absolute;
    bottom: 0;
    left: 50%;

    @include respond(desktop) {
      transform: translateY(29px) translateX(-50%);
    }

    @include respond(ipadMobile) {
      transform: translateY(25px) translateX(-50%);
      font-size: rem(11);
    }
  }
}

.age-verifiation-card {
  text-align: center;

  // @include respond(extralargedesktop) {
  //   transform: scale(1.4);
  // }

  .site-logo-wrapper {
    margin-right: 0;
    display: block;

    @include respond(desktop) {
      margin-bottom: rem(30);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(25);
    }
  }

  h1 {
    line-height: 1 !important;

    @include respond(desktop) {
      margin-bottom: rem(30) !important;
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(25) !important;
    }
  }

  &__desc {
    font-weight: 300;
    // color: $color-grey;
    font-family: "Bellota Text";
    font-style: normal;
    color: #f9f8f7;
    @include respond(desktop) {
      margin: rem(25) auto;
      font-size: rem(16);
      max-width: rem(558);
      line-height: rem(22);
    }
    @include respond(ipad) {
      margin: rem(20) auto;
      line-height: 1.4;
      font-size: rem(16);
      max-width: rem(558);
    }
    @include respond(mobile) {
      margin: rem(20) auto;
      line-height: 1.2;
      font-size: rem(14);
    }

    @include respond(mobile) {
      // max-width: rem(214);
    }
  }

  .top-nav {
    transform: scale(1);
    justify-content: center;
    padding: 0;
    position: relative;

    @include respond(desktop) {
      margin-bottom: rem(25);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(20);
    }

    .top-nav__search {
      @include respond(desktopIpad) {
        max-width: rem(280);
        flex-basis: rem(280);
      }
    }
  }

  .btn {
    @include respond(desktop) {
      margin-bottom: rem(40);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(45);
    }
  }

  &__links {
    padding: 0;
    list-style-type: none;
    @include flex-between();
    flex-wrap: wrap;

    @include respond(desktop) {
      margin: 0 auto rem(35) auto !important;
      max-width: 40rem;
    }

    @include respond(ipadMobile) {
      margin: 0 auto rem(25) auto !important;
      max-width: 40rem;
    }

    @include respond(mobile) {
      justify-content: center;
    }

    li {
      width: 180px;

      @include respond(mobile) {
        margin: 0 rem(30) rem(25) 0;
        width: auto;
      }
    }

    a {
      text-transform: uppercase;
      font-family: $font-alfa;
      color: $white;

      &:hover {
        color: $white;
        text-decoration: none;
      }

      @include respond(desktop) {
        font-size: rem(12);
      }

      @include respond(ipadMobile) {
        font-size: rem(10);
      }

      span {
        text-transform: lowercase;
      }
    }
  }

  &__details {
    font-weight: 400;
    color: $color-grey;
    font-family: "Bellota Text";
    @include respond(desktop) {
      margin: 0 auto rem(25) auto;
      max-width: rem(734);
      font-size: rem(14);
      line-height: rem(22);
    }

    @include respond(ipadMobile) {
      font-size: rem(12);
      margin: 0 auto rem(20) auto;
      line-height: rem(18);
    }

    @include respond(mobile) {
      // max-width: rem(247);
      text-align: center;
    }

    &.query {
      @include respond(desktop) {
        margin: rem(25) auto;
      }

      @include respond(ipadMobile) {
        margin: rem(20) auto;
      }
    }

    &.address {
      font-weight: 300;
      margin-bottom: 0;
      font-size: rem(12);
    }

    &.policy {
      a {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: 100%;
          background-color: $color-primary;
        }
      }
    }

    a {
      color: $color-primary;

      &:hover {
        text-decoration: none;
        color: $color-primary;
      }
    }
  }
}
.age-varification-card__linebreaker {
  @include respond(desktop) {
    display: block;
  }
  @include respond(ipadMobile) {
    display: none;
  }
}
.color-variant-wrapper {
  position: relative;
  width: 100%;

  @include respond(mobile) {
    display: flex;
    flex-direction: column-reverse;
  }

  p {
    color: $color-grey;

    @include respond(desktop) {
      font-size: rem(18);
      transform: translate(35px, -50%);
    }

    @include respond(ipadMobile) {
      font-size: rem(16);
    }

    @include respond(ipad) {
      transform: translate(15px, -50%);
    }

    @include respond(desktopIpad) {
      margin-bottom: 0;
      font-weight: 300;
      position: absolute;
      top: 50%;
      right: 0;
    }
  }
}

.color-variant {
  @include respond(desktopIpad) {
    max-width: rem(210);
    flex-basis: rem(230);
  }

  @include flex-between();
  position: relative;
  align-items: center;

  &::after {
    content: "";
    position: absolute;
    width: calc(100% - 10px);
    height: 2px;
    background-color: #c4c4c4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__child {
    display: inline-block;
    width: rem(15);
    height: rem(15);
    border-radius: 50%;
    position: relative;
    z-index: 1;

    &.active {
      width: rem(27);
      height: rem(27);
      border: 1px solid $color-primary;
    }
  }
}

.static-pages-card {
  &__inner {
    &:not(:last-child) {
      margin-bottom: rem(40);
    }

    p {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  @include respond(desktop) {
    // max-width: rem(840);
    text-align: center;
  }

  .section-heading-2 {
    @include respond(desktop) {
      margin-bottom: rem(50) !important;
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(30) !important;
      font-size: rem(18) !important;
      text-align: center;
    }
  }

  ul {
    // list-style-type: decimal;
    counter-reset: my-sec-counter;
    margin-bottom: 2.8571428571rem !important;

    @include respond(desktop) {
      padding-left: rem(113);
    }

    @include respond(ipadMobile) {
      padding-left: 3rem;
    }

    li {
      @include description();
      margin-bottom: 0 !important;
      position: relative;

      @include respond(ipadMobile) {
        font-size: rem(16) !important;
        line-height: 26px !important;
      }

      &::after {
        counter-increment: my-sec-counter;
        content: counter(my-sec-counter) ". ";
        position: absolute;
        left: 0;
        top: 0;

        @include respond(desktop) {
          transform: translateX(-43px);
        }

        @include respond(ipadMobile) {
          transform: translateX(-23px);
        }
      }
    }
  }

  h3 {
    color: $white;
    font-weight: 400;
    margin-bottom: rem(18);
    font-family: "Alfa Slab One";
    text-transform: uppercase;
    @include respond(desktop) {
      font-size: rem(16) !important;
    }

    @include respond(ipadMobile) {
      font-size: rem(14) !important;
    }
  }

  p {
    @include description();
    font-family: "Bellota Text";
    color: #eddbb5;
    @include respond(desktop) {
      font-size: rem(14);
      line-height: 30px;
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(40) !important;
      font-size: rem(16) !important;
      line-height: 26px !important;
    }

    span {
      color: $color-primary;
    }
  }
}

.contact-us-main {
  @include respond(desktop) {
    padding-top: rem(150);
  }

  @include respond(ipadMobile) {
    padding-top: rem(100);
  }
}

.radar-card {
  canvas {
    @include respond(desktop) {
      transform: translateX(rem(150));
    }

    @include respond(ipadMobile) {
      // padding-top: rem(100);
    }
  }
}

.radar-desc-card {
  ~ canvas {
    @include respond(mobile) {
      transform: scale(1.5);
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }

  text-align: left;
  padding: 0 !important;

  @include respond(desktop) {
    max-width: 185px;
    position: absolute;
  }

  h2 {
    font-weight: 300;
    color: $color-grey;
    text-transform: uppercase;

    @include respond(desktop) {
      margin-bottom: rem(30);
      font-size: rem(22) !important;
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(20);
      font-size: rem(15) !important;
    }
  }

  h3 {
    font-family: $font-alfa;
    color: $color-primary;
    margin-bottom: rem(10);

    @include respond(desktop) {
      font-size: rem(18) !important;
    }

    @include respond(ipadMobile) {
      font-size: rem(18) !important;
    }
  }

  &__body {
    @include respond(desktop) {
      margin-bottom: rem(40);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(30);
    }

    p {
      font-weight: 300;
      color: $color-grey;

      @include respond(desktop) {
        line-height: 24px;
        font-size: rem(18);
      }

      @include respond(ipadMobile) {
        line-height: 20px;
        font-size: rem(14);
      }
    }
  }

  .radar-desc-card-active-1 {
    font-weight: 300;
    color: $color-primary;

    @include respond(desktop) {
      line-height: 20px;
      font-size: rem(14);
      margin-bottom: rem(50);
    }

    @include respond(ipadMobile) {
      line-height: 20px;
      font-size: rem(14);
      margin-bottom: rem(40);
    }
  }

  .radar-desc-card-active-2 {
    font-style: italic;
    color: $color-primary;
  }
}

.product-detail-grand-section {
  .component-loader {
    margin-bottom: 0;

    @include respond(desktop) {
      margin-top: rem(250);
    }

    @include respond(ipadMobile) {
      margin-top: rem(100);
    }
  }
}

.markdown-content {
  text-align: left;
  margin-top: rem(64);
  // @include markdown();
}

.static-pages-section {
  .section-heading-2 {
    @include respond(desktop) {
      margin-bottom: rem(50) !important;
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(30) !important;
      font-size: rem(18) !important;
      text-align: center;
    }
  }
}

.home-banner {
  // border: 1px solid #fff;
  height: 48vw;
  display: flex;
  position: relative;

  .slider-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;

    background-image: url(/images/bb-bg-new.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    .carousel {
      height: 100%;

      .carousel-inner {
        height: 100%;

        .carousel-item {
          height: 100%;

          .img-wrapper {
            height: 100%;

            display: flex;
            flex: 1;
            justify-content: center;
            align-items: flex-end;

            padding-bottom: 2vw;

            img {
              height: 32vw;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}

.playButton {
  cursor: pointer;
  // position: relative;
  z-index: 2;

  @include respond(desktopIpad) {
    // transform: translate(-50%, -50%);
    // top: rem(100);
    // right: rem(20);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }

  @include respond(mobile) {
    // left: rem(20);
    // top: rem(20);
    // opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }

  img {
    // transform: scale(0.7);

    @include respond(mobile) {
      width: rem(20);
    }
    @include respond(desktop) {
      width: rem(30);
    }
    @include respond(ipad) {
      width: rem(25);
    }
    @media screen and (min-width: 360px) {
      width: rem(25);
    }
  }
}

.main-section {
  @include respond(mobile) {
    max-width: rem(420);
    margin: 0 auto;
  }
}

.store-locator-page {
  @include respond(mobile) {
    .main-section {
      max-width: none;
    }
  }
}

.your-cart-product__body {
  @include respond(desktop) {
    padding-left: rem(15);
    margin-top: 0;
  }

  span {
    color: $color-primary !important;

    &:last-child {
      font-weight: 400;
    }
  }
}

.your-cart-card {
  text-align: left;

  &__header {
    border-bottom: 1px solid $color-primary;

    p {
      color: $color-primary;
      text-transform: uppercase;
      font-weight: 300;
      font-size: rem(18);

      @include respond(desktop) {
        margin-bottom: rem(30);
        padding-left: rem(15);
      }

      @include respond(ipadMobile) {
        font-size: rem(14);
      }
    }
  }

  &__total {
    border-bottom: 1px solid $color-primary;

    @include respond(desktop) {
      padding: rem(68) rem(15) rem(68) rem(15);
    }

    @include respond(ipadMobile) {
      padding: rem(29) rem(5);
    }

    .row {
      [class*="col-"] {
        @include flex-y-center();
      }
    }
  }

  &__body {
    position: relative;
    border-bottom: 1px solid $color-primary;

    @include respond(desktop) {
      padding: rem(30) rem(15) rem(25) rem(15);
    }

    @include respond(ipadMobile) {
      padding: rem(29) rem(5);
    }

    .row {
      [class*="col-"] {
        @include flex-y-center();
      }
    }

    &--close {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      img {
        @include respond(mobile) {
          max-width: rem(12);
        }
      }
    }
  }
}

.your-cart-product {
  @include respond(desktop) {
    display: flex;
  }

  img {
    @include respond(desktop) {
      max-width: rem(45);
      margin-right: rem(30);
    }

    @include respond(ipadMobile) {
      max-width: rem(38);
    }
  }

  &__body {
    margin-top: rem(25);

    span {
      display: block;
      color: $color-grey;

      @include respond(desktop) {
        font-size: rem(18);
      }

      @include respond(ipadMobile) {
        font-size: rem(12);
      }

      &:first-child {
        font-weight: 300;
        margin-bottom: rem(3);
      }

      &:last-child {
        font-weight: 600;
      }
    }
  }
}

.your-cart-qty {
  align-items: center;
  @include flex-between();
  background-color: $grey-49;
  overflow: hidden;
  border-radius: 5px;

  @include respond(desktop) {
    margin-left: rem(15);
    width: rem(153);
    height: rem(30);
  }

  @include respond(ipadMobile) {
    width: rem(87);
    height: rem(22);
  }

  &__count {
    color: $color-grey;
  }

  a {
    @include flex-center();
    color: $color-secondary;
    background-color: $color-primary;

    @include respond(desktop) {
      width: rem(30);
      height: rem(30);
    }

    @include respond(ipadMobile) {
      width: rem(22);
      height: rem(22);
    }

    &:hover {
      text-decoration: none;
      color: $color-secondary;
    }
  }
}

.your-cart-price {
  font-weight: 700;
  color: $color-primary;

  @include respond(desktop) {
    padding-left: rem(15);
    font-size: rem(28);
  }
}

.apply-input-wrapper {
  @include flex-end();

  @include respond(desktop) {
    padding: rem(60) 0;
  }

  @include respond(ipadMobile) {
    padding: rem(40) 0;
  }

  .apply-code-div {
    margin-bottom: 0 !important;

    @include respond(mobile) {
      width: 100%;
      max-width: 100%;
    }
  }
}

// Progress
.progressbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  counter-reset: step;
  transition: width 0.5s ease-in-out;
  will-change: width;

  @include respond(desktop) {
    max-width: rem(410);
  }

  &::before,
  .progress {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    background-color: #dcdcdc;
    z-index: 1;

    @include respond(desktop) {
      height: 4px;
    }

    @include respond(ipadMobile) {
      height: 2px;
    }
  }

  .progress {
    background-color: $color-primary;
    width: 0%;
    transition: 0.3s;
  }

  .progress-step {
    width: rem(23);
    height: rem(23);
    background-color: #dcdcdc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    &::before {
      counter-increment: step;
      content: counter(step);
    }

    &::after {
      content: attr(data-title);
      position: absolute;
      top: calc(100% + 0.5rem);
      font-size: 0.85rem;
      color: #666;
    }

    &.active {
      background-color: $color-primary;
    }
  }
}

.your-cart-sign-up {
  @include respond(desktop) {
    padding-top: rem(135);
  }

  .sign-up-form {
    @include respond(ipadMobile) {
      padding-top: rem(47);
      text-align: center;
    }
  }
}

.sub-total-cart {
  text-align: left;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    left: 10px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    background: linear-gradient(
      89.75deg,
      #303033 5.8%,
      #38383a 51.02%,
      #303033 94.2%
    );
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 25%);
  }

  @include respond(desktop) {
    padding: rem(135) 0 rem(135) rem(56);
    margin-left: rem(70);
  }

  &__total {
    border-top: 1px solid $color-primary;
    @include flex-end();
    align-items: flex-end;
    padding-right: 1.0714285714rem;

    .your-cart-product__body {
      margin-top: 0;
    }

    .your-cart-price {
      @include respond(desktop) {
        padding-left: rem(51);
      }
    }

    @include respond(desktop) {
      margin-top: rem(405);
      padding-top: rem(26);
    }
  }

  .section-heading-2 {
    @include respond(desktop) {
      margin-bottom: rem(40) !important;
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(30) !important;
    }
  }

  &__body {
    border-top: 1px solid $color-primary;
    border-bottom: 1px solid $color-primary;

    @include respond(desktop) {
      padding: rem(30) 0;
    }

    @include respond(ipadMobile) {
      padding: rem(20) 0;
    }

    .your-cart-product {
      > .your-cart-product__body {
        span {
          color: $color-grey !important;
        }

        .your-cart-product__body {
          display: flex;
          padding-left: 0;

          @include respond(desktop) {
            margin-top: rem(10) !important;
          }

          span {
            color: $color-primary !important;
          }
        }
      }
    }

    .your-cart-price {
      @include flex-end();
      padding-right: rem(15);
    }
  }

  &__apply {
    @include respond(desktop) {
      max-width: 100% !important;
      margin-top: rem(45);
    }
  }
}

.or-seperator {
  display: block;
  margin-left: auto;
  font-weight: 300;
  color: $color-primary;
  position: relative;
  text-align: center;

  span {
    background-color: $color-secondary;
    display: inline-block;
    z-index: 1;
    position: relative;
    padding: 0 rem(10);
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 1px;
    width: 100%;
    background-color: $color-primary;
  }

  @include respond(desktop) {
    margin-top: rem(43);
    margin-bottom: rem(32);
    font-size: rem(18);
  }

  @include respond(ipadMobile) {
    margin-top: rem(30);
    margin-bottom: rem(30);
    font-size: rem(16);
  }
}

.payment-detail-wrapper {
  text-align: left;

  @include respond(desktop) {
    margin-top: 5.3571428571rem;
    max-width: 29.2857142857rem;
  }

  @include respond(ipadMobile) {
    margin-top: rem(50);
  }

  &__header {
    font-weight: 300;
    text-transform: uppercase;
    color: $color-primary;

    @include respond(desktop) {
      font-size: rem(24);
      margin-bottom: rem(33);
    }

    @include respond(ipadMobile) {
      font-size: rem(18);
      margin-bottom: rem(27);
    }
  }

  &__btn {
    @include respond(desktop) {
      margin-top: rem(166);
    }

    @include respond(ipadMobile) {
      margin-top: rem(100);
      text-align: center;
    }
  }
}

.payment-detail-card__formbody {
  color: $color-grey;
  font-weight: 300;

  @include respond(desktop) {
    padding-left: rem(30);
    font-size: rem(16);
    line-height: 19px;
    margin-top: rem(12);
    max-width: rem(267);
  }

  @include respond(ipadMobile) {
    padding-left: rem(30);
    line-height: 17px;
    margin-top: rem(12);
    max-width: rem(208);
  }

  p {
    margin-bottom: 0;

    @include respond(desktop) {
      margin-top: rem(17);
    }

    @include respond(ipadMobile) {
      margin-top: rem(17);
    }

    span {
      font-weight: 400;
    }
  }

  .payment-detail-card__action {
    @include respond(desktop) {
      margin-top: rem(30);
    }

    @include respond(ipadMobile) {
      margin-top: rem(25);
    }

    a {
      text-transform: uppercase;
      text-decoration: underline;

      @include respond(ipadMobile) {
        font-size: rem(12);
      }

      &:last-child {
        margin-left: rem(30);
      }
    }
  }
}

.add-address-card {
  background: linear-gradient(
    89.75deg,
    #303033 5.8%,
    #38383a 51.02%,
    #303033 94.2%
  );
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
  display: flex;
  text-decoration: none;
  color: $color-primary;

  &:hover {
    text-decoration: none;
    color: $color-primary;
  }

  @include respond(desktop) {
    padding: rem(46) rem(32);
    margin-top: rem(25);
    font-size: rem(16);
  }

  @include respond(ipadMobile) {
    padding: rem(32);
    margin-top: rem(20);
  }
}

.payment-detail-card {
  background: linear-gradient(
    89.75deg,
    #303033 5.8%,
    #38383a 51.02%,
    #303033 94.2%
  );
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);

  @include respond(desktop) {
    padding: rem(30);
  }

  @include respond(ipadMobile) {
    padding: rem(25);
  }

  input {
    margin-bottom: 0;
  }

  .radio-group {
    margin-bottom: 0;

    label {
      margin-bottom: 0;
      font-weight: 600;
      text-transform: capitalize;
    }
  }

  &:not(:last-child) {
    @include respond(desktop) {
      margin-bottom: rem(25);
    }

    @include respond(ipadMobile) {
      margin-bottom: rem(16);
    }
  }
}

.blog-card-section {
  .col-md-4 {
    padding-left: 10px;
    padding-right: 10px;

    &:first-child,
    &:nth-child(3n + 1) {
      padding-left: 15px;
    }

    &:nth-child(3n) {
      padding-right: 15px;
    }

    @include respond(ipadMobile) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.blog-card {
  margin-bottom: 2rem;

  @include respond(mobile) {
    margin-bottom: 60px;
  }

  &.xl {
    @include respond(mobile) {
      margin-bottom: 50px;
      padding: 0 20px;
    }

    .blog-card__image {
      height: rem(650);

      @include respond(mobile) {
        height: 420px;
      }
    }

    .blog-card__body {
      padding-left: rem(80);

      @include respond(ipadMobile) {
        padding-left: rem(20);
      }

      @include respond(mobile) {
        padding-left: rem(0);
      }

      h3 {
        max-width: rem(220);
        margin-bottom: 1.5rem;

        span {
          display: inline-block;
        }
      }

      p.desc {
        max-width: rem(340);
      }
    }
  }

  &__image {
    width: 100%;
    @include bgCover();
    height: rem(550);
    margin-bottom: 2.5rem;
    position: relative;

    .img-filter {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #0a0a0a;
      opacity: 0.5;
    }
  }

  &__body {
    text-align: left;
    color: $color-grey;

    h3 {
      font-family: "Alfa Slab One";
      font-size: rem(18);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 1.2rem;
      max-width: 100%;
      text-transform: uppercase;

      span {
        color: $color-primary;
        display: block;
      }
    }

    p.desc {
      font-family: "Lato";
      font-size: rem(18);
      font-weight: 400;
      line-height: rem(24);
      max-width: rem(285);
      margin-bottom: rem(30);

      @include respond(mobile) {
        font-size: 14px;
        margin-bottom: 15px;
        max-width: 100%;
      }
    }

    p.date {
      font-family: "Lato";
      font-size: rem(18);
      font-weight: 300;
      line-height: normal;
      margin-bottom: 0;

      @include respond(mobile) {
        font-size: 14px;
      }
    }
  }
}

.long-blog-card {
  color: $color-grey;
  width: 100%;

  &__image {
    width: 100%;
    @include bgCover();
    height: rem(500);
    margin-bottom: rem(60);

    @include respond(mobile) {
      height: 15rem;
      margin-bottom: 40px;
    }
  }

  &__header {
    text-align: center;
    max-width: rem(600);
    margin: 0 auto;

    h4 {
      font-family: "Alfa Slab One";
      font-size: rem(32);
      font-weight: 400;
      line-height: rem(42);
      margin-bottom: rem(20);
      text-transform: uppercase;

      @include respond(mobile) {
        font-size: rem(28);
        line-height: rem(36);
      }

      span {
        color: $color-primary;
        display: block;
      }
    }

    p.sub-info {
      font-family: "Lato";
      font-size: rem(22);
      font-weight: 300;
      line-height: rem(28);
      text-align: center;
      max-width: rem(500);
      margin: 0 auto rem(35);

      @include respond(mobile) {
        font-size: rem(18);
        line-height: rem(22);
      }
    }
  }

  &__body {
    text-align: left;
    max-width: 50rem;
    margin: 0 auto;

    p.desc {
      font-family: "Lato";
      font-size: rem(18);
      font-weight: 400;
      line-height: rem(30);
      @include text-truncate(5);
      margin-bottom: rem(30);

      @include respond(mobile) {
        font-size: rem(14);
        line-height: rem(26);
        @include text-truncate(9);
        margin-bottom: 1.5rem;
      }
    }

    p.full.desc {
      display: block;
    }
  }
}

.hero-content-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  width: 100%;

  .btn {
    @include respond(desktopIpad) {
      transform: scale(0.9);
    }

    @include respond(mobile) {
      transform: scale(0.8);
    }
  }

  .section-heading-2 {
    @include respond(desktop) {
      margin-bottom: rem(30) !important;
    }

    @include respond(mobile) {
      font-size: 1.2rem;
      line-height: 1.3;
      margin-bottom: 0 !important;
    }
  }

  @include respond(mobile) {
    width: 100%;
  }
}
.nikfaldo-section-eighteen-years {
  .nikfaldo-section-eighteen-years .add-to-card-nikfaldo__image {
    display: none;
  }
}

.falvour-wheels-section{
  @include respond(mobile){
    display: none;
  }
}
.instagram-details-card{
  .slick-prev, .slick-next{
    background-color: $black;
    @include respond(mobile){
      width: rem(30);
      height: rem(30);
    }
  }
  .slick-next{
    @include respond(mobile){
      transform: translate(-12px, -50%) !important;
    }
  }
 .slick-prev{
    @include respond(mobile){
      transform: translate(12px, -50%) rotate(180deg) !important;
    }
  }
}