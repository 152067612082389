.overflow-hidden {
  overflow: hidden;
}

.race-career-bg {
  background-image: url(/images/super-trofeo/1-Mitchell-and-Lamborghini.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  @include respond(desktopIpad) {
    min-height: rem(767);
    padding: rem(110) 0;
  }
  @include respond(mobile) {
    padding: rem(40) 0;
  }
  .heading-bright-1 {
    @include respond(desktopIpad) {
      max-width: rem(300);
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.font-heading {
  font-family: "Alfa Slab One", sans-serif;
}

.font-medium {
  font-weight: 500;
}

.text-primary {
  color: #f9c467 !important;

  display: block;
}

.bg-primary {
  background-color: #f9c467 !important;
}

.justify-contents-end {
  justify-content: flex-end;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

@media (min-width: 992px) {
  .pac-target-input {
    padding-left: rem(17) !important;
  }
}

.super-trofeo-page {
  .main-section {
    overflow: visible;
    // max-width: 1410px !important;
  }

  .banner-bg {
    background-position: center;
    background-size: cover;
    // margin-top: rem(15);
    @include respond(desktopIpad) {
      min-height: rem(700);
    }
    @include respond(mobile) {
      min-height: rem(500);
    }
  }

  .banner-bg::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .banner {
    padding-top: 12rem;

    .overline {
      font-size: 35px;
      line-height: 65px;
      display: flex;
      justify-content: center;
    }

    .title {
      font-size: 54px;
      line-height: 66px;
      display: flex;
      justify-content: center;
      // gap: 20px;
    }

    .content {
      font-size: 20px;
      line-height: 1.67;
      max-width: rem(606);
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  }

  .section-1 {
    // padding: 100px 150px 220px;
    // margin: 0;
    // position: relative;

    .overline {
      font-size: 35px;
      line-height: 58.45px;
    }

    .title {
      font-size: 45px;
      line-height: 57.5px;
      display: flex;
      justify-content: center;

      margin-bottom: 55px;
    }

    .content {
      // font-size: 20px;
      // line-height: 1.67;
      // display: flex;
      // justify-content: center;
      // text-align: center;
      text-align: left;
      color: #f9f8f7;
      // margin-bottom: 0;
      margin: 0 auto;
      font-family: "lato";
      max-width: 52rem;

      p {
        font-size: 1.2857142857rem;
        margin-bottom: 2.1428571429rem;
        line-height: 2rem;
        position: relative;
      }
    }

    .clipped-border {
      clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
    }

    .clipped-border-cover {
      display: block;
      position: absolute;
      width: 98%;
      height: 100%;
      left: rem(25);
      bottom: rem(25);
    }

    .clipped-border-bg {
      display: block;
      position: absolute;
      left: 2px;
      top: 2px;
      height: calc(100% - 4px);
      width: calc(100% - 4px);
    }
  }

  .section-2 {
    padding-bottom: 60px;

    .overline {
      font-size: rem(36);
      line-height: 1.67;
    }

    .title {
      font-size: 42px;
      line-height: 48px;
      display: flex;
      flex-direction: column;
    }

    .content {
      font-family: Lato;
      font-size: 20px;
      font-weight: 500;
      line-height: 33px;
      letter-spacing: 0em;
      // position: absolute;
      // left: 170px;
      width: 333px;
      height: 238px;
      padding-top: 30px;
      margin-bottom: 180px;
    }

    .race-career {
      display: flex;
      flex-direction: column;
      // padding-left: 350px;
      align-items: end;
      .nikfaldo-desc {
        @include respond(desktopIpad) {
          max-width: rem(350);
        }
        @include respond(mobile) {
          margin-top: rem(20);
        }
      }
      .super-trofeo-summary {
        @include respond(desktopIpad) {
          margin-top: rem(60);
        }
        @include respond(mobile) {
          margin-top: rem(130);
        }
      }
    }

    .clipped-border {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
    }

    .clipped-border-cover {
      display: block;
      position: absolute;
      width: 97%;
      height: 97%;
      right: rem(25);
      top: rem(25);
    }

    .clipped-border-bg {
      display: block;
      position: absolute;
      right: 2px;
      top: 2px;
      height: calc(100% - 4px);
      width: calc(100% - 4px);
    }
  }

  .section-3 {
    padding-top: 15px;
    padding-bottom: 52px;

    .car-image {
      width: 100%;
      border: 1px solid #f9c46780;
      height: 350px;
      object-fit: cover;
    }
  }

  .section-4 {
    display: flex;
    justify-content: center;

    .one-goal,
    .one-vision {
      // width: 150px;
      // left: 473px;
      // top: 2805px;

      font-family: "Alfa Slab One";
      font-style: normal;
      font-weight: 400;
      font-size: rem(24);
    }

    .footer-title {
      display: flex;
      justify-content: "center";
      flex-direction: column;
      margin: "0 30px";
    }

    .black-image {
      width: 86.95px;
      height: 23.34px;
      left: 677.04px;
      top: 2789px;
    }

    .bull-image {
      width: 87px;
      height: 43.23px;
      left: 677px;
      top: 2814.51px;
    }
  }

  .bottles-slider::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    background-image: url("/images/about/slider-bg.jpg");
    background-size: cover;
    background-position: center;
  }

  .bottles-slider {
    position: relative;

    .bottles-slider-card-content::-webkit-scrollbar {
      width: 5px;
    }

    .slider-plank {
      position: absolute;
      display: block;
      background-image: url("/images/about/slider-plank.png");
      background-position: bottom center;
      background-size: cover;
      width: 100%;
      height: rem(160);
      bottom: 0;
    }

    .slick-track {
      display: flex;
      flex-wrap: wrap;
      // align-items: flex-end;
    }

    .slick-dots {
      top: rem(20);
      bottom: auto;

      li {
        border-radius: rem(99);
        background-color: rgba(255, 255, 255, 0.48) !important;
      }

      li.slick-active {
        background-color: #f9c467 !important;
        transform: none;
      }
    }

    .bottles-slider-item {
      padding-top: rem(60);
      padding-bottom: rem(60);
      position: relative;
    }

    .bottles-slider-card {
      color: #fff;
      background-color: #151515;
      border-radius: rem(10);
      margin: rem(20) rem(20);
      padding: rem(40);
      max-width: rem(340);
    }

    .bottles-slider-card-title {
      font-size: rem(20);
      line-height: rem(27);
    }

    .bottles-slider-card-content {
      font-size: rem(12);
      line-height: 1.67;
    }
  }

  .super-trofeo-summary {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(57.01deg, #f9c467 1.27%, #0a0909 97.52%);
      opacity: 0.4;
      border: 1px solid rgba(249, 196, 103, 0.5);
      border-radius: 6px;
    }

    display: grid;
    gap: 10px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;

    width: 350px;
    padding: 20px 20px 20px 20px;

    .summary-item {
      background: rgba(249, 196, 103, 0.19);
      border: 1px solid rgba(249, 196, 103, 0.1);
      backdrop-filter: blur(1px);
      border-radius: rem(6);

      display: flex;
      flex-direction: column;
      justify-content: revert;
      align-items: center;

      padding: 15px;

      h4 {
        color: #fff;
        font-family: "Baskerville";
        font-size: rem(39);
        font-weight: 400;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: rem(10);
      }

      .description {
        color: #fff;
        font-family: Lato;
        font-size: 11px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
      }
    }
  }

  #footer-newsletter {
    background-image: url("/images/about/newsletter-bg.jpg");
    background-size: cover;

    .footer-newsletter-title {
      font-size: rem(12);
      line-height: rem(14);
      font-weight: 500;
    }

    .footer-newsletter-input {
      box-shadow: none;
      border: 1px solid rgba(255, 255, 255, 0.28);
      border-radius: 0;
      margin-right: rem(10);
      background-color: rgba(31, 31, 32, 1);
      color: rgba(255, 255, 255, 0.42);
      height: rem(34);
    }

    .footer-newsletter-btn {
      font-size: rem(12);
      line-height: rem(14);
      height: rem(34);
      background-color: rgba(255, 255, 255, 0.78);
      outline: none;
      border: none;
      padding-left: rem(14);
      padding-right: rem(14);
    }
  }

  @media screen and (min-width: 1500px) {
    .section-2 {
      .title {
        width: 70%;
      }
    }
  }

  @include respond(ipad) {
    .section-1 {
      .title {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .section-2 {
      .title {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }

      .content {
        margin-bottom: 30px;
        font-size: 1.2857142857rem;
        line-height: 2rem;
      }
    }

    .section-3 {
      padding: 15px 15px 52px;

      .car-image {
        height: 200px;
      }
    }

    .section-4 {
      padding: 5px;
      align-items: center;

      .footer-title {
        padding: 0 12px;
      }

      .one-goal,
      .one-vision {
        width: auto;
        height: auto;
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.2;
        padding: 0;
      }
    }
  }

  @include respond(ipadProMobile) {
    .section-2 {
      .content {
        // margin-bottom: 30px;
        font-size: 1.2857142857rem;
        line-height: 2rem;
      }
    }

    .section-4 {
      padding: 5px;
      align-items: center;

      .footer-title {
        padding: 0 12px;
      }

      .one-goal,
      .one-vision {
        width: auto;
        height: auto;
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.2;
        padding: 0;
      }
    }
  }

  @include respond(mobile) {
  

    .banner {
      // padding-top: 12rem;

      .overline {
        font-size: rem(15);
        line-height: rem(18);
        justify-content: flex-start;
      }

      .title {
        font-size: rem(30);
        line-height: rem(41);
        justify-content: flex-start;
      }

      .title2 span {
        font-size: 1.6rem !important;
        line-height: 1.5 !important;
      }

      .content {
        font-size: rem(12);
        line-height: 1.67;
        max-width: rem(606);
        justify-content: flex-start;

        text-align: left !important;
      }
    }

    .section-1 {
      .overline {
        font-size: rem(15);
        line-height: rem(50);
        margin-top: 32px;
        text-align: initial;
      }

      .title {
        font-size: rem(30);
        line-height: rem(35);
        // display: inline;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: initial;

        margin-bottom: 30px;
      }

      .content {
        p {
          font-size: rem(12);
          line-height: 1.67;
        }

        text-align: left;
      }
    }

    .section-2 {
      padding-bottom: 0;

      .overline {
        font-size: rem(15);
        line-height: rem(18);
        // text-align: center;
        text-align: initial;
      }

      .title {
        font-size: rem(30);
        line-height: rem(41);
        display: flex;
        flex-direction: row;
        justify-content: left;
        flex-wrap: wrap;
      }

      .race-career {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .content {
        font-size: rem(12);
        line-height: 1.67;
        text-align: left;

        padding-top: 15px;
        margin-bottom: 30px;

        width: auto;
        height: auto;
      }

      .super-trofeo-summary {
        width: 100%;
      }
    }

    .section-4 {
      padding: 5px;
      align-items: center;

      .footer-title {
        padding: 0 12px;
      }

      .one-goal,
      .one-vision {
        width: auto;
        height: auto;
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: rem(20);
        line-height: 1.2;
        padding: 0;
      }
    }
  }
}

// // Response
// @media (max-width: 1024px) {
//   .super-trofeo-page {
//     .info-block-section {
//       padding-top: 30px;
//       padding-bottom: 30px;

//       .info-section-tagline {
//         // padding-top: 30px;
//       }

//       .info-section-image {}

//       .info-section-description {
//         padding: 20px 0;
//       }
//     }
//   }
// }

// @media (max-width: 576px) {
//   .super-trofeo-page {
//     .timeline {
//       .timeline-item {
//         .content {
//           padding-bottom: 15px;
//         }
//       }
//     }

//     .slick-slide {
//       height: 55vh;
//     }
//   }
// }

.one-vision-section {
  @include flex-center();
  @include respond(desktopIpad) {
    min-height: rem(93);
  }
  padding: rem(15);
  .section-4 {
    align-items: center;
  }
  .footer-title {
    margin: 0 rem(40);
  }
}

.racing-gallery-card-slider {
  margin: 0 -15px rem(40) -15px;
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  &__item {
    height: rem(310);
    @include bgCover();
    margin: 0 rem(15);
    border-radius: rem(6);
    border: 5px solid $color-primary1;
  }
  .slick-dots {
    bottom: rem(-45);
  }
}

.about-race-section {
  background: radial-gradient(
    35.97% 50% at 50% 50%,
    rgba(62, 75, 96, 0.58) 0%,
    rgba(24, 48, 71, 0.58) 48.44%,
    rgba(2, 0, 0, 0.58) 100%
  );
  &__row {
    @include bgCover();
    align-items: center;
    @include respond(desktopIpad) {
      min-height: rem(516);
    }
    @include respond(mobile) {
      min-height: rem(400);
    }
    background-image: url("/images/about-race-bg.jpg");
  }
}
.about-race-card {
  max-width: rem(721);
  @include respond(desktopIpad) {
    margin: 0 auto;
  }
  @include respond(mobile) {
    text-align: left;
  }
  .heading-bright-1 {
    margin-top: rem(18);
  }
  .nikfaldo-desc {
    @include respond(desktopIpad) {
      margin-top: rem(40);
    }
    @include respond(mobile) {
      margin-top: rem(20);
    }
  }
}
.racing-banner-card {
  max-width: rem(721);

  @include respond(desktopIpad) {
    margin: 0 auto;
    text-align: center;
  }
  // @include flex-y-center();
  .heading-bright-1 {
    margin-top: rem(18);
  }
  .nikfaldo-desc {
    @include respond(desktopIpad) {
      margin-top: rem(40);
    }
    @include respond(mobile) {
      margin-top: rem(20);
    }
  }
}
