.checkout-table {
    margin-bottom: rem(60);
    table {
        width: 100%;
        text-align: left;
        thead {
            border-bottom: 1px solid $color-primary;
            th {
                font-family: 'Lato';
                font-weight: 300;
                font-size: rem(18);
                line-height: rem(22);
                color: $color-primary;
                padding: 20px 10px;
                text-transform: uppercase;
            }
        }
        tbody {
            tr {
                height: 180px;
                border-bottom: 1px solid $color-primary;
                @include respond(ipadMobile) {
                    height: 150px;
                }
                td {
                    padding: 20px 10px;
                }
                td:nth-child(3), td:nth-child(4) {
                    font-family: 'Lato';
                    font-weight: bold;
                    font-size: rem(28);
                    line-height: rem(34);
                    color: $color-primary;
                    @include respond(mediumMobile) {
                        font-size: rem(20);
                    }
                }
            }
        }
    }
}

.sub-total {
    font-family: 'Lato';
    font-weight: 300;
    font-size: rem(18);
    line-height: rem(22);
    color: $color-primary;
    text-transform: uppercase;
    @include respond(mediumMobile) {
        font-size: rem(14);
    }
    span {
        font-weight: 400;
        text-transform: none;
    }
}


.compare-whisky-table {
    display: flex;
    .cw-table-header {
        margin-right: 20px;
        background-color: $color-primary;
        padding: rem(35) rem(45);
        p {
            max-width: rem(180);
            text-transform: uppercase;
            text-align: left;
            background-color: $color-primary;
            color: $black;
            font-family: 'Alfa Slab One';
            font-size: rem(12);
            font-weight: 400;
            line-height: rem(16);
            padding: 0;
            margin-bottom: rem(31);
            display: block;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .cw-table {
        padding: 18px;
        display: block;
        width: 100%;
        background-color: $color-grey;
    }
    table {
        width: 100%;
    }
    tr, td {
        border: none;
    }
    tr {
        &:first-child {
            td {
                &::after {
                    content: "";
                    width: 4px;
                    height: 4px;
                    position: absolute;
                    right: -2.5px;
                    top: 0;
                    z-index: 1;
                    background-color: #E0E0E0;
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
        &:last-child {
            td {
                border-bottom: none;
                &::after {
                    content: "";
                    width: 4px;
                    height: 4px;
                    position: absolute;
                    right: -2.5px;
                    bottom: 0;
                    z-index: 1;
                    background-color: #E0E0E0;
                }
                &:first-child {
                    &::before {
                        display: none;
                    }
                }
                &:last-child {
                    &::before, &::after {
                        display: none;
                    }
                }
            }
        }
    }
    td {
        position: relative;
        background-color: $color-grey;
        color: $black;
        font-family: 'Roboto';
        font-size: rem(14);
        font-weight: 400;
        line-height: rem(16);
        padding: 15px;
        padding-left: 70px;
        text-align: left;
        border-bottom: 1px solid #E0E0E0;
        border-right: 1px solid #E0E0E0;
        &:first-child {
            &::before {
                content: "";
                width: 4px;
                height: 4px;
                position: absolute;
                left: 0;
                bottom: -2px;
                z-index: 1;
                background-color: #E0E0E0;
            }
        }
        &:last-child {
            border-right: none;
            &::before {
                content: "";
                width: 4px;
                height: 4px;
                position: absolute;
                right: 0;
                bottom: -2px;
                z-index: 1;
                background-color: #E0E0E0;
            }
        }

    }
}