.blackbull-twelve-section-wrapper {
  .falvour-wheels-section {
    @include respond(ipad) {
      padding: 4.25rem 0.625rem;
    }
  }
  .falvour-wheels-card {
    @include respond(ipad) {
      margin-top: 0;
    }
  }
  .nikfaldo-section-heading {
    color: $text-c3;
  }
  .add-to-card-nikfaldo__age {
    span {
      letter-spacing: 5px;
    }
  }
  .discover-video-card {
    .play {
      // display: none;
      img {
        width: rem(50);
        filter: grayscale(1);
        transform: translateX(1.3rem);
      }
    }
    &__image {
      &:first-child {
        display: flex;
        justify-content: center;
        @include respond(desktopIpad) {
          width: 10.357143rem;
        }
        @include respond(mobile) {
          width: 3.357143rem;
        }
      }
    }
    .discover-bg-logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      // z-index: -1;
      top: 50%;
      @include respond(desktopIpad) {
        width: rem(700);
      }
      @include respond(mobile) {
        width: rem(300);
      }
    }
  }
  .falvour-wheels-section {
    // @include respond(desktopIpad) {
    //   padding: 3.2142857143rem 2.1428571429rem;
    // }
    @include respond(mobile) {
      margin: 0;
      overflow: hidden;
    }
    &::after {
      @include respond(mobile) {
        display: none;
      }
    }
  }
  .falvour-wheels-card {
    position: relative;
    padding: 0 rem(25);
    align-items: flex-start;
    .nikfaldo-desc-2.lg {
      margin-bottom: rem(20);
    }
    &__bull {
      opacity: 0.1;
      position: absolute;
      width: 23.5rem;
      left: 50%;
      transform: translateX(-55%);
      @include respond(ipad) {
        display: none;
      }
    }
  }
  .falvour-wheels-section {
    .nikfaldo-desc-2 {
      opacity: 0.3;
    }
  }
  .scroll-stories-section__card {
    background-color: $black !important;
  }
  .product-gallery-section::after,
  .scroll-stories-section.first::after {
    display: none;
  }
  .scroll-stories-section__card {
    @include respond(ipadMobile) {
      background-color: transparent !important;
    }
  }
  .scroll-mobile-slider {
    @include respond(ipadMobile) {
      background-color: $black !important;
    }
  }
}
.crafted-section {
  @include bgCover();
  position: relative;
  @include respond(desktopIpad) {
    padding-top: 7.514286rem;
  }
  @include respond(mobile) {
    padding-top: 10rem;
    padding-bottom: 2.8571428571rem;
  }
  &__bottle {
    img {
      @include respond(mobile) {
        width: 9rem;
      }
      @include respond(desktopIpad) {
        width: rem(200);
      }
    }
  }
  .container {
    position: relative;
    z-index: 1;
  }
  .row {
    @include respond(desktopIpad) {
      justify-content: center;
      padding-top: rem(20);
      padding-bottom: rem(20);
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      background-color: rgba($color: $black, $alpha: 0.6);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.crafted-section-card {
  position: relative;
  z-index: 1;
  height: 100%;
  text-align: center;
  .nikfaldo-desc {
    max-width: rem(171);
    margin: rem(15) auto 0 auto;
    font-size: rem(20);
  }
  .nikfaldo-btn {
    margin-top: rem(30);
  }
  @include flex-column-center();
  align-items: center;
  @include respond(mobile) {
    margin-top: 1rem;
  }
  &__logo {
    @include respond(desktopIpad) {
      width: 24rem;
    }
    @include respond(mobile) {
      display: none;
    }
  }
  .add-to-card-nikfaldo__age {
    span {
      color: $text-d7;
    }
  }
  &__bottle {
    text-align: center;
  }
  &__craftedfrom {
    text-align: center;
    color: #e7c983 !important;
    span {
      background-color: rgba($color: #d9d9d9, $alpha: 0.1);
      @include flex-center();
      line-height: 1;
      padding: 5px;
      margin-bottom: 4px;
    }
  }
  .add-to-card-nikfaldo__age,
  &__craftedfrom {
    margin-top: 1rem;
  }
}

.product-description-twelve-section {
  .row {
    [class*="col-"] {
      &:first-child {
        @include respond(desktop) {
          padding-right: 0;
        }
      }
      // &:last-child {
      //   @include respond(desktop) {
      //     padding-left: 0;
      //   }
      // }
    }
  }
}

.product-description-twelve-section {
  .product-description-card__details {
    display: flex;
    align-items: center;
  }

  .nikfaldo-section-heading,
  .nikfaldo-desc-2 {
    color: #e8e7e7;
  }
  .nikfaldo-desc {
    text-align: left;
    z-index: 1;
    color: $text-d7;
    a,
    span {
      cursor: pointer;
      display: block;
      color: $color-primary !important;
    }
  }
  .scroll-main-description-bottle {
    transform: translateX(-59%) translateY(-50%);
    img {
      @include respond(desktopIpad) {
        width: 8.857143rem !important;
      }
    }
  }
  &.tastingnotes {
    @include bgCover();
    padding: rem(20);
    .product-description-card__details {
      flex-direction: column !important;
      background-image: none;
      align-items: flex-start;
      background-color: rgba($color: $black, $alpha: 0.4);
    }
  }
}
.product-description-wrapper {
  .product-description-twelve-section {
    &:not(:first-child) {
      margin-top: 1.43rem;
    }
  }
}

.nikfaldo-desc {
  a {
    color: $color-primary;
  }
}

.scroll-stories-section__cardwrapper {
  .nikfaldo-section-heading {
    color: $color-primary;
  }
}

.kyloe-banner-wrapper {
  position: relative;

  @include respond(desktopIpad) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right top;
    padding: rem(30) 0;
    min-height: rem(520);
    background-image: url("/images/kyloe-regular-banner.jpg");
  }

  .desktopAddToCart {
    position: absolute;
    right: 0;
    bottom: 6.5rem;
    transform: translateX(2.785714rem);
  }
  .mobileAddToCart {
    @include respond(desktopIpad) {
      display: none !important;
    }
    @include respond(mobile) {
      margin: rem(20) 0;
    }
  }
  .nikfaldo-btn {
    background: linear-gradient(
      92.29deg,
      #a86e10 0%,
      #cd8819 29.08%,
      #d67d1b 53.94%,
      #943502 100%
    );
    color: $white;
  }
  .row {
    justify-content: center;
  }
}
.kyloe-peated-edition-card {
  width: 100%;
  text-align: right;
}
.kyloe-peated-edition-card-wrapper {
  @include respond(desktopIpad) {
    align-items: flex-end !important;
  }
}

.kyloe-edition-section-bottle-wrapper {
  .row {
    @include respond(mobile) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.crafted-section__logo {
  position: relative;
  z-index: 1;
  @include flex-column();
  @include respond(desktopIpad) {
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  img {
    &:first-child {
      @include respond(desktopIpad) {
        width: rem(214);
      }
      @include respond(mobile) {
        width: 7.375rem;
      }
    }
    &:last-child {
      @include respond(desktopIpad) {
        width: 9.0625rem;
      }
      @include respond(mobile) {
        width: 6.0625rem;
      }
    }
  }
}
.crafted-section__mainbottle {
  position: relative;
  z-index: 1;
  img {
    width: rem(168);
  }
}

.crafted-section-bull {
  display: inline-block;
  position: relative;
  &__image {
    position: absolute;
    right: 0;
    width: rem(176) !important;
    @include respond(desktopIpad) {
      top: 15.475rem;
      transform: translateX(3.4125rem) !important;
    }
    @include respond(mobile) {
      transform: translateX(2.6rem) !important;
      top: 10.9rem;
    }
  }
}

.bbtwelve-new-bottle {
  @include respond(desktop) {
    display: none;
  }
  @include respond(ipadMobile) {
    width: 7.714286rem !important;
  }
}

.product-description-card__years {
  @include respond(ipadMobile) {
    text-align: center;
  }
}
.crafted-section-bull {
  @include respond(mobile) {
    width: rem(120);
  }
}
.crafted-section {
  @include respond(mobile) {
    padding-top: 0;
    background-image: url("/images/crafted-section-bg.jpg");
    @include bgCover();
  }
  &__row {
    @include respond(mobile) {
      padding: rem(100) 0 rem(50) 0;
    }
  }
}

.crafted-section__mainbottle {
  img {
    filter: drop-shadow(2px 4px 6px #f1c576);
  }
}

.kyloe-section-wrapper .chartjs-render-monitor {
  @include respond(ipad) {
    transform: scale(1.7);
  }
}
.explore-18-years,
.explore-30-years {
  @include respond(desktopIpad) {
    width: 8rem;
  }
  @include respond(mobile) {
    width: 4.5rem !important;
  }
}
.explore-bbtwelve-card__bottle--shadow {
  @include respond(ipad) {
    width: 9rem;
    margin: 0 auto;
  }
}
.discover-video-card__image {
  img {
    position: relative;
    z-index: 2;
  }
}
.kyloe-section-wrapper.kyloe-peated-wrapper {
  .productGallery .slick-arrow.slick-next {
    @include respond(mobile) {
      transform: translate(-40px, -50%) rotate(0deg) !important;
    }
  }
  .product-kyloe-section .slick-next {
    @include respond(mobile) {
      left: inherit !important;
    }
  }
}
.discover-video-card__image--video {
  z-index: 2;
}
.nikfaldo-main-section {
  .nikfaldo-section-heading {
    background: -webkit-radial-gradient(
      0 50%,
      100% 250.08%,
      #ffd59f 0,
      #ca974a 36.99%,
      #ffcfa4 67.58%,
      #d59255 100%,
      #e19e61 0
    );
    background: radial-gradient(
      100% 250.08% at 0 50%,
      #ffd59f 0,
      #ca974a 36.99%,
      #ffcfa4 67.58%,
      #d59255 100%,
      #e19e61 0
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-transform: uppercase;
  }
}
.explore-range-card__image {
  &:not(.active) {
    .activeLink {
      display: none;
    }
  }
  &.active {
    position: relative;
    .activeLink {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

.main-50-years, .main-55-years{
  .add-to-card-nikfaldo__image{
    background: none !important;
  }
}