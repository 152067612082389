.accordion {
    button[variant="link"] {
        color: $color-grey;
        background: transparent;
        box-shadow: none;
        border: none;
        display: block;
        width: 100%;
        text-align: left;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: rem(10);
            height: rem(12);
            top: 50%;
            transform: translateY(-50%);
            background-image: url("/svg/dropdown-arrow.svg");
            @include bgContain();

            @include respond(desktop) {
                right: rem(30);
            }
            @include respond(ipadMobile) {
                right: 0;
            }
        }
    }
}
.faqs-accordian {
    text-align: left;
    .faqs-accordian-wrapper {
        border-bottom: 1px solid $color-primary;
        &.open {
            button {
                &::after {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
            border-bottom: 2px solid $color-primary;
        }
    }

    .accordion {
        button {
            @include respond(desktop) {
                font-size: rem(18);
                padding: rem(33) rem(33) rem(33) 0;
            }
            @include respond(ipadMobile) {
                padding: rem(20) rem(20) rem(20) 0;
            }
        }
    }
    &__desc {
        font-weight: 300;
        color: $color-grey;

        @include respond(desktop) {
            font-size: rem(18);
            line-height: 26px;
            padding-right: rem(33);
        }
        @include respond(ipadMobile) {
            line-height: 22px;
            padding-right: rem(20);
        }
    }
}

.your-cart-accordian {
    text-align: left;
    margin-bottom: rem(50);

    .your-cart-wrapper__heading {
        margin-bottom: 0 !important;
        padding-right: rem(26);
    }
    .your-cart-card {
        margin-top: rem(25);
    }
    .your-cart-card__total .row [class*=col-]{
        align-items: flex-end;
    }
}
.you-cart-section-2 {
    &__row {
        @include respond(mobile) {
            flex-direction: column-reverse;
        }
    }
}
