.kyloe-peated-edition-card-wrapper,
.kyloe-peated-edition-locator-card,
.product-kyloe-description-card-wrapper,
.wheels-edition-card-wrapper {
  @include flex-column();
  justify-content: center;
  align-items: flex-start;
  @include respond(desktopIpad) {
    height: 100%;
  }
}
.kyloe-section-wrapper {
  .crafted-section__bottle {
    // padding-bottom: rem(50);
    img {
      @include respond(desktopIpad) {
        width: 8.5rem;
      }
    }
  }
  .nikfaldo-section-heading {
    &.lg {
      @include respond(desktop) {
        font-size: rem(35);
      }
    }
  }
}
.kyloe-peated-edition-card-wrapper {
  @include respond(desktopIpad) {
    padding-right: rem(13);
  }
  &__kyloe {
    @include respond(desktopIpad) {
      margin: rem(10) 0;
    }
  }
  @include respond(mobile) {
    align-items: center;
  }
  &__logo {
    @include respond(mobile) {
      @include flex-column();
    }
    img {
      &:first-child {
        @include respond(desktopIpad) {
          width: rem(230);
        }
        @include respond(mobile) {
          width: rem(135);
        }
      }
    }
  }
}
.kyloe-edition-section {
  @include bgCover();
  @include respond(desktopIpad) {
    padding-bottom: 0;
  }
}
.kyloe-peated-edition-card {
  @include respond(mobile) {
    @include flex-column();
    align-items: center;
  }
  .nikfaldo-desc-2 {
    color: #dfddd7;
  }
  img {
    @include respond(mobile) {
      width: rem(103);
    }
  }
  &__mobilelogo {
    @include respond(mobile) {
      width: rem(250);
    }
  }
  .nikfaldo-section-heading {
    margin-bottom: rem(10);
  }
  .nikfaldo-desc {
    @include respond(mobile) {
      max-width: rem(217);
      text-align: center;
      line-height: 1.6;
    }
    a {
      display: block;
      color: $color-primary !important;
    }
  }
}
.kyloe-peated-edition-locator-card {
  @include respond(desktopIpad) {
    padding-left: rem(13);
  }
  &__mobiledesc {
    @include respond(mobile) {
      margin-left: rem(20);
      padding-left: rem(20);
      border-left: 1px solid #525252;
    }
  }
  &__btn {
    @include respond(mobile) {
      @include flex-x-center();
      width: 100%;
      margin-top: rem(20);
    }
  }
  .nikfaldo-desc {
    max-width: rem(219);
    a {
      font-family: "Bellota Text", cursive;
      font-weight: 400;
      margin-bottom: 0;
      display: block;
    }
  }
  .nikfaldo-desc-2,
  .nikfaldo-section-heading {
    color: #e8e7e7;
  }
  .nikfaldo-section-heading {
    margin-top: rem(10);
    font-size: rem(22);
  }
  &__image {
    width: rem(50);
  }
  .btn-locate-store {
    @include respond(desktopIpad) {
      margin-top: rem(40);
    }
  }
}

.btn-locate-store {
  border-radius: 5px;
  border: 1px solid #bd2402;
  @include respond(desktopIpad) {
    padding: 0.36rem 0.625rem;
    min-width: 6.875rem;
  }
  @include respond(mobile) {
    min-width: rem(107);
    padding: rem(11);
  }
  @include flex-x-center();
  &:hover {
    color: $color-primary;
    text-decoration: none;
  }
  span {
    line-height: 1.3;
    letter-spacing: 0.09em;
    display: inline-block;
    margin-left: rem(10);
  }
}
.product-kyloe-description-card {
  @include respond(desktopIpad) {
    // width: 100%;
    margin-top: rem(55);
  }
  &__body {
    &:not(:first-child) {
      @include respond(desktopIpad) {
        margin-top: rem(30);
      }
    }
    @include respond(mobile) {
      margin-top: rem(20);
    }
  }
}
.kyloe-edition-section {
  .row {
    [class*="col-"] {
      &:first-child {
        @include respond(desktopIpad) {
          padding-right: 0;
        }
      }
      &:last-child {
        @include respond(desktopIpad) {
          padding-left: 0;
        }
      }
    }
  }
}

.product-kyloe-description-card-wrapper {
  @include respond(desktopIpad) {
    justify-content: flex-start;
    min-height: rem(513);
  }

  .nikfaldo-desc-2 {
    @include respond(mobile) {
      font-size: rem(20);
      font-weight: 700;
    }
  }
  &.one {
    position: relative;
    @include respond(desktopIpad) {
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    @include respond(desktop) {
      padding-right: rem(189);
    }
    @include respond(ipad) {
      padding-right: 4.8125rem;
    }
    .product-kyloe-description-card {
      @include respond(desktopIpad) {
        width: 100%;
        text-align: right;
      }
      .color-variant {
        margin-left: auto;
      }
    }
    .nikfaldo-desc,
    .nikfaldo-desc-2 {
      font-weight: 700;
      @include respond(desktopIpad) {
        color: $color-primary1;
      }
      @include respond(mobile) {
        color: $white;
      }
    }
    .nikfaldo-section-heading {
      background: radial-gradient(
        73.45% 53.65% at 54.15% 46.35%,
        #ffe3a7 0%,
        #fbf3dd 26.56%,
        #f6d59a 73.44%,
        #f4eed2 93.23%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  &:not(.one) {
    @include respond(desktopIpad) {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    @include respond(desktop) {
      padding-left: rem(189);
    }
    @include respond(ipad) {
      padding-left: 4.8125rem;
    }
    .nikfaldo-section-heading {
      background: radial-gradient(
        100% 250.08% at 0% 50%,
        #ffd59f 0%,
        #ca974a 36.99%,
        #ffcfa4 67.58%,
        #d59255 100%,
        #e19e61 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .nikfaldo-desc,
    .nikfaldo-desc-2 {
      color: #dfddd7;
      max-width: rem(300);
      font-weight: 700;
    }
  }
  @include respond(mobile) {
    align-items: flex-start !important;
    text-align: left;
  }
}

.product-kyloe-section {
  position: relative;

  @include bgCover();
  @include respond(desktop) {
    padding: rem(70) 0;
  }
  @include respond(ipadMobile) {
    padding: rem(50) 0;
  }
  .crafted-section__bottle {
    padding-bottom: 0 !important;
    @include respond(desktopIpad) {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    @include respond(mobile) {
      position: absolute;
      transform: translateY(-50%);
      width: rem(103);
      left: 2.9375rem;
      opacity: 1;
      top: 57%;
    }
    img {
      @include respond(mobile) {
        width: rem(400);
      }
    }
  }
  &.gallery {
    @include respond(desktopIpad) {
      padding-bottom: 9rem;
    }
    @include respond(mobile) {
      padding-bottom: 9rem;
    }
  }
}

.kyloe-tales-section {
  // &::after,
  // &:before {
  //   content: "";
  //   position: absolute;
  //   width: rem(53);
  //   height: 26.5rem;
  //   top: 50%;
  //   z-index: 1;
  //   transform: translateY(-44%);
  // }
  // &::before {
  //   background-image: linear-gradient(
  //     91deg,
  //     #8d6427 1%,
  //     #a7845e 22%,
  //     rgba(255, 254, 250, 0) 96%
  //   );
  //   left: 0;
  // }
  // &::after {
  //   right: 0;
  // }
  .productGallery__image {
    transition: 0.5s ease-in-out transform;
    will-change: transform;
    div {
      @include bgCover();
      @include respond(desktopIpad) {
        height: 37.857143rem;
      }
      @include respond(mobile) {
        height: 20rem;
      }
    }
  }
  .slick-slide {
    &:not(.slick-active) {
      .productGallery__image {
        transform: scale(0.7);
      }
    }
  }
  .productKyloeGallery {
    @include respond(desktop) {
      width: 80%;
      margin: 0 auto;
    }
    .slick-list {
      overflow: inherit !important;
    }
  }
  .slick-prev,
  .slick-next {
    width: rem(40) !important;
    height: rem(40) !important;
    left: 50%;
    bottom: 0;
    top: inherit;
    border-color: $color-primary !important;
  }
  .slick-arrow {
    background-image: url("/svg/slider-arrow-primary.svg") !important;
  }
  .slick-next {
    @include respond(desktopIpad) {
      transform: translate(18px, 90px) !important;
    }
    @include respond(mobile) {
      transform: translate(18px, 70px) !important;
    }
  }
  .slick-prev {
    @include respond(desktopIpad) {
      transform: translate(-46px, 90px) rotate(180deg) !important;
    }
    @include respond(mobile) {
      transform: translate(-46px, 70px) rotate(180deg) !important;
    }
  }
}

//
.wheels-edition-section {
  background: url("/images/wheel-bg.png");
  @include bgCover();
  &__row {
    background: rgba(56, 40, 15, 0.7);
    border: 1px solid rgba(249, 196, 103, 0.3);
    padding: rem(30) 0;
    @include respond(desktopIpad) {
      border-radius: 9px;
    }
    @include respond(mobile) {
      margin: 0 !important;
      border-radius: 14px;
    }
  }
  .crafted-section__bottle {
    img {
      @include respond(desktop) {
        width: rem(700);
      }
    }
  }
}
.wheels-edition-card {
  // border: 1px solid $color-primary1;
  // border-radius: 5px;
  // padding: 1.3857142857rem;

  @include respond(desktopIpad) {
    max-width: rem(222);
  }
  @include respond(mobile) {
    max-width: rem(225);
    margin: rem(40) auto 0 auto;
    text-align: center;
  }
  p {
    margin-top: 0.7rem;
  }
  h2 {
    @include respond(desktopIpad) {
      font-size: rem(24) !important;
    }
    @include respond(mobile) {
      max-width: rem(200);
      line-height: 1.4;
      margin: 0 auto;
      font-size: rem(16) !important;
    }
  }
  h2,
  p {
    color: $color-primary1;
  }
}
.you-may-like-card {
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.31);
  border-radius: rem(14);
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  padding: 1.375rem 2.1875rem;
  max-width: rem(436);
  margin: 0 auto;
  @include respond(desktop) {
    min-height: rem(563);
    background-size: 112%;
  }
  @include respond(ipadMobile) {
    background-size: auto;
    min-height: 34.1875rem;
  }
  img {
    width: rem(110);
    position: relative;
    z-index: 1;
  }
  h3 {
    color: $white;
    text-transform: uppercase;
    margin-top: 3.1875rem;
    letter-spacing: 0.15em;
  }
  p {
    margin-top: rem(10);
    position: relative;
  }
  &.bg-dark-green {
    @include respond(desktopIpad) {
      background-size: rem(358) rem(216);
    }
    @include respond(mobile) {
      background-size: rem(308) rem(166);
    }
    background-image: url("/images/you-may-like-kyloe.png");
  }
  &.light-brown-bg {
    border: 1px solid rgba(249, 196, 103, 0.3);
    background-color: transparent;
    background-image: url("/images/you-may-like-1.png");
  }
  &.bg-dark-brown {
    @include respond(desktopIpad) {
      background-size: rem(412) rem(292);
    }
    @include respond(mobile) {
      background-size: rem(322) rem(202);
      margin-top: rem(30);
    }

    background-image: url("/images/you-may-like-peated.png");
  }
}
.bg-dark-green {
  background-color: #0c1812;
}

.bg-dark-brown {
  background-color: #1e170c;
}

.nikfaldo-section-heading {
  &.xl {
    @include respond(desktopIpad) {
      font-size: rem(60);
    }
    @include respond(mobile) {
      font-size: rem(40);
    }
  }
}

.kyloe-edition-section-bottle-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @include respond(mobile) {
    background-position: right top;
  }
  @include respond(desktopIpad) {
    background-position: center;
    background-image: url("/images/kyloe-desc-bg.jpg");
  }
  // &::before {
  //   @include respond(desktopIpad) {
  //     content: "";
  //     position: absolute;
  //     width: 50%;
  //     left: 0;
  //     height: 100%;
  //     top: 0;
  //     background-color: rgba($color: $black, $alpha: 0.5);
  //   }
  // }
  @include respond(desktopIpad) {
    min-height: rem(520);
  }
}

.wheels-edition-section-wrapper {
  .nikfaldo-section-heading {
    @include respond(desktop) {
      margin-bottom: 2.375rem;
    }
    @include respond(ipadMobile) {
      margin-bottom: 3.125rem;
    }
  }
}
.regular-banner-description-wrapper {
  position: relative;
  @include respond(mobile) {
    padding-top: 6.375rem;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: calc(100% + 10.5rem);
    background-image: url("/images/kyloe-regular-bg.jpg");
    @include bgCover();
    z-index: -1;
    top: 0;
  }
  .bottle-section {
    @include respond(mobile) {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right top;
      min-height: rem(724);
      background-image: url("/images/kyloe-regular-banner-sm.jpg");
    }
    .container {
      // @include respond(mobile) {
      //   padding: 0;
      // }
    }
  }
}

.yellow-gradient {
  background: radial-gradient(
    100% 250.08% at 0% 50%,
    #ffd59f 0%,
    #ca974a 36.99%,
    #ffcfa4 67.58%,
    #d59255 100%,
    #e19e61 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.yellow-light-gradient {
  background: radial-gradient(
      73.45% 53.65% at 54.15% 46.35%,
      #ffe3a7 0%,
      #fbf3dd 26.56%,
      #f6d59a 73.44%,
      #f4eed2 93.23%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.product-kyloe-section-gallery {
  .legend-tales-section {
    background-image: url("/images/gallery-bg.jpg");
  }
  &::after {
    display: none;
  }
}

.kyloe-legend-tales-section-wrapper {
  .legend-tales-section {
    background: linear-gradient(
      180deg,
      rgba(83, 64, 43, 0.75) 0%,
      rgba(45, 34, 23, 0.3) 140.44%
    );
    border: 1px solid rgba(249, 196, 103, 0.3);
    border-radius: 9px;
    @include respond(mobile) {
      border-radius: 14px;
      padding: 1.875rem 1.625rem;
    }
  }
  .scroll-stories-card {
    @include respond(desktopIpad) {
      height: rem(262);
    }
    @include respond(mobile) {
      height: rem(109);
    }
  }
}

.light-brown-bg {
  background-color: #281c09;
}

.kyloe-edition-section {
  &.description {
    @include respond(mobile) {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom right;
      position: relative;
      background-image: url("https://blackbullwebsitestaging.s3.ap-south-1.amazonaws.com/Group_3016934_36a8dbaf17.jpg") !important;
    }
  }
}
.color-variant__child {
  @include respond(mobile) {
    margin: 0 rem(2);
  }
}

.mobileProductDesc {
  @include respond(mobile) {
    display: flex;
    align-items: center;
  }
  .nikfaldo-desc {
    @include respond(mobile) {
      padding-left: rem(5);
    }
  }
}

.product-kyloe-section {
  .slick-prev,
  .slick-next {
    @include respond(mobile) {
      width: rem(41) !important;
      height: rem(41) !important;
      border: 1px solid #545454 !important;
      background-size: rem(25) rem(26) !important;
      bottom: -7.625rem !important;
      top: inherit !important;
    }
  }
  .slick-prev {
    @include respond(mobile) {
      left: 8%;
    }
  }
  .slick-next {
    @include respond(mobile) {
      left: 55%;
    }
  }
  &.product-gallery-section {
    .legend-tales-section {
      @include respond(mobile) {
        padding-bottom: rem(100);
        border-radius: 14px;
        border: 1px solid rgba(249, 196, 103, 0.3);
      }
    }
  }
}

.you-may-like-section {
  @include respond(desktopIpad) {
    padding-top: rem(15);
  }
  @include respond(mobile) {
    padding-top: 0;
  }
}

.kyloe-peated-wrapper {
  .kyloe-banner-wrapper {
    @include respond(desktopIpad) {
      background-image: url("/images/kyloe-peated-bg.jpg");
    }
  }
  .regular-banner-description-wrapper .bottle-section {
    @include respond(mobile) {
      background-image: url("https://blackbullwebsitestaging.s3.ap-south-1.amazonaws.com/Group_3016944_5335bea2e0.png");
    }
  }
  .regular-banner-description-wrapper::before {
    background: linear-gradient(180deg, #321e1a 0%, #2d1815 99.48%);
  }
  .kyloe-edition-section-bottle-wrapper {
    background-image: url("/images/kyloe-peated-desc-bg.png");
  }

  .wheels-edition-section {
    background: linear-gradient(
      91.19deg,
      #000000 47.08%,
      rgba(0, 0, 0, 0) 99.16%
    );
  }
  .wheels-edition-section__row {
    background: rgba(92, 49, 38, 0.6);
    border: 1px solid rgba(249, 138, 103, 0.3);
  }
  .wheels-edition-card h2,
  .wheels-edition-card p {
    background: radial-gradient(
        73.45% 53.65% at 54.15% 46.35%,
        #ffe3a7 0%,
        #fbf3dd 26.56%,
        #f6d59a 73.44%,
        #f4eed2 93.23%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .product-gallery-section {
    background-color: $black;
    @include respond(desktopIpad) {
      padding-top: 4.375rem !important;
    }
  }
  .product-kyloe-section-gallery {
    .legend-tales-section {
      // background-image: url("/images/kyloe-gallery-bg.png");
      background: radial-gradient(
        80.08% 70.56% at 48.5% 49.13%,
        #434d56 0%,
        #434d56 38.02%,
        #2c3439 64.58%,
        #292b2d 85.42%
      );
      border: 1px solid rgba(122, 134, 142, 0.3);
      border-radius: 9px;
    }
  }
  .kyloe-legend-tales-section-wrapper {
    background: linear-gradient(180deg, #1b1915 0%, #1d1817 113.11%);
    .legend-tales-section {
      // background: transparent;
      border: none;
    }
  }
  .kyloe-peated-wrapper
    .kyloe-legend-tales-section-wrapper
    .legend-tales-section {
    padding-top: 0;
    padding-bottom: 0;
  }
  .you-may-like-section {
    @include respond(desktopIpad) {
      padding-top: 4.375rem;
    }
    @include respond(mobile) {
      padding-top: 3.125rem;
    }
  }

  .you-may-like-card.light-brown-bg {
    position: relative;
    background: rgba(69, 77, 85, 0.3);
    box-shadow: 4px 4px 5px rgb(0 0 0 / 31%);
    border-radius: 14px;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 73%;
      background-image: url("/images/kyloe-card-bg.png");
      @include bgCover();
      top: 0;
      left: 0;
      opacity: 0.6;
    }
  }
}
