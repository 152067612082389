.header-section {
  @include flex-column();
  align-items: center;
  position: relative;
  z-index: 5;
  &__top {
    height: rem(52);
    width: 100%;
    background-color: $color-secondary;
  }
  @include respond(ipadMobile) {
    position: fixed;
    top: 0;
    width: 100%;
  }
  &.fixed {
    .header-nav {
      @include respond(desktop) {
        position: fixed;
        max-width: 80vw;
        width: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      @media (min-width: 1280px) {
        max-width: 75vw;
      }
    }
  }
  .header-nav-wrapper {
    width: calc(100% - 30px);
    position: absolute;
    // max-width: 922px;
    margin: 0 auto;

    top: rem(12);
    // @media only screen and (max-width: 1367px) {
    // }
    // @media only screen and (min-width: 1440px) {
    //     top: 13px;
    // }

    @include respond(largedesktop) {
      top: rem(15);
    }
    @include respond(extralargedesktop) {
      top: 1.2rem;
    }

    @include respond(mobile) {
      top: 0 !important;
    }
  }
  .header-nav {
    padding: 0 rem(19) 0 1.5rem;
    background: linear-gradient(93.99deg, #0D0D0D 8.91%, #131313 29.71%, #282828 54.34%, #141414 78.11%, #0E0E0E 99.4%);
    @include flex-y-center();
    justify-content: space-between;
    @include respond(ipadMobile) {
      padding: 0.8rem 1.7142857143rem 0.8571428571rem;
    }
    .site-logo {
      // @include respond(ipadMobile) {
      //   width: 3rem;
      // }
      // width: 5rem;
    }
    &__inner {
      display: flex;
      @include respond(ipadMobile) {
        display: none;
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: $color-secondary;
        left: 0;
        top: 0;
        padding: 20px 48px;
      }
      > ul {
        li {
          &:last-child {
            .sub-nav {
              transform: translateY(8.5rem) translateX(-67%);
            }
          }
        }
      }
    }

    ul {
      &:not(.sub-nav) {
        @include flex-y-center();
        font-family: $font-alfa;
        li {
          &::after {
            content: "";
            position: relative;
            border-bottom: 4px solid transparent;
            display: block;
          }
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 4px;
            background-color: $color-primary;
            left: 0;
            bottom: 0;
            visibility: hidden;
          }
          &.active,
          &:hover {
            &::before {
              visibility: visible;
            }
          }
        }
      }
      @include respond(ipadMobile) {
        flex-direction: column;
        align-items: flex-start !important;
      }

      li {
        position: relative;
        &:not(:last-child) {
          @include respond(ipadMobile) {
            margin-bottom: rem(50);
          }
        }
        &:hover {
          .sub-nav {
            visibility: visible;
            opacity: 1;
          }
          img {
            transform: rotate(180deg);
          }
        }
        list-style-type: none;
        &.expanded-dropdown {
          position: unset;
          &::before {
            display: none;
          }
          &::after {
            content: "";
            position: relative;
            border-bottom: 4px solid $color-primary;
            width: 100%;
            display: block;
            bottom: 1px;
            left: 0;
            visibility: hidden;
          }
          &.active,
          &:hover {
            &::after {
              visibility: visible;
            }
          }
        }
      }
    }
  }
}
.header-nav__link {
  display: flex;
  color: $white;
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  font-family: "libre_baskervillebold";
  &:hover {
    text-decoration: none;
    color: $white;
  }
  img {
    transition: all 0.4s ease;
  }

  @include respond(desktop) {
    white-space: nowrap;
    font-size: 0.7rem;
    padding: 2.5rem .7rem 2.5rem .7rem;
  }
  @include respond(ipadMobile) {
    padding-bottom: rem(15);
  }
}
.sub-nav {
  li {
    white-space: nowrap;
    &::before {
      display: none;
    }
  }
  @include respond(desktop) {
    position: absolute;
    bottom: -0.5px;
    left: 50%;
    @include flex-column();
    transform: translateY(100%) translateX(-50%);
    padding: rem(31) rem(39);
    padding-bottom: rem(31) !important;
    background-color: rgba($color: $color-dark, $alpha: 0.8);
  }

  @include respond(ipadMobile) {
    display: none;
  }
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease-in-out visibility, 0.3s ease-in-out opacity;
  will-change: visibility, opacity;

  li {
    a{
      font-size: 0.7rem;
      text-transform: uppercase;
    }
    &:not(:last-child) {
      a {
        display: flex;
        position: relative;
        margin-bottom: rem(13.3);
        padding-bottom: rem(13.3);
        padding-right: rem(10);
        &::after {
          @include borderLine();
        }
      }
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }

    text-transform: capitalize;
    color: $color-light;
    font-family: $font-lato !important;
    &:hover {
      color: $color-light;
    }
  }
}
.mobile-nav {
  position: fixed;
  left: 0;
  width: 100%;
  // top: rem(52);
  // height: calc(100vh - 3.7142857143rem);
  height: 100vh;
  top: 0;
  background-color: $color-secondary;
  transform: translateX(100%);
  padding: rem(19) rem(45);
  visibility: hidden;
  opacity: 0;
  z-index: 15;
  transition: transform 0.5s $transition-timing, opacity 0.5s $transition-timing,
    visibility 0.5s $transition-timing;
  will-change: transform, opacity, visibility;
  &.in-active {
    visibility: hidden;
    opacity: 0;
  }
  &.active {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
  }
  .site-logo {
    width: 3rem;
  }
  &__header {
    @include flex-between();
    margin-bottom: rem(40);
  }
  &__link {
    display: block;
    margin-bottom: rem(55);
  }
  &__body {
    height: calc(100vh - 84px);
    overflow-y: auto;
  }
  &__accordian {
    [type="button"] {
      background: transparent;
      box-shadow: none;
      outline: 0;
      margin: rem(26) 0 !important;
      padding: 0;
      display: block;
      border: none !important;

      &:not(:first-child) {
        margin-top: rem(55) !important;
      }

      // ~ div {
      //      margin-top: rem(27);
      // }
    }
    .text-up {
      display: inline-block;
      img {
        width: 10px;
      }
    }
    ul {
      @include flex-column();
      li {
        position: relative;
        &:not(:last-child) {
          margin-bottom: rem(20);
          padding-bottom: rem(20);
          &::after {
            @include borderLine();
          }
        }
      }
    }
  }
}
.text-up {
  cursor: pointer;
  font-family: $font-alfa;
  color: $color-light;
  font-size: rem(14);
  line-height: rem(14);
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
    color: $color-light;
  }
}

.link-text {
  font-family: $font-lato;
  font-weight: 400;
  color: $color-light;

  &:hover {
    text-decoration: none;
    color: $color-light;
  }
}

.hamburger-menu {
  display: none;
  @include respond(ipadMobile) {
    display: block;
    cursor: pointer;
  }
}

.close-menu {
  cursor: pointer;
}

.productMenuCardWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include respond(ipadMobile) {
    border-bottom: rem(1) solid $color-primary;
  }
}
.product-sub-nav {
  @include respond(desktop) {
    flex-direction: row;
    // width: rem(951);
    width: 100%;
    left: 50%;
    bottom: rem(1);
    padding-left: rem(58);
    flex-wrap: wrap;
    padding-bottom: 0;
    transform: translateY(100%) translateX(-50%) !important;
    height: rem(500);
    overflow-y: auto;
  }

  @include respond(mobile) {
    max-height: rem(746);
    overflow-y: auto;
  }
  @include respond(ipadMobile) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: rem(1) solid $color-primary;
  }
}
.product-sub-menu-card {
  @include respond(ipadMobile) {
    padding-bottom: rem(40);
  }

  @include respond(mobile) {
    width: calc(50% - 20px);
  }
  @include respond(ipad) {
    width: calc(100% / 3 - 20px);
  }
  &:first-child {
    @include respond(desktop) {
      padding-left: 0;
    }
  }
  &:nth-child(-n + 3) {
    p {
      @include respond(desktop) {
        transform: translateY(20px);
      }
    }
  }
  &:nth-child(n + 4) {
    z-index: 0;
    a {
      @include respond(desktop) {
        transform: translateY(-50px);
      }
    }
    p {
      @include respond(desktop) {
        bottom: rem(30);
      }
    }
  }
  @include respond(desktop) {
    padding-right: rem(30);
  }
  // @media (min-width:1440px) and (max-width: 1600px) {
  //     padding-right:rem(30);
  // }
  &:nth-child(4) {
    a {
      @include respond(desktop) {
        // padding-left: rem(180) !important;
      }
    }
  }
  a {
    position: relative;
    margin: 0 !important;
    padding-right: 0 !important;
    padding: 0 !important;
    font-family: $font-alfa !important;
    display: flex;

    @include respond(desktop) {
      font-size: rem(18);
      line-height: rem(22);
      padding-left: rem(100) !important;
    }
    &::after {
      display: none;
    }
    p {
      align-self: baseline;
      margin-bottom: 0;
      width: 30%;
      display: inline-block;
      padding-right: rem(10);
      color: $color-light;
      position: absolute;

      @media only screen and (min-width: 450px) and (max-width: 560px) {
        padding-right: 1.714286rem;
      }
      @include respond(ipad) {
        width: 35%;
      }
      @include respond(desktop) {
        right: rem(94);
      }
      @include respond(ipadMobile) {
        font-size: rem(10);
        top: rem(25);
        line-height: 1.3;
      }
    }
  }
  img {
    transform: rotate(0) !important;
    width: rem(80);
    @include respond(ipadMobile) {
      margin-left: 27%;
    }
  }
}
.checkout-header {
  display: flex;
  align-items: baseline;
  @include respond(mediumMobile) {
    display: flex;
    margin-bottom: rem(40) !important;
    .section-heading-2.mb-0 {
      font-size: rem(18);
      font-weight: 400;
      line-height: rem(26);
      margin-bottom: 0rem !important;
    }
    .clear-all {
      font-size: rem(12);
      font-weight: 300;
      line-height: rem(18);
    }
  }
}
.site-logo-wrapper {
  span {
    display: none;
  }
  @include respond(desktop) {
    // margin-right: calc(3rem + 1px);
  }
}

// .new-inside-header__menu{
//   li{
//     &:nth-child(3){

//     }
//   }
// }