.card-wrapper {
    &.verdict {
        &.no-btns {
            .rotate-card-two__body {
                @include respond(ipadMobile) {
                    min-height: 350px;
                }
            }
        }
        .rotate-card-two {
            &::after {
                background-color: $color-dark;
                opacity: 0.5;
            }
            @include respond(mobile) {
                padding: 3.9285714286rem 2.2142857143rem 10.857143rem 2.2142857143rem;
            }
        }
        .section-heading-3 {
            @include respond(desktop) {
                font-size: rem(32);
                margin-bottom: rem(20);
            }
            @include respond(ipadMobile) {
                font-size: rem(22);
                margin-bottom: rem(12);
            }
        }
        p {
            color: $color-grey;
            font-weight: 300;
            text-transform: uppercase;

            @include respond(desktop) {
                max-width: rem(605);
                font-size: rem(42);
                margin-bottom: rem(85);
                line-height: 52px;
            }
            @include respond(mobile) {
                font-family: 'Lato';
                font-size: 28px;
                font-weight: 300;
                line-height: 34px;
                margin-bottom: rem(80) !important;
            }
            span {
                @include respond(desktop) {
                    font-size: rem(25);
                }
                @include respond(ipadMobile) {
                    font-size: rem(10);
                }
                @include respond(mobile) {
                    display: block;
                    font-family: Lato;
                    font-size: 28px;
                    font-weight: 300;
                    line-height: 38px;
                    text-transform: lowercase;
                    margin-bottom: 5px;
                }
            }
            @include respond(ipadMobile) {
                font-size: rem(32);
                margin-bottom: rem(35);
            }
        }
    }
}

.blog-listing-container {
    max-width: rem(855);
    margin: 0 auto;
}

.blog-list-card {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    text-align: left;
    margin-bottom: rem(100);
    &:last-child {
        margin-bottom: 0;
    }
    @include respond(mobile) {
        margin-bottom: rem(70);
        display: flex;
        flex-direction: column-reverse;
    }
    .blog-list-content {
        color: $color-grey;
        .blog-list-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: rem(30);
            @include respond(mobile) {
                margin-bottom: rem(25);
            }
        }
        .blog-list-text {
            h4 {
                font-family: 'Alfa Slab One';
                font-size: rem(18);
                font-weight: 400;
                line-height: rem(28);
                margin-bottom: rem(10);
                span {
                    color: $color-primary;
                    @include respond(mobile) {
                        display: block;
                    }
                }
            }
            p {
                font-family: 'Lato';
                font-size: rem(14);
                font-weight: 400;
                line-height: rem(20);
            }
        }
    }   
    .blog-list-image {
        max-width: rem(250);
        margin-left: rem(70);
        @include respond(mobile) {
            max-width: 100%;
            margin-left: rem(0);
            margin-bottom: rem(20);
        }
    }
}

.compare-card-listing {
    margin-bottom: rem(45);
}
.compare-whisky-single-bottle-card {
    position: relative;
    padding: rem(30);
    min-height: rem(340);
    display: flex;
    align-items: flex-end;
    background: linear-gradient(90deg, #303033 -0.12%, #38383A 50.95%, #303033 99.94%);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
    .primary-plus-circle-btn {
        margin: rem(20) auto rem(50);
    }
    @include respond(mobile) {
        margin-bottom: rem(30);
    }
    .sb-card-content {
        display: block;
        width: 100%;
    }
    .compare-bottle-info {
        display: grid;
        align-items: flex-end;
        grid-template-columns: 1.4fr 1fr;
        margin-bottom: 0;
        img {
            margin: 0 auto;
            max-width: rem(100);
        }
        span {
            margin-bottom: 7px;
        }
    }
    .compare-whisky-cancel-btn {
        cursor: pointer;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        img {
            max-width: rem(15);
        }
    }
}

.compare-whisky-card {
    padding: rem(35) rem(65);
    background: linear-gradient(90deg, #303033 -0.12%, #38383A 50.95%, #303033 99.94%);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
    @include respond(mobile) {
        padding: 2.5rem;
    }
    .compare-whisky-card-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        align-items: center;
        color: $color-grey;
        .compare-vs-text {
            font-family: 'Alfa Slab One';
            font-size: rem(24);
            font-weight: 400;
            line-height: rem(18);
        }
    }
}

.compare-bottle-info {
    font-family: 'Lato';
    margin-bottom: rem(40);
    img {
        max-height: rem(270);
        margin-bottom: rem(10);
    }
    p {
        color: $color-grey;
        font-size: rem(14);
        font-weight: 300;
        line-height: rem(17);
        margin-bottom: rem(8);
    }
    span {
        display: block;
        font-size: rem(16);
        font-weight: 700;
        line-height: rem(19);
        color: $color-primary;
    }
    .compare-bottle-text-info {
        text-align: left;
    }
}