.main-slider-card,
.explore-bbtwelve-card,
.explore-kyloe-card,
.explore-nikfaldo-card {
  position: relative;
  @include respond(desktopIpad) {
    height: rem(672);
  }
  @include respond(mobile) {
    height: 43rem;
  }
}
.bgCover {
  @include bgCover();
}
.main-slider-card {
  @include respond(desktopIpad) {
    background-image: url("/images/new-home-slider-bg.jpg");
  }
}
.explore-kyloe-collection-section {
  background: radial-gradient(
    29.54% 42.54% at 46.04% 50%,
    #644d33 0%,
    #614628 38.02%,
    #2c1b07 64.58%,
    #150c02 85.42%,
    #100901 94.27%
  );
}
.explore-nikfaldo-collection-section {
  overflow: hidden;
  background: radial-gradient(
    61.5% 50% at 50% 50%,
    rgba(62, 75, 96, 0.66) 0%,
    rgba(24, 48, 71, 0.4158) 48.44%,
    rgba(2, 0, 0, 0) 100%
  );
}
@mixin bgLayout($width) {
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: $width;
    z-index: 1;
    height: 100%;
    top: 0;
    pointer-events: none;
  }
  &::before {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 77.77%);
    left: 0;
  }
  &::after {
    background: linear-gradient(-270deg, rgba(0, 0, 0, 0) 0%, #000000 77.77%);
    right: 0;
  }
}
.main-slider-section {
  @include bgLayout(50%);
  &::before,
  &::after {
    @include respond(mobile) {
      display: none;
    }
  }
  @include respond(mobile) {
    background-image: url("/images/new-home-slider-bg-mobile.png");
    height: 48rem !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  }
}
.explore-bbtwelve-collection-section {
  @include bgLayout(40%);
}

.home-main-slider {
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  z-index: 1;
  @include respond(desktopIpad) {
    max-width: 24.428571rem;
    bottom: rem(80);
  }
  @include respond(mobile) {
    max-width: 13.5rem;
    bottom: 4.5rem;
  }
  &__image {
    height: rem(470);
    display: flex !important;
    align-items: flex-end;
    justify-content: center;
  }
}

.line-header-card {
  @include bgCover();
  @include flex-center();
  @include respond(desktopIpad) {
    padding: rem(30) 0;
    min-height: rem(93);
  }
  @include respond(mobile) {
    padding: rem(20) 0;
    min-height: rem(70);
  }
  .heading-lined-1 {
    margin-bottom: 0;
  }
}
.heading-lined-1 {
  line-height: 1.1;
  @include respond(desktopIpad) {
    font-size: rem(24);
  }
  @include respond(mobile) {
    font-size: rem(14);
  }
  span {
    position: relative;
    font-family: $font-alfa;
    background: radial-gradient(
      100% 250.08% at 0% 50%,
      #ffd59f 0%,
      #ca974a 36.99%,
      #ffcfa4 67.58%,
      #d59255 100%,
      #e19e61 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-transform: uppercase;
    &::before,
    &::after {
      content: "";
      position: absolute;
      height: rem(2);
      background-color: $color-primary;
      top: 50%;
      @include respond(desktopIpad) {
        width: rem(40);
      }
      @include respond(mobile) {
        width: rem(20);
      }
    }
    &::before {
      left: 0;

      @include respond(desktopIpad) {
        transform: translateY(-50%) translateX(rem(-90));
      }
      @include respond(mobile) {
        transform: translateY(-50%) translateX(-2.428571rem);
      }
    }
    &::after {
      right: 0;
      @include respond(desktopIpad) {
        transform: translateY(-50%) translateX(rem(90));
      }
      @include respond(mobile) {
        transform: translateY(-50%) translateX(2.428571rem);
      }
    }
  }
}

// logo xl
.explore-bbtwelve-card {
  .logo-svg-xl {
    position: absolute;
    top: 11rem;
    @include respond(desktop) {
      width: rem(627);
      left: 14rem;
    }
    @include respond(ipadMobile) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .bb-new-logo {
    position: absolute;
    @include respond(desktop) {
      left: 17.5rem;
      top: 7.1rem;
      width: rem(164);
    }
    @include respond(ipadMobile) {
      left: rem(44);

      width: 5.714286rem;
    }
    @include respond(ipadMobile) {
      top: 12.1rem;
    }
    @include respond(mobile) {
      top: 9.1rem;
    }
  }
  &__bottle {
    z-index: 1;
    position: absolute;
    text-align: center;
    top: 45%;
    transform: translate(-50%, -50%);
    @include respond(mobile) {
      top: 50%;
      left: 50%;
      min-width: 75%;
    }
    @include respond(desktopIpad) {
      left: 49%;
      max-width: rem(400);
    }
    .nikfaldo-desc {
      @include respond(desktopIpad) {
        text-align: left;
        position: absolute;

        bottom: 4rem;
        font-size: rem(14);
        max-width: rem(228);
      }
      @include respond(desktop) {
        right: -14.9375rem;
      }
      @include respond(ipad) {
        right: -9.9375rem;
      }
      @include respond(mobile) {
        font-size: rem(14);
        max-width: rem(200);
        text-align: center;
        margin: rem(50) auto 0 auto;
        line-height: 1.6 !important;
      }
    }
    img {
      @include respond(desktop) {
        width: 8.3125rem;
      }
      @include respond(mobile) {
        width: 9.5rem;
      }
    }
    .new-btn-primary {
      position: relative;
      z-index: 1;
      @include respond(desktopIpad) {
        margin-top: rem(45);
      }
      @include respond(mobile) {
        margin-top: rem(22);
      }
    }
  }
}
.explore-kyloe-card {
  .logo-svg-xl {
    position: absolute;
    filter: drop-shadow(rem(15) rem(33) rem(7) black);
    top: rem(207);

    @include respond(desktop) {
      left: 13.357143rem;
      width: rem(516);
    }
    @include respond(ipad) {
      width: 32.857143rem;
    }
    @include respond(mobile) {
      top: 7.785714rem;
    }
  }
  .bb-new-logo {
    position: absolute;

    @include respond(desktop) {
      left: 31.2rem;
    }
    @include respond(ipad) {
      left: 25.2rem;
    }
    @include respond(desktopIpad) {
      top: 8.8rem;
      width: rem(164);
    }
    @include respond(mobile) {
      top: 4.8rem;
      width: 8.714286rem;
      right: 0;
    }
  }
  &__bottle {
    z-index: 1;
    position: absolute;
    text-align: center;
    top: 45%;
    transform: translate(-50%, -50%);
    left: 50%;
    @include respond(desktopIpad) {
      left: 53%;
      top: 41%;
      max-width: rem(400);
    }
    @include respond(mobile) {
      top: 50%;
      min-width: 80%;
    }
    .new-btn-primary {
      @include respond(ipad) {
        position: relative;
        z-index: 1;
      }
    }
    .nikfaldo-desc {
      @include respond(desktopIpad) {
        position: absolute;
        max-width: rem(228);
        text-align: left;
        font-size: rem(14);
        right: -3.5rem;
        bottom: 4.5rem;
      }
      @include respond(mobile) {
        max-width: rem(228);
        text-align: center;
        font-size: rem(12);
        margin: 3rem auto 0 auto;
      }
    }
    .new-btn-primary {
      @include respond(mobile) {
        margin-top: 1.5714285714rem;
      }
    }
    &--shadow {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 62%;
        height: 74%;
        left: 50%;
        bottom: 0;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(244, 234, 199, 0.94) 0%,
          rgba(105, 78, 38, 0.94) 100%
        );
        filter: blur(rem(28));
        z-index: -1;
        transform: translateX(-50%);
        border-radius: 40%;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 150%;
        height: rem(50);
        left: 50%;
        z-index: -1;
        transform: translateX(-50%) translateY(1rem);
        background: radial-gradient(
          50% 50% at 50% 50%,
          #b09872 0%,
          #6c5a3d 16.15%,
          #483a25 52.08%,
          #160d02 77.05%
        );
        filter: blur(rem(10));
      }
      @include respond(desktop) {
        transform: translate(19.214286rem, 6.142857rem);
      }
      @include respond(ipad) {
        transform: translate(16.214286rem, 3.142857rem);
      }

      img {
        @include respond(desktop) {
          width: rem(177);
        }
        @include respond(ipad) {
          width: 9.642857rem;
        }
        @include respond(mobile) {
          width: 6.5rem;
        }
      }
    }
  }
}
.explore-nikfaldo-card {
  .logo-svg-xl {
    position: absolute;

    top: 13.3rem;
    width: rem(779);
    left: 50%;
    transform: translateX(-50%);

    @include respond(mobile) {
      top: 8.3rem;
      width: 57.2rem;
    }
  }
  .bb-new-logo {
    position: absolute;
    top: 8.9rem;
    width: rem(167);
    left: 50%;
    transform: translateX(-50%);

    @include respond(mobile) {
      top: 5rem;
      width: 7.714286rem;
    }
  }
  &__bottle {
    z-index: 1;
    position: absolute;
    text-align: center;

    transform: translate(-50%, -50%);
    left: 50%;

    @include respond(desktopIpad) {
      max-width: rem(400);
      top: 60.5%;
    }

    @include respond(mobile) {
      min-width: 80%;
      top: 55.5%;
    }

    &::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      // background-image: url(attr(data-shadow));
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center;

      left: 50%;
      z-index: -1;
      transform: translateX(-50%) translateY(rem(195));
      @include respond(desktopIpad) {
        bottom: 0;
      }

      @include respond(mobile) {
        bottom: rem(43);
      }
    }
    &--shadow {
      position: relative;
      &::before {
        content: "";
        position: absolute;

        left: 50%;
        bottom: 0;
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgba(255, 216, 115, 0.54) 0%,
          rgba(185, 100, 42, 0.54) 100%
        );
        filter: blur(1.3125rem);
        z-index: -1;
        transform: translateX(-50%);
        border-radius: 50%;
        @include respond(desktopIpad) {
          width: 89%;
          height: 80%;
        }
        @include respond(mobile) {
          width: 80%;
          height: 76%;
        }
      }
      &::after {
        content: "";
        position: absolute;

        left: 50%;
        transform: translateX(-50%);
        background-position: center;
        background-repeat: no-repeat;

        z-index: -1;
        background: radial-gradient(
          50% 50% at 50% 50%,
          #f4c15d 0%,
          #be8247 16.67%,
          #53140f 61.72%,
          #2b0908 100%
        );
        filter: blur(7.5px);
        border-radius: 50%;
        @include respond(desktopIpad) {
          height: 5.142857rem;
          bottom: -2.5rem;
          width: 131%;
        }
        @include respond(mobile) {
          height: 3.142857rem;
          bottom: -1.5rem;
          width: 100%;
        }
      }
    }
    .nikfaldo-desc {
      @include respond(desktopIpad) {
        position: absolute;
        max-width: rem(228);
        text-align: left;
        font-size: rem(14);
        right: -12.5rem;
        bottom: rem(88);
      }
      @include respond(mobile) {
        max-width: rem(228);
        text-align: center;
        font-size: rem(14);
        margin: 2rem auto 0 auto;
      }
    }
    &--shadow {
      // transform: translate(23.214286rem, 3.142857rem);
      img {
        width: rem(239);
      }
    }
    .new-btn-primary {
      @include respond(desktopIpad) {
        margin-top: rem(45);
      }
      @include respond(mobile) {
        margin-top: 1rem;
      }
    }
  }
}
.new-btn-primary {
  background: rgba(106, 42, 17, 0.35);
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.31);
  border-radius: rem(37);
  display: inline-block;
  padding: 1rem 2.2rem;

  color: $white;
  text-align: center;
  text-transform: uppercase;
  font-family: "libre_baskervillebold";
  border: 1px solid $color-primary;
  &:hover {
    color: $white;
    text-decoration: none;
  }
  span {
    background: linear-gradient(90deg, #ffffff 0%, #ffcfa2 98.63%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  @include respond(desktopIpad) {
    font-size: rem(14);
  }
  @include respond(mobile) {
    font-size: rem(12);
  }
}

.no-bull-just-whisky {
  @include respond(desktop) {
    width: rem(299);
    left: rem(30);
  }
  @include respond(ipad) {
    left: 0;
    width: 12.6875rem;
  }
  @include respond(desktopIpad) {
    top: 44%;
    position: absolute;
    z-index: 1;
  }
  @include respond(mobile) {
    display: none;
  }
}
.hundred-proof {
  @include respond(desktop) {
    right: rem(124);
    width: rem(243);
  }
  @include respond(ipad) {
    right: 0;
    width: 13.1875rem;
  }
  @include respond(desktopIpad) {
    top: 44%;

    position: absolute;
    z-index: 2;
  }
  @include respond(mobile) {
    display: none;
  }
}

.blackbullwhiskey-collection-wrapper {
  @include respond(mobile) {
    padding-bottom: 2.5rem;
  }
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 85%;
    width: 100%;
    background-image: url("/images/black-bull-whiskey-bg.jpg");
    top: 0;
    z-index: -1;
    @include bgCover();
  }
}
// bb whiskey
.blackbullwhiskey-section {
  background-image: url("/images/black-bull-whiskey-section-bg.png");
  @include bgCover();
  @include respond(desktopIpad) {
    padding: rem(100) 0 rem(50) 0;
  }
  @include respond(mobile) {
    padding: 4.24rem 0 rem(50) 0;
  }
}
.explore-blackbullwhiskey-card__innertext {
  @include respond(desktopIpad) {
    display: none;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    padding: rem(40);
    height: 100%;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    border-radius: rem(6);
    top: 0;
    left: 0;
    width: 100%;
  }
  .heading-lined-1 {
    font-size: rem(15);
    @include text-truncate-7();
    overflow-y: auto;
    min-height: rem(126);
    &::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #7d6233;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #d49255;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #ffd59f;
    }
    span {
      &::before,
      &::after {
        display: none;
      }
    }
  }
  a {
    padding: 1rem 1.5rem;
    margin-top: 2.375rem !important;
    font-size: rem(13);
    line-height: 1.5;
  }
}
.explore-blackbullwhiskey-card {
  text-align: center;
  .nikfaldo-desc {
    color: #ffc699;
    max-width: rem(451);
    margin: 0 auto;
    text-align: center;
    @include respond(mobile) {
      font-size: rem(13);
      max-width: rem(243);
      margin-top: rem(32);
    }
  }
  .new-btn-primary {
    @include respond(desktopIpad) {
      margin-top: rem(70);
    }
    @include respond(mobile) {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0 !important;
      padding: 0;
      left: 0;
      top: 0;
      border-radius: 0;
      opacity: 0;
    }
  }
  &__insta {
    .w-100.row {
      @include respond(mobile) {
        margin: 0;
      }
    }
  }
}

.readmore-modal-body {
  .shop-whiskey-card-wrapper__heading {
    h2 {
      font-family: "Alfa Slab One";
      text-transform: uppercase;
      color: $white;
      margin: 0;
      @include respond(desktopIpad) {
        font-size: 1.5rem;
      }
      @include respond(mobile) {
        font-size: 1.125rem;
      }
    }
  }
}
.heading-bright-1,
.heading-bright-2 {
  font-family: $font-alfa;
  background: radial-gradient(
    100% 250.08% at 0% 50%,
    #ffd59f 0%,
    #ca974a 36.99%,
    #ffcfa4 67.58%,
    #d59255 100%,
    #e19e61 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: uppercase;
}
.heading-bright-2 {
  margin-bottom: 0;
  @include respond(desktopIpad) {
    font-size: rem(24);
  }
  @include respond(mobile) {
    font-size: rem(18);
  }
}
.heading-bright-1 {
  @include respond(desktopIpad) {
    font-size: rem(42);
  }
  @include respond(mobile) {
    font-size: rem(18);
  }
}

.hignlander-collection-wrapper {
  position: relative;
  @include respond(mobile) {
    padding: rem(35) 0;
  }
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    left: 0;
    width: 100%;
    background-image: url("/images/black-bull-whiskey-bg.jpg");
    top: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  @include respond(desktopIpad) {
    padding: rem(40) 0;
  }
}
.explore-hignlander-card {
  @include respond(desktopIpad) {
    padding: rem(115) rem(60) rem(85) rem(60);
    min-height: rem(580);
  }
  @include respond(mobile) {
    padding: 1.875rem;
    min-height: rem(423);
    @include flex-center();
    background-position: rem(-261);
  }
  &__desc {
    @include respond(mobile) {
      text-align: center;
    }
    .heading-bright-1 {
      @include respond(mobile) {
        max-width: rem(176);
        margin: 0 auto;
        line-height: 1.3;
      }
    }
    .nikfaldo-desc {
      @include respond(desktopIpad) {
        margin-top: rem(15);
        max-width: rem(314);
        font-size: rem(14);
      }
      @include respond(mobile) {
        margin: rem(147) auto 0 auto;
        max-width: rem(231);
        font-size: rem(14);
        line-height: 1.63;
      }
    }
    a {
      @include respond(desktopIpad) {
        margin-top: rem(45);
      }
      @include respond(mobile) {
        margin-top: rem(35);
      }
    }
  }
}

.explore-nikfaldo-show-more-card-wrapper {
  transform: translateY(rem(-155));
  z-index: 1;
  position: relative;
  max-width: rem(950);
  margin: 0 auto;
  &__close {
    position: absolute;
    top: rem(30);
    right: rem(30);
    @include respond(mobile) {
      display: none;
      right: 2.875rem;
      transform: translateY(-13.3125rem);
    }
  }
  &__sign {
    position: absolute;
    top: 0;
    transform: translateX(-50%) translateY(-99%);
    display: flex;
    align-items: flex-end;
    left: 50%;

    img {
      &:first-child {
        @include respond(desktopIpad) {
          width: rem(261);
        }
      }
      &:last-child {
        @include respond(desktopIpad) {
          width: rem(179);
        }
      }
      &:nth-child(2) {
        @include respond(desktopIpad) {
          position: absolute;
          right: 0;
          transform: translateX(rem(243)) translateY(rem(33));
        }
      }
    }
  }
}
.explore-kyloe-show-more-card-wrapper {
  @include respond(desktopIpad) {
    transform: translateY(-7.375rem);
    z-index: 1;
    position: relative;
    max-width: rem(620);
    margin: 0 auto;
  }
  @include respond(mobile) {
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: calc(100% + 2.5rem);
    transform: translateX(-1.25rem);
    padding-bottom: rem(75);
  }
  .slick-dots {
    @include respond(mobile) {
      bottom: rem(-45);
    }
  }
  &__close {
    position: absolute;
    top: rem(30);
    right: rem(30);

    @include respond(mobile) {
      display: none;
    }
  }
}

.explore-kyloe-show-more-card {
  @include flex-between();
  align-items: center;
  background: rgba(106, 42, 17, 0.2);
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.31);
  border-radius: rem(18);
  border: 1px solid #943502;
  @include respond(desktop) {
    padding: 2rem 7.0625rem 3rem 7.0625rem;
  }
  @include respond(desktopIpad) {
    height: rem(467);
    max-width: rem(621);
  }
  @include respond(ipad) {
    padding: 3rem 2rem;
    align-items: flex-end;
  }
  @include respond(mobile) {
    padding: 2rem;
    flex-direction: column;
    width: 18.625rem;
    margin: 0 1rem;
  }
  &__btn {
    img {
      @include respond(mobile) {
        margin: 0 auto;
      }
    }
  }
  .bull {
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    right: -7.4rem;
    width: rem(318);
  }
  .new-btn-primary {
    min-width: inherit;
    margin-top: rem(15);
    @include respond(desktop) {
      display: none;
    }
  }
  &__bottle {
    position: relative;
    text-align: center;
    @include respond(ipad) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    img {
      width: rem(105);
      @include respond(mobile) {
        width: 4.5625rem;
      }
    }
    &:hover {
      .new-btn-primary {
        display: inline-block;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 7.375rem;
      height: 16.1875rem;
      left: 50%;
      bottom: rem(21);
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(255, 216, 115, 0.53) 0%,
        rgba(185, 100, 42, 0.53) 100%
      );
      filter: blur(1.3125rem);
      z-index: -1;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    &::before {
      content: "";
      position: absolute;
      height: 5.142857rem;
      bottom: -2.5rem;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("/images/bottle-shadow.png");
      @include bgContain();
      width: 156%;
      z-index: -1;
    }
  }
}
.explore-nikfaldo-show-more-card {
  display: flex;
  justify-content: center;

  background: radial-gradient(
    76.48% 50% at 50% 50%,
    #b6582d 0%,
    #4c1a0e 40.78%,
    #220605 64.82%,
    #1c0504 89.42%
  );
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.31);
  border-radius: rem(37);
  border: 1px solid #943502;

  &__btn {
    text-align: center;
    display: block;
    position: relative;
    .add-to-card-nikfaldo__age {
      position: absolute;
      margin: 0;
      left: 47%;
      transform: translateX(-50%);
      top: rem(10);
      @include respond(desktop) {
        display: none;
      }
      span {
        &:not(:nth-child(2)) {
          font-size: 1rem;
        }
        &:nth-child(2) {
          font-size: 4rem;
        }
      }
    }
    .new-btn-primary {
      min-width: inherit;
      margin-top: rem(15);
      @include respond(desktop) {
        display: none;
      }
    }
  }
  &__bottle {
    @include flex-column();
    justify-content: flex-end;
    align-items: center;
    &.bottle-30,
    &.bottle-18 {
      .add-to-card-nikfaldo__age {
        top: rem(55);
      }
    }
    @include respond(desktop) {
      height: 100%;
    }
    img {
      position: relative;
      z-index: 1;
    }
    &:hover {
      .new-btn-primary,
      .add-to-card-nikfaldo__age {
        display: inline-block;
      }
    }
    &--element {
      position: relative;
      @include respond(ipad) {
        margin-bottom: rem(30);
      }
      &::before {
        content: "";
        position: absolute;
        height: rem(58);

        left: 47%;
        transform: translateX(-50%);
        background-image: url("/images/bottle-shadow.png");
        @include bgContain();
        width: 135%;
        @include respond(desktopIpad) {
          bottom: rem(-28);
        }

        @include respond(mobile) {
          bottom: rem(68);
        }
      }
    }
  }
  @include respond(desktop) {
    padding: 2rem rem(50) 4rem rem(50);
    height: 31.1875rem;
  }

  @include respond(ipad) {
    padding: 3rem 2rem;
    align-items: flex-end;
    flex-wrap: wrap;
  }
  @include respond(mobile) {
    padding: 1.5rem 2rem;
  }
}
.explore-bbtwelve-card {
  overflow: hidden;
  &__bottle {
    &::after {
      content: "";
      position: absolute;
      width: rem(150);
      height: rem(259);
      left: 50%;
      bottom: rem(103);
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(255, 216, 115, 0.54) 0%,
        rgba(185, 100, 42, 0.54) 100%
      );
      filter: blur(rem(21));
      z-index: -1;
      transform: translateX(-50%);
      @include respond(mobile) {
        display: none;
      }
    }
  }
}
.explore-bbtwelve-card__bottle--shadow {
  position: relative;
  &::before {
    content: "";
    position: absolute;

    background: radial-gradient(
      50% 50% at 50% 50%,
      #f4c15d 0%,
      #be8247 26.04%,
      #714410 44.79%,
      #1d1d1d 73.44%,
      #111111 100%
    );
    filter: blur(7.5px);
    box-shadow: 0px 1px 8px rgb(0 0 0 / 39%);

    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    @include respond(desktopIpad) {
      width: rem(300);
      height: rem(73.37);
      bottom: rem(-36);
    }
    @include respond(mobile) {
      width: 100%;
      height: 2.585625rem;
      bottom: -1rem;
    }
  }
  &::after {
    content: "";
    position: absolute;
    height: rem(200);
    width: rem(216);
    // background-image: url("/images/bb12-shadow.png");
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: center;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
  }
}

.explore-nikfaldo-show-more-card-slider {
  .explore-nikfaldo-show-more-card {
    width: 18.625rem;
    margin: 0 1rem;
  }
  .slick-slide img {
    margin: 0 auto;
  }
}
.explore-kyloe-show-more-card-slder {
  .explore-kyloe-show-more-card {
    width: 18.625rem;
    margin: 0 1rem;
  }
  .slick-slide img {
    margin: 0 auto;
  }
}
.explore-nikfaldo-show-more-card-wrapper {
  @include respond(mobile) {
    width: calc(100% + 2.5rem);
    transform: translateX(rem(-20));
    position: absolute;
    bottom: rem(40);
  }
}
.explore-nikfaldo-show-more-card {
  @include respond(mobile) {
    border-radius: rem(18);
  }
}

.explore-nikfaldo-show-more-card-wrapper__sign {
  img {
    &:first-child {
      @include respond(mobile) {
        width: rem(126);
      }
    }
    &:last-child {
      @include respond(mobile) {
        width: rem(108);
        transform: translateY(rem(20)) translateX(rem(27));
      }
    }
  }
}
.kyloe-peated-wrapper {
  .kyloe-edition-section.description {
    @include respond(mobile) {
      background-image: url("/images/kyloe-peated-desc-bg.png");
    }
    .kyloe-edition-section-bottle-wrapper {
      @include respond(mobile) {
        background-image: none !important;
      }
    }
  }
}

.store-locator-page {
  .new-outside-header {
    @include respond(ipadMobile) {
      background-color: $black;
    }
  }
}
.add-to-card-nikfaldo__image {
  @include respond(desktopIpad) {
    text-align: center;
  }
}

.main-slider-section {
  .main-slider-card {
    @include respond(mobile) {
      background-image: none !important;
    }
  }
}

.ProductOpen {
  .nikfaldo-desc,
  .new-btn-primary,
  .explore-nikfaldo-card__bottle--shadow,
  .explore-kyloe-card__bottle--shadow {
    display: none;
  }
}
.discover-video-card__image--video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.nikfaldo-section-eighteen-years,
.nikfaldo-section-thirty-years {
  .discover-video-card__image--video {
    @include respond(desktopIpad) {
      width: 7.8rem;
    }
    @include respond(mobile) {
      width: 3rem;
    }
  }
}
.nikfaldo-section-thirty-years {
  .nikfaldo-section-heading,
  .nikfaldo-desc-2.lg {
    background: linear-gradient(
      101.54deg,
      #95663c 28.76%,
      #cc9a6e 45.11%,
      #eeceb3 61.45%,
      #c39166 77.8%,
      #93643a 94.15%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.crafted-section-card {
  .nikfaldo-desc {
    color: #e7c983;
  }
}
.product-description-twelve-section {
  .product-description-card__image2 {
    border: 2px solid rgba(241, 197, 118, 0.3);
    border-radius: 6px;
  }
  .product-description-card__details {
    .nikfaldo-section-heading,
    .product-description-card__years--body {
      position: relative;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgb(0 0 0 / 50%);
      left: 0;
    }
  }
}

.black-bull-section {
  @include respond(desktopIpad) {
    padding: rem(70) 0;
  }
  @include respond(mobile) {
    padding: rem(50) 0;
  }
}
.bbtwelveScrollSection {
  background-color: $black;
}
.blackbull-twelve-section-wrapper {
}
.kyloe-section-wrapper.kyloe-peated-wrapper {
  .wheels-edition-section {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url("/images/kyloe-tasting-bg.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      right: 0;
      top: 0;
      opacity: 0.3;
    }
  }
}
.kyloe-section-wrapper {
  .wheels-edition-section__row {
    position: relative;
    z-index: 1;
    background: rgba(122, 134, 142, 20%);
    border: 1px solid rgba(122, 134, 142, 0.3);
    border-radius: 9px;

    @include respond(desktopIpad) {
      padding: 7.1875rem 1.875rem;
    }
  }
}
.kyloe-banner-wrapper {
  @include respond(mobile) {
    background-image: none !important;
  }
}
.falvour-wheels-section {
  .chartjs-render-monitor {
    @include respond(mobile) {
      transform: scale(1.5);
      transform-origin: top;
    }
  }
}

.kyloe-section-wrapper {
  .chartjs-render-monitor {
    @include respond(mobile) {
      transform: scale(1.2);
      transform-origin: top;
    }
  }
}

.nikfaldo-section-fifty-years {
  .product-description-wrapper {
    .fifty-years {
      @include respond(desktopIpad) {
        width: 17rem;
      }
    }
  }
}
.instagram-details-card {
  .explore-blackbullwhiskey-card__insta--items2::before,
  .explore-blackbullwhiskey-card__insta--items1::before {
    display: none;
  }
  .explore-blackbullwhiskey-card__insta--items1 {
    @include respond(desktopIpad) {
      height: 13.125rem;
    }
    .new-btn-primary {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 0;
      background-color: transparent;
    }
  }
}

.explore-the-collection-wrapper {
  background-color: $black;
}

.kyloe-section-wrapper.kyloe-peated-wrapper {
  background: radial-gradient(50% 154.13% at 50% 50%, #183047 0%, #100101 100%);
}

.chartjs-render-monitor {
  @include respond(ipad) {
    transform: scale(1.4);
  }
}
.insta-link {
  width: rem(20);
  position: absolute;
  top: rem(10);
  right: rem(10);
  @include respond(desktopIpad) {
    display: none !important;
  }
}
