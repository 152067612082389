.opps-modal {
    .modal-header {
        justify-content: flex-end;
        border-bottom: none;
    }
    .section-heading-2 {
        text-align: center;
    }
    .modal-close {
        width: 12px;
        cursor: pointer;
    }
    max-width: rem(325);

    @include respond(desktop) {
        margin: 0 auto 0 auto;
    }

    @include respond(ipadMobile) {
        margin: 0 auto 0 auto;
    }

    .modal-content {
        background: rgba(249, 248, 247, 0.85);
        border-radius: 3px;
        border: none;
    }
    .modal-body {
        @include respond(desktop) {
            padding: rem(37) rem(30) rem(56) rem(36);
        }
        @include respond(ipadMobile) {
            padding: rem(56) rem(34) rem(56) rem(46);
        }
        h3 {
            color: $color-secondary;
            font-size: rem(22);
            margin-bottom: rem(30) !important;
        }
        p {
            color: $color-secondary;
            font-size: rem(18);
            line-height: 22px;

            &:not(:last-child) {
                margin-bottom: rem(20);
            }
        }
    }
}
.modal-backdrop.show {
    background-color: rgba($color: $color-secondary, $alpha: 0.7);
    opacity: 1;
    mix-blend-mode: multiply;
}
.modal {
    @include respond(mobile){
        padding-right: 0 !important;
    }
}
.message-modal {
    justify-content: center;
    &.error-404 {
        .modal-icon {
            // border-left: calc(57px / 2) solid transparent;
            // border-right: calc(57px / 2) solid transparent;
            // border-bottom: 57px solid $color-primary;
            background-image: url('/svg/error-traingle.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 57px;
            height: 57px;
            position: relative;


            img {
                position: absolute;
                top: 58%;
                transform: translate(-50%, -50%);
                left: 50%;
            }
        }
    }
    &.thank-you {
        .modal-content {
            .modal-icon {
                height: rem(73);
                width: rem(73);
                background-color: $color-primary;
                border-radius: 50%;
                @include flex-center();
            }
        }
    }
    .modal-icon {
        margin: 0 auto rem(32) auto;
    }
    .section-heading-2 {
        font-size: rem(22);
        text-align: center;
        span{
            line-height: 1.2;
        }
    }
    .modal-content {
        max-width: rem(325);
        height: rem(294);
        padding: rem(20);
        background-color: $color-secondary;
        border: none;
        box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
    }
    .modal-body {
        @include flex-center();
        padding: 0;
        flex-direction: column;
    }
    .modal-close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        img {
            filter: invert(1);
        }
    }
    .modal-header {
        border-bottom: none;
    }
}

.mail-list-modal {
    max-width: 660px !important;
    @include respond(mediumMobile){
        max-width: rem(320) !important;
        margin: auto;
    }
    .modal-content {
        max-width: rem(660);
        height: rem(455);
        @include respond(mediumMobile){
            height: rem(340);
        }
        .modal-close {
            right: rem(35);
            top: rem(35);
            img {
                filter: invert(0);
                max-width: 15px;
            }
        }
        .modal-body {
            margin-top: 50px;
            text-align: center;
            display: block !important;
            @include respond(mediumMobile){
                margin-top: 25px;
                .mb-100 {
                    margin-bottom: 50px !important;
                }
            }
        }
        h3 {
            font-family: 'Alfa Slab One';
            font-size: 22px;
            font-weight: 400;
            line-height: 23px;
            color: $color-primary;
            margin-bottom: 20px;
            @include respond(mediumMobile){
                font-size: rem(18);
                line-height: rem(26);
            }
        }
        p {
            font-family: 'Lato';
            font-size: rem(14);
            font-weight: 400;
            line-height: rem(20);
            color: $color-light;
            @include respond(mediumMobile){
                font-size: rem(12);
                line-height: rem(18);
            }
        }
    }
    
}

.modal-info-msg {
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: $color-grey;
    text-align: center;
}
