body {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    @include respond(desktop) {
      // max-width: 981px  !important;
    }
    @include respond(ipadMobile) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
}
