.slick-dots {
    bottom: -19px;
    li {
        border: 1px solid rgba($color: $color-primary, $alpha: 1);
        transition: 0.5s ease-in-out transform;
        will-change: transform;
        margin: 0 3.5px !important;
        border-radius: 50%;
        @include respond(desktop) {
            width: 13px;
            height: 13px;
        }
        @include respond(ipadMobile) {
            width: 8px !important;
            height: 8px !important;
        }
        &.slick-active {
            background-color: rgba($color: $color-primary, $alpha: 1);
            // transform: scale(1.3);
        }
        button {
            opacity: 0;
        }
    }
}
.some-people-section {
    .slick-arrow {
        display: none !important;
    }
}
.bartenderSlider {
    .slick-list {
        @include respond(desktop) {
            margin-left: -19.5px;
        }
    }

    .slick-arrow {
        display: none !important;
    }
}
.bartenderSliderCardWrapper {
    @include respond(desktopIpad) {
        padding-bottom: rem(77);
    }
    @include respond(mobile) {
        padding-bottom: rem(77);
    }
}
.bartenderSliderContainer {
    @include respond(mobile) {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}
.winterSliderContainer {
    @include respond(mobile) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}
.winterSlider {
    .slick-dots {
        @include respond(desktopIpad) {
            display: none !important;
        }
    }
    @include respond(ipadMobile) {
        position: relative;
        .tracker {
            position: absolute;
            top: -50px;
        }
    }
}
.winter-slider-card {
    text-align: left;
    background-size: 101% !important;
    @include bgCover();

    @include respond(desktopIpad) {
        padding: rem(85) rem(114);
    }
    @include respond(mobile) {
        @include flex-column();
        padding: rem(350) rem(30) rem(54) rem(30);
        text-align: center;
        background-size: cover !important;
        align-items: center;
        background-position: center -4px;
    }
    .section-heading-2 {
        margin-bottom: rem(22) !important;
        @include respond(desktopIpad) {
            max-width: rem(291);
        }
        @include respond(mobile) {
            max-width: rem(193);
        }
    }
    .card-para {
        @include respond(desktopIpad) {
            margin-bottom: rem(54);
        }
        @include respond(mobile) {
            max-width: rem(123);
            line-height: 20px;
            margin-bottom: rem(31);
        }
    }
}

.slick-arrow {
    z-index: 5;
    background-image: url("/svg/slider-arrow.svg") !important;
    background-size: 19px 19px !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    border: 2px solid #f9c467 !important;
    border-radius: 50%;

    &::after,
    &::before {
        display: none;
    }
    @include respond(desktopIpad) {
        width: 2.5rem !important;
        height: 2.5rem !important;
    }
    &.slick-next {
        transform: translate(6px, -50%) !important;
    }
    &.slick-prev {
        transform: translate(-6px, -50%) rotate(180deg) !important;
    }
}

.recommendedSlider {
    @include respond(ipad) {
        .slick-prev {
            left: -10px;
        }
        .slick-next {
            right: -10px;
        }
    }
}
.youMightSlider {
    .slick-slide {
        &:first-child {
            .you-might-like-card__link {
                padding-left: rem(10);
            }
        }
    }
}
.productDetailMobileSlider {
    margin-bottom: rem(80);
    .slick-slide {
        padding: rem(20);
    }
    .slick-arrow {
        display: none !important;
    }
}

.bestBuySlider {
    .slick-slider {
        @include respond(desktopIpad) {
            max-width: 93.5%;
            margin: 0 auto;
        }
    }
    .slick-slide {
        &:first-child {
            .best-buy-card {
                @include respond(mobile) {
                    padding-left: rem(20);
                }
            }
        }
    }
    .slick-arrow {
        @include respond(mobile) {
            display: none !important;
        }
        &.slick-next {
            transform: translate(-2px, -112px) !important;
        }
        &.slick-prev {
            transform: translate(4px, -112px) rotate(180deg) !important;
        }
    }
}
.bestBuySliderCol {
    @include respond(mobile) {
        padding: 0 !important;
    }
}

.topReviewssSlider {
    .social-media-card {
        @include respond(mobile) {
            width: rem(270);
            padding: 0 rem(15);
        }
        @include respond(ipad){
            padding: 0 rem(15);
        }
    }
    .slick-arrow {
        @include respond(mobile) {
            display: none !important;
        }
    }
    .slick-slide {
        &:first-child{
            @include respond(mobile){
                padding-left: rem(10);
            }
        }
    }
}

.review-slider-container {
    @include respond(mobile) {
        padding: 0 !important;
    }
}


.productDescriptionCardSlider{
    .slick-arrow{
        display: none !important;
    }
    .slick-dots{
        @include respond(desktopIpad){
            bottom: rem(-108);
        }
        @include respond(mobile){
            bottom: rem(-68);
        }
    }
    .product-desc-section-card{
        @include respond(desktopIpad){
            min-height: 730px;
        }
        @include respond(mobile){
            // min-height: 1144px;
        }
    }
}

.section.product-desc-section{
    @include respond(desktopIpad){
        padding-bottom: 10.2142857143rem;
    }
    @include respond(mobile){
        padding-bottom: 7.2142857143rem;
    }
}

.responsiveMasonrySlider {
    @include respond(mediumMobile){
        button.slick-arrow {
            width: 32px;
            height: 32px;
            z-index: 10;
            &.slick-prev {
                left: 0;
            }
            &.slick-next {
                right: 0;
            }
        }
    }
}

.compare-whisky-slider {
    .slick-dots {
        bottom: -60px;
        li {
            width: rem(14);
            height: rem(14);
        }
    }
    // .slick-list {
    //     overflow: visible;
    // }
    // .compare-whisky-card {
    //     display: none !important;
    // }
    // .slick-slide.slick-active .compare-whisky-card {
    //     display: inline-block !important;
    // }
}
.slick-dots li{
    width: rem(8) !important;
    height: rem(8) !important;
}