.react-select-dropdown {
    background: $grey-49;
    max-width: 370px;

    .react-select__control {
        height: rem(45);

        .react-select__value-container {
            height: rem(45);
            padding: 0 20px;
        }
    }
}
