.apply-code-div {
  max-width: rem(375);
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  @include respond(mediumMobile) {
    max-width: 93%;
    min-height: rem(40);
  }

  &.sm {
    min-height: rem(35);
    button {
      padding: 0.86rem;
      font-size: rem(10);
      width: 12.857143rem;
      border-radius: 0;
      border: none;
    }
  }
  input {
    width: 100%;
    font-family: "Lato";
    font-size: rem(14);
    font-weight: 300;
    line-height: rem(17);
    color: $color-grey;
    background-color: transparent;
    box-shadow: none;
    border: 1px solid #f9c467;
    padding: rem(17) rem(30);
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: $color-grey;
      font-weight: 300;
    }
    @include respond(mediumMobile) {
      padding: rem(12) rem(16);
      border-radius: 5px 0px 0px 5px;
    }
  }
  button {
    padding: 0.86rem;
    text-align: center;
    @include respond(mediumMobile) {
      padding: rem(12) rem(16);
    }
  }
  @include respond(mediumMobile) {
    margin-bottom: rem(25) !important;
  }
}

.cancel-img {
  cursor: pointer;
  max-width: rem(15) !important;
}

.table-bottle-info {
  display: flex;
  align-items: center;
  font-family: Lato;
  color: $color-grey;
  @include respond(mediumMobile) {
    flex-direction: column;
  }
  p {
    margin-bottom: 0;
    font-size: rem(18);
    line-height: rem(22);
    @include respond(mediumMobile) {
      font-size: rem(14);
    }
  }
  p.bottle-name {
    font-weight: 600;
  }
  p.bottle-edition {
    font-weight: 300;
  }
  .table-bottle-img {
    max-width: rem(45);
    margin-right: rem(30);
    @include respond(mediumMobile) {
      max-width: rem(30);
      margin-right: rem(10);
    }
  }
}

.new-input {
  min-height: rem(48) !important;
  background: #1f1f20 !important;
  border: 1px solid rgba(255, 255, 255, 0.28) !important;
  padding: 0.7rem 1rem !important;
  font-family: "Bellota Text" !important;
  font-weight: 400 !important;
  font-size: rem(14);
  color: $white;
  &::placeholder {
    color: rgba(255, 255, 255, 0.42);
    text-transform: uppercase;
  }
}
.d-flex-aib {
  display: flex;
  align-items: baseline;
}
.d-flex-aic {
  display: flex;
  align-items: center;
}
.d-flex-jc {
  display: flex;
  justify-content: center;
}
.d-flex-sb {
  display: flex;
  justify-content: space-between;
}
.d-flex-end {
  display: flex;
  align-items: flex-end;
}
.d-flex-aic-jc {
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-heading-2.mb-0 {
  margin-bottom: 0 !important;
}

@mixin blog-info() {
  .blog-info {
    padding-left: rem(15);
    font-family: "Lato";
    color: $color-grey;
    h5 {
      font-size: rem(14);
      font-weight: 300;
      margin-bottom: rem(8);
    }
    h4 {
      font-size: rem(18);
      font-weight: 300;
      margin-bottom: rem(5);
    }
    p {
      font-size: rem(12);
      font-weight: 400;
      margin-bottom: 0;
      line-height: normal;
    }
    p.info {
      font-family: "Lato";
      font-size: rem(14);
      font-weight: 400;
      line-height: normal;
    }
    p.written {
      margin-bottom: 1.5rem;
      @include respond(mobile) {
        margin-bottom: 1rem;
      }
    }
  }
}
.blog-info-container {
  display: flex;
  align-items: center;
  @include respond(mobile) {
    justify-content: center;
  }
  @include blog-info();
}

.author-info-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @include respond(mobile) {
    display: block;
    .blog-info-container {
      justify-content: flex-start;
    }
    .follow-author-btn {
      margin-top: 50px;
      text-align: center;
    }
  }
  @include blog-info();
}

.blog-author-img {
  border: 0.5px solid $color-primary;
  border-radius: 50%;
  padding: 5px;
  &.medium {
    width: rem(60);
    height: rem(60);
    @include respond(mobile) {
      padding: 3px;
    }
  }
  &.large {
    width: rem(95);
    height: rem(95);
  }
  img {
    width: 100%;
    max-width: 100%;
    border-radius: 50%;
  }
}

.blog-hash-container {
  margin-bottom: rem(50);
  @include respond(mediumMobile) {
    margin-bottom: rem(40);
  }
}
.blog-hash {
  padding: rem(8) rem(16);
  background: $grey-49;
  margin-right: rem(15);
  font-family: "Lato";
  font-size: rem(12);
  font-weight: 300;
  color: $grey-ea;
  border-radius: 2px;
  display: inline-block;
  @include respond(mediumMobile) {
    margin-bottom: 15px;
  }
}

.blog-actions {
  display: flex;
  align-items: flex-end;
  margin-right: 40px;
  img {
    margin-right: 7px;
  }
  span {
    font-family: "Lato";
    font-size: rem(12);
    font-weight: 300;
    line-height: normal;
    color: $color-primary;
  }
}

.seperator {
  margin: 30px 0;
  border-bottom: 1px solid $color-primary;
}
.vlog-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(60);
  @include respond(mobile) {
    margin-bottom: rem(30);
    .blog-heading span {
      display: inline;
    }
  }
}

.vlog-info-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
  @include respond(ipadMobile) {
    .blog-info-container {
      margin-bottom: 20px;
    }
  }
  @include respond(mediumMobile) {
    .vlog-video-title {
      margin-bottom: 20px;
    }
  }
}

.video-list-container {
  @include respond(ipadMobile) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @include respond(mobile) {
    display: block;
  }
}

.subscribe-panel {
  @include respond(ipadMobile) {
    margin-bottom: rem(40);
  }
}

.similar-video {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  margin-bottom: rem(12);
  align-items: center;
  grid-gap: 10px;
  @include respond(ipadMobile) {
    grid-template-columns: 1fr 1.5fr;
    margin-right: 40px;
    &:nth-child(2n + 1) {
      margin-right: 40px;
    }
  }
  @include respond(mobile) {
    display: block;
    text-align: left;
    margin-right: 0px !important;
    margin-bottom: 45px;
  }
  img {
    max-width: rem(120);
    @include respond(mobile) {
      max-width: 100%;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  &-text {
    text-align: left;
    font-family: "Lato";
    h5 {
      font-size: rem(14);
      font-weight: 400;
      line-height: rem(18);
      color: $color-primary;
      @include text-truncate(2);
    }
    p {
      font-size: rem(12);
      font-weight: 300;
      line-height: rem(12);
      color: $color-grey;
      margin-bottom: 0;
    }
  }
}

.new-white-btn {
  padding: 0.939rem 2.5rem !important;
  background: rgba(255, 255, 255, 0.78);
  border: 1px solid rgba(255, 255, 255, 0.28);
  font-family: "benchninebold";
  font-size: rem(16) !important;
}

.subcribe-new-section {
  @include bgCover();
  background-image: url("/images/line-sub.png");
  @include respond(desktopIpad) {
    margin-top: rem(50);
  }
  @include respond(mobile) {
    margin-top: rem(30);
  }
  .row {
    align-items: center;
  }
}
.location-data-container {
  &__row {
    @include respond(ipad) {
      flex-direction: column-reverse;
    }
  }
}
.google-maps-container__countrylevel{
    position: absolute;
    transform: translateY(-25px);
    right: rem(15);
}
.subcribe-new-card {
  min-height: rem(120);
  padding: rem(20) 0;
  .new-input {
    @include respond(desktop) {
      min-width: rem(307);
      margin-right: rem(30);
    }
    @include respond(ipadMobile) {
      width: 100%;
      margin-bottom: rem(20);
    }
  }
  &__input {
    @include respond(ipad) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  h3 {
    text-align: left;
    color: $white;
    font-family: "Bellota Text";
    font-weight: 700;
    @include respond(desktopIpad) {
      font-size: rem(24);
      line-height: 1.5;
    }
    @include respond(mobile) {
      font-size: rem(18);
      line-height: 1.4;
    }
  }
}
