$white: #fff;
$black: #000;

//
$color-primary: #f9c467;
$color-primary1: #F1C576;
$color-primary2: #BE8D62;
$color-primary-light: #f85151;
$color-secondary: #303033;
$color-light: #f4f2ea;
$color-dark: #0a0a0a;
$color-danger: #73271B;
$color-danger1: #BB0C2E;
$color-warning: #C14525;
$color-grey: #f9f8f7;
$grey-77: #777777;
$grey-49: #464649;
$color-DE9: #f2ede9;
$dark-4f: #4c4c4f;
$grey-ea: #f4f2ea;
$text-85: #EDDBB5;
$text-c3: #D2D0C3;
$text-d7: #DFDDD7;
$text-b0: #B0B0B0;
$theme-colors: (
  "primary": #f9c467,
  "secondary": #303033,
  "dark": #0a0a0a,
  "danger": #ff4136,
);

$black-gradient: linear-gradient(90deg, #303033 0%, #38383a 56%, #303033 100%);

$font-lato: "Lato";
$font-alfa: "Alfa Slab One";

$transition-timing: cubic-bezier(0.45, 0.05, 0.55, 0.95);
